/********************************
 * Sentry 관련 유틸
 ********************************/

import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';

import commonUtil, { getLoginUser } from '@/utils/common-util';
import StorageUtil from '@/utils/storage-util';
import DateUtil from '@/utils/date-util';

import { selSentryPly } from '@/api/pfm/cmn/funcply/sentry';

const sentryUtil = {
  RENDERING_ERROR: 'RENDERING_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',

  /**
   * 정책을 조회한다.
   * @param
   * @return {Object} enabled 전체 모니터링 사용 여부
   * @return {Object} sampleRate 오류 모니터링 샘플링 비율
   * @return {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @return {Number} 정책 조회 주기
   * @return {String} 정책 마지막 조회 시간
   * @example
   * const { sentryOptions, reInqrPrdMtc, nowDateTime } = sentryUtil.selSentryPly();
   **/
  selSentryPly: async () => {
    const res = await selSentryPly();
    const isSuccess = res && res.success && res.result;

    const sentryOptions = {
      enabled: isSuccess ? Boolean(res.result?.useYn === 'true') : false,
      sampleRate: isSuccess ? Number(res.result?.errMniSampRt) : 0,
      tracesSampleRate: isSuccess ? Number(res.result?.pfmcMniSampRt) : 0,
    };
    const reInqrPrdMtc = isSuccess ? Number(res.result?.reInqrPrdMtc) : 0;
    const nowDateTime = DateUtil.nowDateTime();

    return {
      sentryOptions,
      reInqrPrdMtc,
      nowDateTime,
    };
  },

  /**
   * 정책 조회 주기가 지났는지 확인한다.
   * @param {Number} 정책 조회 주기
   * @param {String} 정책 마지막 조회 시간
   * @return {Boolean} 정책 조회 주기 지남 여부
   * @example
   * const isSelSentryPlyAgain = sentryUtil.calcTerm(reInqrPrdMtc, lastInqrDateTime);
   **/
  calcTerm: (reInqrPrdMtc, lastInqrDateTime) => {
    if (lastInqrDateTime === 0) return false;

    const term = DateUtil.diff(DateUtil.nowDateTime(), lastInqrDateTime, 's');
    return !!(term >= reInqrPrdMtc);
  },

  /**
   * 스토리지에 정책을 저장한다.
   * @param {Object} enabled 전체 모니터링 사용 여부
   * @param {Object} sampleRate 오류 모니터링 샘플링 비율
   * @param {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @param {Number} reInqrPrdMtc 정책 조회 주기
   * @param {String} lastInqrDateTime 정책 마지막 조회 시간
   * @return
   * @example
   * sentryUtil.setSentryOptionsInStorage(sentryOptions, reInqrPrdMtc, nowDateTime);
   **/
  setSentryOptionsInStorage: (sentryOptions, reInqrPrdMtc, nowDateTime) => {
    if (commonUtil.isMobileApp()) {
      window.$nuxt.context.$ongobridge.setDeviceStorage({
        key: 'SENTRY_INIT_OPTION',
        value: JSON.stringify({
          ...sentryOptions,
          reInqrPrdMtc,
          lastInqrDateTime: nowDateTime,
        }),
      });
    } else {
      StorageUtil.localStorage.set('SENTRY_INIT_OPTION', {
        ...sentryOptions,
        reInqrPrdMtc,
        lastInqrDateTime: nowDateTime,
      });
    }
  },

  /**
   * 스토리지에서 정책을 조회한다.
   * @param
   * @return {Object} enabled 전체 모니터링 사용 여부
   * @return {Object} sampleRate 오류 모니터링 샘플링 비율
   * @return {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @return {Number} 정책 조회 주기
   * @return {String} 정책 마지막 조회 시간
   * @example
   * const { sentryOptions, reInqrPrdMtc, lastInqrDateTime } = sentryUtil.getSentryOptionsInStorage();
   * **/
  getSentryOptionsInStorage: async () => {
    let sentryOptionsInStorage;
    if (commonUtil.isMobileApp()) {
      const res = await window.$nuxt.context.$ongobridge.getDeviceStorage({
        key: 'SENTRY_INIT_OPTION',
      });
      sentryOptionsInStorage = res?.data?.value;
      sentryOptionsInStorage = sentryOptionsInStorage ? JSON.parse(sentryOptionsInStorage) : {};
    } else {
      sentryOptionsInStorage = await StorageUtil.localStorage.get('SENTRY_INIT_OPTION');
    }

    const sentryOptions = {
      enabled: sentryOptionsInStorage?.enabled || false,
      sampleRate: sentryOptionsInStorage?.sampleRate || 0,
      tracesSampleRate: sentryOptionsInStorage?.tracesSampleRate || 0,
    };

    const reInqrPrdMtc = sentryOptionsInStorage?.reInqrPrdMtc || 0;
    const lastInqrDateTime = sentryOptionsInStorage?.lastInqrDateTime || 0;

    return { sentryOptions, reInqrPrdMtc, lastInqrDateTime };
  },

  /**
   * 스토어에 정책을 저장한다.
   * @param {Object} enabled 전체 모니터링 사용 여부
   * @param {Object} sampleRate 오류 모니터링 샘플링 비율
   * @param {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @param {Number} reInqrPrdMtc 정책 조회 주기
   * @param {String} lastInqrDateTime 정책 마지막 조회 시간
   * @return
   * @example
   * sentryUtil.setSentryOptionsInStorage(sentryOptions, reInqrPrdMtc, nowDateTime);
   **/
  setSentryOptionsInStore: (sentryOptions, reInqrPrdMtc, nowDateTime) => {
    const $store = window.$nuxt.$store;

    $store.commit('sentry/setEnabled', sentryOptions.enabled);
    $store.commit('sentry/setSampleRate', sentryOptions.sampleRate);
    $store.commit('sentry/setTracesSampleRate', sentryOptions.tracesSampleRate);

    $store.commit('sentry/setReInqrPrdMtc', reInqrPrdMtc);
    $store.commit('sentry/setLastInqrDateTime', nowDateTime);
  },

  /**
   * 스토어에서 정책을 조회한다.
   * @param
   * @return {Object} enabled 전체 모니터링 사용 여부
   * @return {Object} sampleRate 오류 모니터링 샘플링 비율
   * @return {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @return {Number} 정책 조회 주기
   * @return {String} 정책 마지막 조회 시간
   * @example
   * const { sentryOptions, reInqrPrdMtc, lastInqrDateTime } = sentryUtil.getSentryOptionsInStore();
   * **/
  getSentryOptionsInStore: () => {
    const getters = window.$nuxt.$store.getters;

    const sentryOptions = {
      enabled: getters['sentry/getEnabled'] || false,
      sampleRate: getters['sentry/getSampleRate'] || 0,
      tracesSampleRate: getters['sentry/getTracesSampleRate'] || 0,
    };

    const reInqrPrdMtc = getters['sentry/getReInqrPrdMtc'] || 0;
    const lastInqrDateTime = getters['sentry/getLastInqrDateTime'] || 0;

    return { sentryOptions, reInqrPrdMtc, lastInqrDateTime };
  },

  /**
   * Sentry를 초기화한다. (단, 이미 초기화한 경우 옵션 재설정)
   * @param {Object} enabled 전체 모니터링 사용 여부
   * @param {Object} sampleRate 오류 모니터링 샘플링 비율
   * @param {Object} tracesSampleRate 트랜잭션 모니터링 샘플링 비율
   * @return
   * @example
   * sentryUtil.initialize(sentryOptions);
   **/
  initialize: sentryOptions => {
    if (!Sentry.isInitialized()) {
      Vue.use(Router);

      const sentryOptionsDefault = {
        // DSN
        dsn: window.$nuxt.context.env.SENTRY_DSN,

        // 환경
        environment: window.$nuxt.context.env.SENTRY_ENVIRONMENT,

        // 프로젝트
        project: window.$nuxt.context.env.SENTRY_PROJECT,

        // 릴리즈
        release: window.$nuxt.context.env.SENTRY_RELEASE,

        // 플러그인
        integrations: [
          // Performance 플러그인
          Sentry.browserTracingIntegration({ Router }),

          // Session Replay 플러그인
          Sentry.replayIntegration({
            maskAllInputs: true,
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],

        // 세션 리플레이 샘플링 비율
        replaysSessionSampleRate: 0.1, // 10%

        // 오류 세션 리플레이 샘플링 비율
        replaysOnErrorSampleRate: 1, // 100%

        // 레포트 전송
        sendClientReports: false,

        // fetch keepAlive
        transportOptions: {
          fetchOptions: {
            keepalive: false,
          },
        },

        // 예외 오류
        ignoreErrors: ['Object captured as exception'],

        initialScope: scope => {
          // 중요도
          scope.setLevel('error');

          // 커스텀 태그
          scope.setTags({ type: 'none' });

          // 추가 커스텀
          // scope.setExtras({});
          return scope;
        },

        // 오류 전송 Before Handler
        beforeSend: async event => {
          // 미사용 시, 종료 처리 (미사용이어도 Handler에 들어오므로 예외처리)
          const curSentryOptions = Sentry.getClient().getOptions();
          if (!curSentryOptions?.enabled) return;

          // 기본 Extra
          const { loginUser } = await getLoginUser();
          event.extra = {
            ...event.extra,
            mnmCstMngtNo: loginUser?.mnmCstMngtNo,
          };

          window.$nuxt?.context.$log.log(
            `[공통-sentryUtil] Sentry ${event?.tags?.type === 'message' && event?.message ? '메세지' : '오류'} 전송`,
            event,
          );
          return event;
        },

        // 트랜잭션 전송 Before Handler
        beforeSendTransaction: transaction => {
          window.$nuxt?.context.$log.log('[공통-sentryUtil] Sentry 트랜잭션 전송', transaction);
          return transaction;
        },
      };

      Sentry.init({
        ...sentryOptionsDefault,
        ...sentryOptions,
        Vue,
      });

      window.$nuxt?.context.$log.log('[공통-sentryUtil] Sentry 초기화\n', { ...Sentry.getClient().getOptions() });
    } else {
      const curSentryOptions = Sentry.getClient().getOptions();
      Object.keys(sentryOptions).forEach(item => {
        curSentryOptions[item] = sentryOptions[item];
      });

      window.$nuxt?.context.$log.log('[공통-sentryUtil] Sentry 재설정\n', { ...Sentry.getClient().getOptions() });
    }
  },

  /**
   * Sentry를 초기화한다. (단, 정책은 어드민에서 관리되고 있으며 주기(분 단위)마다 정책 재조회 및 옵션 재설정)
   * @param
   * @return
   * @example
   * sentryUtil.init();
   **/
  init: async () => {
    /**
     * 스토어 조회
     *
     *  1. 스토어에 있는 경우, 조회 주기가 지났는지 확인
     *
     *    1.1. 조회 주기가 지난 경우, 어드민 조회 -> 스토리지 저장 + 스토어 저장 + Sentry 초기화 (Case 5. 웹뷰 내 화면 이동 + 조회 주기가 지남)
     *    1.2. 조회 주기가 지나지 않는 경우, 아무것도 하지 않음 (Case 4. 웹뷰 내 화면 이동)
     *
     *  2. 스토어에 없는 경우, 스토리지 조회
     *
     *    2.1. 스토리지에 있는 경우, 조회 주기가 지났는지 확인
     *
     *      2.1.1. 조회 주기가 지난 경우, 어드민 조회 -> 스토리지 저장 + 스토어 저장 + Sentry 초기화 (Case 3. 다른 웹뷰 최초 접속 + 조회 주기가 지남)
     *      2.1.2. 조회 주기가 지나지 않은 경우, 스토리지 -> 스토어 저장 + Sentry 초기화 (Case 2. 다른 웹뷰 최초 접속)
     *
     *    2.2. 스토리지에 없는 경우, 어드민 조회 -> 스토리지 저장 + 스토어 저장 + Sentry 초기화 (Case 1. 웹뷰 최초 접속)
     */

    // 스토어 조회
    const {
      sentryOptions: sentryOptionsInStore,
      reInqrPrdMtc,
      lastInqrDateTime,
    } = sentryUtil.getSentryOptionsInStore();

    // 1. 스토어에 있는 경우,
    if (sentryOptionsInStore && reInqrPrdMtc !== 0 && lastInqrDateTime !== 0) {
      // 조회 주기가 지났는지 확인
      const isSelSentryPlyAgain = sentryUtil.calcTerm(reInqrPrdMtc, lastInqrDateTime);

      // 1.1. 조회 주기가 지난 경우,
      if (isSelSentryPlyAgain) {
        // 어드민 조회
        const { sentryOptions, reInqrPrdMtc, nowDateTime } = await sentryUtil.selSentryPly();

        // 스토리지 저장
        sentryUtil.setSentryOptionsInStorage(sentryOptions, reInqrPrdMtc, nowDateTime);

        // 스토어 저장
        sentryUtil.setSentryOptionsInStore(sentryOptions, reInqrPrdMtc, nowDateTime);

        // Sentry 초기화
        sentryUtil.initialize(sentryOptions);
      }
      // 1.2. 조회 주기가 지나지 않은 경우,
    }
    // 2. 스토어에 없는 경우,
    else {
      // 2.1. 스토리지 조회
      const {
        sentryOptions: sentryOptionsInStorage,
        reInqrPrdMtc,
        lastInqrDateTime,
      } = await sentryUtil.getSentryOptionsInStorage();

      // 2.1. 스토리지에 있는 경우,
      if (sentryOptionsInStorage && reInqrPrdMtc !== 0 && lastInqrDateTime !== 0) {
        // 조회 주기가 지났는지 확인
        const isSelSentryPlyAgain = sentryUtil.calcTerm(reInqrPrdMtc, lastInqrDateTime);

        // 2.1.1. 조회 주기가 지난 경우,
        if (isSelSentryPlyAgain) {
          // 어드민 조회
          const { sentryOptions, reInqrPrdMtc, nowDateTime } = await sentryUtil.selSentryPly();

          // 스토리지 저장
          sentryUtil.setSentryOptionsInStorage(sentryOptions, reInqrPrdMtc, nowDateTime);

          // 스토어 저장
          sentryUtil.setSentryOptionsInStore(sentryOptions, reInqrPrdMtc, nowDateTime);

          // Sentry 초기화
          sentryUtil.initialize(sentryOptions);
        }
        // 2.1.2. 조회 주기가 지나지 않은 경우,
        else {
          // 스토어 저장
          sentryUtil.setSentryOptionsInStore(sentryOptionsInStorage, reInqrPrdMtc, lastInqrDateTime);

          // Sentry 초기화
          sentryUtil.initialize(sentryOptionsInStorage);
        }
      }
      // 2.2. 스토리지에 없는 경우,
      else {
        // 어드민 조회
        const { sentryOptions, reInqrPrdMtc, nowDateTime } = await sentryUtil.selSentryPly();

        // 스토리지 저장
        sentryUtil.setSentryOptionsInStorage(sentryOptions, reInqrPrdMtc, nowDateTime);

        // 스토어 저장
        sentryUtil.setSentryOptionsInStore(sentryOptions, reInqrPrdMtc, nowDateTime);

        // Sentry 초기화
        sentryUtil.initialize(sentryOptions);
      }
    }
  },

  /**
   * Sentry에 전송할 오류 객체와 유형을 확인한다.
   * @param {Object} error 오류 객체
   * @return {Object} errorObject 오류 객체
   * @return {Object} errorType 오류 유형
   * @example
   * sentryUtil.getError(error);
   * sentryUtil.getError(this.error);
   **/
  getError(error) {
    // Axios 오류 객체이거나 Axios 오류 객체가 아니더라도 request, response 정보가 있는 경우 Axios Error로 판단
    const isAxiosError = error?.isAxiosError || (error?.request !== undefined && error?.response !== undefined);
    const is404Error = error?.statusCode === 404;

    const isNewError = !isAxiosError && is404Error;

    // Axios 오류는 아닌데 404 오류인 경우 (예: Page Not Found), 오류 정보가 담긴 Object밖에 없기 때문에 Sentry 전송을 위해 오류 객체 생성
    const errorObject = isNewError ? new Error(error?.message, { statusCode: error?.statusCode }) : error;
    // Axios 오류면 네트워크 오류, 아니면 렌더링 오류
    const errorType = isAxiosError ? sentryUtil.NETWORK_ERROR : sentryUtil.RENDERING_ERROR;

    return { errorObject, errorType };
  },

  /**
   * Sentry에 오류 정보를 전송한다.
   * @param {Object} error 오류 객체
   * @param {String} errorType 오류 유형
   * @return
   * @example
   * sentryUtil.captureException(error, sentryUtil.RENDERING_ERROR);
   * sentryUtil.captureException(error, sentryUtil.NETWORK_ERROR);
   **/
  captureException: (error, errorType) => {
    if (!Sentry.isInitialized()) return;

    let type = 'rendering';
    let extra = {};

    switch (errorType) {
      case 'RENDERING_ERROR':
        type = 'rendering';
        extra = {};
        break;

      case 'NETWORK_ERROR':
        type = 'network';
        extra = { request: error.request, response: error.response?.data };
        break;
    }

    Sentry.captureException(error, scope => {
      scope.setLevel('error');
      scope.setTag('type', type);
      scope.setExtras(extra);

      return scope;
    });
  },

  /**
   * Sentry에 메세지 정보를 전송한다.
   * @param {String} message 메세지 string
   * @return
   * @example
   * sentryUtil.captureMessage('user message');
   **/
  captureMessage: message => {
    if (!Sentry.isInitialized()) return;

    Sentry.captureMessage(message, scope => {
      scope.setLevel('info');
      scope.setTag('type', 'message');

      return scope;
    });
  },
};
export default sentryUtil;
