import { convertWcmsPath } from '@/utils';
export const state = () => ({
  student: null, // 학교 등록 정보
  isJunior: true, // 자녀 여부
  receiver: null, // 1회 용돈 주기 대상자 사용자 정보
  feedList: [], // 피드 목록
  familyList: [], // 가족 목록
  connectedFamilyList: [], // 연결된 가족 목록
  isCurtainCallShow: false, // 신규 가족 커튼콜 노출 여부
  curtaincallMessage: '', // 토스트 메시지 문구
  newFamily: '', // 신규로 연결된 가족 모관번
  isSnackbar: false, // 토스트 메시지 노출 여부
  toastMessage: '', // 토스트 메시지 문구
  profileList: [], // 프로필 이미지 목록
  isFromMFamily: false, // 모니페밀리 > 아이용돈 진입 여부 상태 저장
});

export const getters = {
  // 오늘의학교 학교 설정 정보(학교/학년/반)
  getStudent(state) {
    return state.student;
  },
  // 용돈 자녀 페이지 여부
  getIsJunior(state) {
    return state.isJunior;
  },
  // 용돈 요청 정보(받는 자녀 모관번/요청금액/요청시리얼넘버)
  getReceiver(state) {
    return state.receiver;
  },
  // 용돈 메안 피드 목록
  getFeedList(state) {
    return state.feedList;
  },
  // 용돈 메인 가족 목록
  getFamilyList(state) {
    return state.familyList;
  },
  // 연결 상태인 가족 목록
  getConnectedFamilyList(state) {
    return state.connectedFamilyList;
  },
  // 가족 연결 커튼콜 노출 여부
  getIsCurtainCallShow(state) {
    return state.isCurtainCallShow;
  },
  // 가족 연결 커튼콜 메시지
  getCurtaincallMessage(state) {
    return state.curtaincallMessage;
  },
  // 신규 연결 가족
  getNewFamily(state) {
    return state.newFamily;
  },
  // 토스트 메시지 노출 여부
  getIsSnackbar(state) {
    return state.isSnackbar;
  },
  // 토스트 메시지
  getToastMessage(state) {
    return state.toastMessage;
  },
  // 가족 프로필 이미지 목록
  getProfileList(state) {
    return state.profileList;
  },
  // 프로필 이미지 정보 상세
  getProfile(state) {
    return function (payload) {
      const result = state.profileList.find(profile => profile.imgC === payload?.fmlPrflImgWcmsC);
      return result !== undefined ? convertWcmsPath(result?.imgUrl) : require('@images/allowance/profile-default.png');
    };
  },
  // 프로필 사진 배경 컬러
  getBgColor(state) {
    return function (payload) {
      const result = state.profileList.find(profile => profile.imgC === payload?.fmlPrflImgWcmsC);
      return result ? result.bgColor : '#e5e5e8';
    };
  },
  // 가족 프로필 이미지 목록
  getIsFromMFamily(state) {
    return state.isFromMFamily;
  },
};

export const mutations = {
  // 오늘의학교 학교 설정 정보(학교/학년/반)
  setStudent(state, payload) {
    state.student = payload;
  },
  // 용돈 자녀 페이지 여부
  setIsJunior(state, payload) {
    state.isJunior = payload;
  },
  // 용돈 요청 정보(받는 자녀 모관번/요청금액/요청시리얼넘버)
  setReceiver(state, payload) {
    state.receiver = payload;
  },
  // 용돈 메안 피드 목록
  setFeedList(state, payload) {
    state.feedList = payload;
  },
  // 용돈 메인 가족 목록
  setFamilyList(state, payload) {
    state.familyList = payload;
  },
  // 연결 상태인 가족 목록
  setConnectedFamilyListy(state, payload) {
    state.connectedFamilyList = payload;
  },
  // 가족 연결 커튼콜 노출 여부
  setIsCurtainCallShow(state, payload) {
    state.isCurtainCallShow = payload;
  },
  // 가족 연결 커튼콜 메시지
  setCurtaincallMessage(state, payload) {
    state.curtaincallMessage = payload;
  },
  // 신규 연결 가족
  setNewFamily(state, payload) {
    state.newFamily = payload;
  },
  // 토스트 메시지 노출 여부
  setIsSnackbar(state, payload) {
    state.isSnackbar = payload;
  },
  // 토스트 메시지
  setToastMessage(state, payload) {
    state.toastMessage = payload;
  },
  // 가족 프로필 이미지 목록
  setProfileList(state, payload) {
    state.profileList = payload;
  },
  // 가족 프로필 이미지 목록
  setIsFromMFamily(state, payload) {
    state.isFromMFamily = payload;
  },
};
