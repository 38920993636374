// state (선언)
export const state = () => ({
  currentTab: 0, // 선택한 탭 정보
  eventScrollY: 0, // 이벤트메인: 보고 있던 화면 위치
  boxScrollY: 0, // 쿠폰함: 보고 있던 화면 위치
});

// Mutations
export const mutations = {
  // 선택된정보
  setSelectedInfo(state, payload) {
    state.selectedInfo = payload;
  },
  // 현재 탭정보
  setCurrentTab(state, payload) {
    state.currentTab = payload;
  },
  // 이벤트메인: 스크롤 할 Y좌표 정보
  setEventScrollY(state, payload) {
    state.eventScrollY = payload;
  },
  // 쿠폰함: 스크롤 할 Y좌표 정보
  setBoxScrollY(state, payload) {
    state.boxScrollY = payload;
  },
};

// Getter
export const getters = {
  // 현재 탭정보
  getCurrentTab(state) {
    return state.currentTab;
  },
  // 이벤트메인: 스크롤 할 Y좌표 정보
  getEventScrollY(state) {
    return state.eventScrollY;
  },
  // 쿠폰함: 스크롤 할 Y좌표 정보
  getBoxScrollY(state) {
    return state.boxScrollY;
  },
};
