/**
 * Console
 */
import TypeUtil from './type-util';
import { IS_DEBUG } from './constants';

/** 디버그모드인 경우만 로그 활성화 */
const isEnable = IS_DEBUG;

/** console이 없는 경우 에러 방지 */
const console = window?.console || {
  log() {},
  debug() {},
  info() {},
  warn() {},
  error() {},
  dir() {},
  dirxml() {},
  table() {},
  group() {},
  groupCollapsed() {},
  groupEnd() {},
  ...window?.console,
};

/** 로그 */
const log = {
  log() {
    try {
      if (isEnable && TypeUtil.isFunction(console.log)) {
        console.log.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * debug 레벨 로그를 출력한다.
   * @param {string} msg
   * @example
   *
   * scard.log.debug("로그 내용");
   **/
  debug() {
    try {
      if (isEnable && TypeUtil.isFunction(console.debug)) {
        console.debug.apply(this, Array.prototype.slice.call(arguments));
      } else if (isEnable && TypeUtil.isFunction(log.log)) {
        log.log.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * info 레벨 로그를 출력한다.
   * @param {string} msg
   * @example
   *
   * scard.log.info("로그 내용");
   **/
  info() {
    try {
      if (isEnable && TypeUtil.isFunction(console.info)) {
        console.info.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * warn 레벨 로그를 출력한다.
   * @param {string} msg
   * @example
   *
   * scard.log.warn("로그 내용");
   **/
  warn() {
    try {
      if (isEnable && TypeUtil.isFunction(console.warn)) {
        console.warn.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * error 레벨 로그를 출력한다.
   * @param {string} msg
   * @example
   *
   * scard.log.error("로그 내용");
   **/
  error() {
    try {
      if (isEnable && TypeUtil.isFunction(console.error)) {
        console.error.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * dir
   */
  dir() {
    try {
      if (isEnable && TypeUtil.isFunction(console.dir)) {
        console.dir.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * dirxml
   */
  dirxml() {
    try {
      if (isEnable && TypeUtil.isFunction(console.dirxml)) {
        console.dirxml.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * table
   */
  table() {
    try {
      if (
        isEnable &&
        TypeUtil.isFunction(console.table) &&
        // typeof arguments[0] === 'object' &&
        !TypeUtil.isEmptyArray(arguments[0])
      ) {
        console.table.apply(this, Array.prototype.slice.call(arguments));
      } else if (isEnable && TypeUtil.isFunction(console.log)) {
        console.log.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * group
   */
  group() {
    try {
      if (isEnable && TypeUtil.isFunction(console.group)) {
        console.group.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * groupCollapsed
   */
  groupCollapsed() {
    try {
      if (isEnable && TypeUtil.isFunction(console.groupCollapsed)) {
        console.groupCollapsed.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
  /**
   * groupEnd
   */
  groupEnd() {
    try {
      if (isEnable && TypeUtil.isFunction(console.groupEnd)) {
        console.groupEnd.apply(this, Array.prototype.slice.call(arguments));
      }
    } catch (ignore) {}
  },
};

export default log;
