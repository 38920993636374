/********************************
 * Cookie 유틸
 ********************************/
export default {
  get(key, context = window?.$nuxt?.context) {
    return context.$storage.getCookie(key);
  },
  set(key, value, options = {}, context = window?.$nuxt?.context) {
    return context.$storage.setCookie(key, value, options);
  },
  remove(key, options, context = window?.$nuxt?.context) {
    context.$storage.removeCookie(key, options);
  },
  getAll(context = window?.$nuxt?.context) {
    return context.$storage.getCookies();
  },
};
