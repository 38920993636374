/********************************
 * Data Type 관련 유틸
 ********************************/
const typeUtil = {
  /**
   * 현재 객체(값)의 생성자 이름을 가져온다.
   * @param {*} val
   * @returns 생성자 명
   */
  getConstructorName(val) {
    // eslint-disable-next-line no-proto
    return val?.__proto__.constructor.name;
  },

  /**
   * 현재 객체(값)가 원하는 타입인지를 체크한다.
   * @param {*} val
   * @param {Function|String} Type
   * @returns
   */
  is(val, Type) {
    if (typeUtil.isFunction(Type)) {
      return val instanceof Type || (typeof val)?.toLowerCase() === Type?.prototype.constructor.name.toLowerCase();
    } else if (typeUtil.isString(Type)) {
      return (
        (typeof val)?.toLowerCase() === Type?.toLowerCase() ||
        // eslint-disable-next-line no-proto
        val?.__proto__.constructor.name?.toLowerCase() === Type?.toLowerCase()
      );
    }
  },

  /**
   * 현재 객체(값)의 type이 string인지를 체크한다.(primitive와 Wrapper 포함)
   * @param {*} val
   * @returns (primitive타입인 string이나 Wrapper타입인 String 여부)
   */
  isString(val) {
    return val instanceof String || typeof val === 'string';
  },

  /**
   * 현재 객체(값)의 type이 number인지를 체크한다.(primitive와 Wrapper 포함)
   * @param {*} val
   * @returns (primitive타입인 number나 Wrapper타입인 Number 여부)
   */
  isNumber(val) {
    return val instanceof Number || (!Number.isNaN(val) && typeof val === 'number');
  },

  /**
   * 현재 객체(값)의 type이 bigint인지를 체크한다.
   * @param {*} val
   * @returns bigint 여부
   */
  isBigInt(val) {
    return val instanceof BigInt || typeof val === 'bigint';
  },

  /**
   * 현재 객체(값)의 type이 boolean인지를 체크한다.(primitive와 Wrapper 포함)
   * @param {*} val
   * @returns (primitive타입인 boolean이나 Wrapper타입인 Boolean 여부)
   */
  isBoolean(val) {
    return val instanceof Boolean || typeof val === 'boolean';
  },

  /**
   * 현재 객체(값)의 type이 undefined인지를 체크한다.(primitive와 Wrapper 포함)
   * @param {*} val
   * @returns undefined 여부
   */
  isUndefined(val) {
    return typeof val === 'undefined';
  },

  /**
   * 현재 객체(값)의 type이 null인지를 체크한다.
   * @param {*} val
   * @returns null 여부
   */
  isNull(val) {
    return val === null;
  },

  /**
   * 현재 객체(값)의 type이 symbol인지를 체크한다.
   * @param {*} val
   * @returns symbol 여부
   */
  isSymbol(val) {
    return typeof val === 'symbol';
  },

  /**
   * 현재 객체(값)이 primitive type인지를 체크한다.
   * @param {*} val
   * @returns primitive type (string | number | bigint | boolean | undefined | symbol | null) 여부
   */
  isPrimitive(val) {
    let _isPrimitive = false;
    if (typeUtil.isString(val) && !(val instanceof String)) {
      _isPrimitive = true;
    } else if (typeUtil.isNumber(val) && !(val instanceof Number)) {
      _isPrimitive = true;
    } else if (typeUtil.isBigInt(val)) {
      _isPrimitive = true;
    } else if (typeUtil.isBoolean(val) && !(val instanceof Boolean)) {
      _isPrimitive = true;
    } else if (typeUtil.isUndefined(val)) {
      _isPrimitive = true;
    } else if (typeUtil.isSymbol(val)) {
      _isPrimitive = true;
    } else if (typeUtil.isNull(val)) {
      _isPrimitive = true;
    }
    return _isPrimitive;
  },

  /**
   * primitive 값인 경우 wrapping 된 객체로 변환하여 가져온다.
   *   - wrapping 할 수 있는 primitive인 String, Number, Boolean만 가능
   * @param {*} val
   * @returns primitive 값을 wrapping한 객체
   */
  primitiveToWrapper(val) {
    let wrappedVal = val;
    if (typeUtil.isPrimitive(val)) {
      if (typeUtil.isNumber(val)) {
        // eslint-disable-next-line no-new-wrappers
        wrappedVal = new Number(val);
      } else if (typeUtil.isString(val)) {
        // eslint-disable-next-line no-new-wrappers
        wrappedVal = new String(val);
      } else if (typeUtil.isBoolean(val)) {
        // eslint-disable-next-line no-new-wrappers
        wrappedVal = new Boolean(val);
      }
    }
    return wrappedVal;
  },

  /**
   * 현재 객체(값)의 type이 Function인지를 체크한다.
   * @param {*} val
   * @returns Function 여부
   */
  isFunction(val) {
    return val instanceof Function || typeof val === 'function';
  },

  /**
   * 현재 객체(값)의 type이 Object인지를 체크한다.
   *   Array의 경우도 Object로 체크되어 true를 리턴함에 주의한다.
   * @param {*} val
   * @returns Object 여부(Array인 경우도 true로 리턴됨)
   */
  isObject(val) {
    return val instanceof Object || typeof val === 'object';
  },

  /**
   * 현재 객체(값)의 type이 Array인지를 체크한다.
   * @param {*} val
   * @returns Array 여부
   */
  isArray(val) {
    return val instanceof Array;
  },

  /**
   * 현재 객체(값)이 Plain Object인지 체크한다.
   * @param {*} val
   * @returns Plain Object 여부
   */
  isPlainObject(val) {
    return typeof val === 'object' && !(val instanceof Array);
  },

  /**
   * 현재 객체(값)이 비어있는 Plain Object인지 체크한다.
   * @param {*} val
   * @returns 비어있는 Plain Object 여부
   */
  isEmptyObject(val) {
    return typeUtil.isPlainObject(val) && Object.keys(val).length === 0;
  },

  /**
   * 현재 객체(값)이 비어있는 Array인지 체크한다.
   * @param {*} val
   * @returns 비어있는 Array 여부
   */
  isEmptyArray(val) {
    return typeUtil.isArray(val) && Object.keys(val).length === 0;
  },

  /**
   * 현재 객체(값)이 비어있는 String인지 체크한다.
   * @param {*} val
   * @returns 비어있는 String 여부
   */
  isEmptyString(val) {
    return typeUtil.isString(val) && (val?.trim()?.length ?? 0) === 0;
  },

  /**
   * 현재 객체(값)이 비어있는지 체크한다.
   *   null, undefined, length가 0인 array, key-value 쌍이 하나도 없는 object, trim한 길이가 0인 문자열, falthy를 비었다고 판단한다.
   * @param {*} val
   * @returns 비어있는 객체(값) 여부
   */
  isEmpty(val) {
    return (
      typeUtil.isNull(val) ||
      typeUtil.isUndefined(val) ||
      typeUtil.isEmptyArray(val) ||
      typeUtil.isEmptyObject(val) ||
      typeUtil.isEmptyString(val) ||
      !val
    );
  },
};

export default typeUtil;
