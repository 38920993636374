/* eslint-disable prefer-regex-literals */
/**
 * XSS 디코딩
 * @param {String} encodedStr XSS디코딩 처리할 인코딩된 문자열
 * @returns XSS디코딩 처리된 문자열
 */
import lottie from 'lottie-web';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import URLParser, { toWcmsUrl } from './url-parser';
gsap.registerPlugin(ScrollToPlugin);
export const decodeXss = encodedStr => {
  let tmp = encodedStr;
  if (typeof tmp === 'string') {
    tmp = tmp.replace(/&#x27;/gi, "'");
    tmp = tmp.replace(/&amp;/gi, '&');
    tmp = tmp.replace(/&quot;/gi, '"');
    tmp = tmp.replace(/&lt;/gi, '<');
    tmp = tmp.replace(/&gt;/gi, '>');
    tmp = tmp.replace(/&#x2F;/gi, '/');
  }
  return tmp;
};

/**
 * WCMS PATH -> WCMS 절대경로 Full URL로 변환
 * @param {String} wcmsPath
 * @returns WCMS 절대경로 Full URL
 */
export const convertWcmsPath = wcmsPath => {
  let tmp = wcmsPath;
  if (typeof tmp === 'string') {
    if (tmp.startsWith('/wcms')) {
      tmp = tmp.replace(/\/wcms/, '');
    }
  }
  const parsedUrl = new URLParser(toWcmsUrl(tmp)).tokenize();
  parsedUrl.pathname = '/wcms' + parsedUrl.pathname;
  return parsedUrl.toString();
};

/**
 * XSS Decoding + WCMS URL 변환 체이닝 하여 한번에 처리
 * @param {String} encodedWcmsPath
 * @returns XSS Decoding 된 WCMS 절대경로 Full URL
 */
export const resolveWcmsPath = encodedWcmsPath => {
  return convertWcmsPath(decodeXss(encodedWcmsPath));
};

/**
 * 입력받은 문자열에서 '@'를 반환하여 이메일 타입 체크
 * @param {String} val
 * @returns 배열 중 첫번째 '@'
 */
export const getEmailLocalPart = val => {
  if (!val || val.split('@')[0].length === 0) return '';
  return val.split('@')[0];
};

/**
 * 입력받은 문자열을 3자리씩 끊고 ,를 붙여줌
 * @param {String}} val
 * @returns ,가 포함된 문자열
 */
export const addComma = val => {
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const removeComma = val => {
  return String(val).replace(/,/gi, '');
};

/**
 * 마이데이터 default field 값을 설정한다.
 * @param {String} val
 * @returns 하이픈(-)
 */
export const setDefaultMydataField = val => {
  if (val === null || val === undefined) {
    return '-';
  } else if (typeof val === 'string' && val.trim() === '') {
    return '-';
  } else {
    return val;
  }
};

/**
 * element의 offset 값 구하기 : jquery의 offset()과 같은 역활
 * @param {String} elememet
 * @returns {top: val, left: val}
 */
export const getOffset = element => {
  let $el = element;
  let $elX = 0;
  let $elY = 0;
  while ($el && !Number.isNaN($el.offsetLeft) && !Number.isNaN($el.offsetTop)) {
    $elX += $el.offsetLeft;
    $elY += $el.offsetTop;
    $el = $el.offsetParent;
    if ($el !== null) {
      $elX += parseInt(getComputedStyle($el).borderLeftWidth);
      $elY += parseInt(getComputedStyle($el).borderTopWidth);
    }
  }
  return { top: $elY, left: $elX };
};

/**
 * window scrollTop animation
 * windowScrollTop(top:Number, duration:Number, callback:Function)
 */
export const windowScrollTop = (topVal, duration = 300, callback) => {
  if (typeof topVal !== 'number') return;
  // targetScroll(window, { top: topVal }, duration, callback);
  gsap.to(window, {
    duration: duration / 1000,
    scrollTo: { y: topVal },
    onComplete: () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  });
};

/**
 * target scroll animation
 * targetScroll(element, {top: value(Number),top: value(Number), duration(Number), callback(Function))
 */
export const targetScroll = (element, option, duration = 300, callback) => {
  if (option === undefined || (option.top === undefined && option.left === undefined)) return;
  const $el = element === 'window' ? element === window : element;
  let $left = 0;
  let $top = 0;
  if (option.left === undefined) {
    $left = $el === window ? window.pageXOffset : $el.scrollLeft;
  } else {
    $left = option.left;
  }
  if (option.top === undefined) {
    $top = $el === window ? window.pageYOffset : $el.scrollTop;
  } else {
    $top = option.top;
  }
  gsap.to($el, {
    duration: duration / 1000,
    scrollTo: {
      x: $left,
      y: $top,
    },
    onComplete: () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  });
};

/**
 * target이 화면 밖일때 스크린 안으로 스크롤 애니메이션
 * scrollInScreen(elememet:String, duration:Number, callback:Function)
 */
export const scrollInScreen = (elememet, duration = 300, callback) => {
  const $scrollY = window.pageYOffset;
  const $winH = window.innerHeight;
  const $el = elememet;
  if (!$el) return;
  const $headerH = document.querySelector('.sc-sub-header') ? document.querySelector('.sc-sub-header').offsetHeight : 0;
  const $footerH = document.querySelector('.v-footer--fixed')
    ? document.querySelector('.v-footer--fixed').offsetHeight
    : 0;
  const $elTop = getOffset($el).top;
  const $elHeight = $el.offsetHeight;
  const $scrollEnd = $scrollY + $winH - $footerH;
  const $elStart = $elTop - $headerH;
  const $elEnd = $elTop + $elHeight;

  if ($scrollY > $elStart) {
    windowScrollTop($elStart, duration, callback);
  } else if ($scrollEnd < $elEnd) {
    const $sclTop = $scrollY + ($elEnd - $scrollEnd);
    windowScrollTop(Math.min($sclTop, $elStart), duration, callback);
  }
};

/**
 * 형제 요소(엘리먼트) 갖고오기
 * getSiblings(elememet:String)
 */
export const getSiblings = element => {
  const siblings = [];
  if (!element.parentNode) return siblings;
  let sibling = element.parentNode.firstChild;
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== element) siblings.push(sibling);
    sibling = sibling.nextSibling;
  }
  return siblings;
};

/**
 * 해당하는 부모 엘리먼트들 모두 갖고오기
 * getParents(elememet:String, selector:String)
 */
export const getParents = (elem, selector) => {
  const parents = [];
  while (elem && elem !== document) {
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem);
      }
    } else {
      parents.push(elem);
    }
    elem = elem.parentNode;
  }

  return parents;
};

/**
 * 해당 부모 엘리먼트만 갖고오기 (IE 용)
 * getClosest(elememet:String, selector:String)
 */
export const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        const matches = (this.document || this.ownerDocument).querySelectorAll(s);
        let i = matches.length;
        // eslint-disable-next-line no-empty
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  let el = elem;
  do {
    if (Element.prototype.matches.call(el, selector)) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);
  return null;
};

/**
 * html용(wcms) 아코디언
 * scrollInScreen(elememet:String, duration:Number, callback:Function)
 * scrollInScreen(elememet:String, option:Object, callback:Function)
 * option 설정 : btnClass, panelClass, activeClass, duration, afterScroll(열리고 나서 스크롤 여부)

  예시)
  const folding = this.$el.querySelectorAll('.foldings');
  htmlFolding(folding);
  htmlFolding(folding, 500);
  htmlFolding(folding, 500, () => {
    console.log('open end')
  });
  htmlFolding(folding,{
    btnClass: 'btn',
    panelClass: 'panel',
    activeClass: 'active',
    afterScroll: false,
    daration: 1000,
  }, () => {
    console.log('open end')
  });
 */
export const htmlFolding = (elememet, option, callback) => {
  // default option
  let itemClass = 'folding';
  let btnClass = 'folding-btn';
  let panelClass = 'folding-panel';
  let activeClass = 'open';
  let duration = 300;
  let afterScroll = true;

  // change option
  if (option) {
    if (typeof option === 'number') {
      duration = option;
    } else if (typeof option === 'object') {
      if (option.itemClass !== undefined) itemClass = option.itemClass;
      if (option.btnClass !== undefined) btnClass = option.btnClass;
      if (option.panelClass !== undefined) panelClass = option.panelClass;
      if (option.activeClass !== undefined) activeClass = option.activeClass;
      if (option.afterScroll !== undefined) afterScroll = option.afterScroll;
      if (option.duration !== undefined) duration = option.duration;
    }
  }

  // ready event
  const readyEvt = el => {
    const $el = el;
    const $fdIndex = document.querySelectorAll('[data-fd]').length;
    $el.dataset.fd = $fdIndex;
    // const $children = $el.children;
    // Array.prototype.forEach.call($children, (li, liIndex) => {
    const $children = $el.querySelectorAll('.' + itemClass);
    $children.forEach((item, itemIndex) => {
      const $isOpen = !!item.classList.contains(activeClass);
      const $btn = item.querySelector('.' + btnClass);
      const $panel = item.querySelector('.' + panelClass);
      const $btnID = $btn.getAttribute('id') ? $btn.getAttribute('id') : 'fd_btn_' + $fdIndex + '_' + itemIndex;
      const $panelID = $panel.getAttribute('id') ? $panel.getAttribute('id') : 'fd_panel_' + $fdIndex + '_' + itemIndex;

      if ($btn) {
        if (!$btn.getAttribute('id')) $btn.setAttribute('id', $btnID);
        $btn.setAttribute('aria-controls', $panelID);
        if ($btn.tagName === 'A') $btn.setAttribute('href', '#' + $panelID);
        if ($isOpen) {
          $btn.setAttribute('aria-expanded', 'true');
        } else {
          $btn.setAttribute('aria-expanded', 'false');
        }
      }

      if ($panel) {
        if (!$panel.getAttribute('id')) $panel.setAttribute('id', $panelID);
        $panel.setAttribute('role', 'region');
        $panel.setAttribute('aria-labelledby', $btnID);
        if ($isOpen) {
          $panel.style.display = 'block';
        } else {
          $panel.style.display = 'none';
        }
      }
    });
  };

  // slide event
  const slideUp = el => {
    const $el = el;
    $el.style.overflow = 'hidden';
    gsap.to($el, {
      duration: duration / 1000,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      onComplete: () => {
        $el.style.display = 'none';
        $el.style.overflow = '';
        $el.style.height = '';
        $el.style.paddingTop = '';
        $el.style.paddingBottom = '';
        $el.style.borderTopWidth = '';
        $el.style.borderBottomWidth = '';
      },
    });
  };
  const slideDown = el => {
    const $el = el;
    $el.style.height = 0 + 'px';
    $el.style.display = 'block';
    $el.style.overflow = 'hidden';
    $el.style.visibilty = 'hidden';
    const $pdT = parseInt(window.getComputedStyle($el).paddingTop);
    const $pdB = parseInt(window.getComputedStyle($el).paddingBottom);
    const $bdT = parseInt(window.getComputedStyle($el).borderTopWidth);
    const $bdB = parseInt(window.getComputedStyle($el).borderBottomWidth);
    const $ScrollHeight =
      window.getComputedStyle($el).boxSizing === 'border-box'
        ? $el.scrollHeight
        : $el.scrollHeight - $pdT - $pdB - $bdT - $bdB;
    $el.style.paddingTop = 0 + 'px';
    $el.style.paddingBottom = 0 + 'px';
    $el.style.borderTopWidth = 0 + 'px';
    $el.style.borderBottomWidth = 0 + 'px';
    $el.style.visibilty = '';
    gsap.to($el, {
      duration: duration / 1000,
      height: $ScrollHeight,
      paddingTop: $pdT,
      paddingBottom: $pdB,
      borderTopWidth: $bdT,
      borderBottomWidth: $bdB,
      onComplete: () => {
        $el.style.overflow = '';
        $el.style.height = '';
        $el.style.paddingTop = '';
        $el.style.paddingBottom = '';
        $el.style.borderTopWidth = '';
        $el.style.borderBottomWidth = '';
        if (afterScroll) {
          scrollInScreen($el.closest('.' + itemClass));
        }
        if (callback && typeof callback === 'function') {
          callback();
        }
      },
    });
  };

  // click event
  const clickEvt = e => {
    const $target = e.target.closest('.' + btnClass) ? e.target.closest('.' + btnClass) : e.target;
    const $isToggle = !!$target.closest('.is-toggle');
    if ($target.classList.contains(btnClass)) {
      e.preventDefault();
      const $parent = $target.closest('.' + itemClass);
      const $panel = $parent.querySelector('.' + panelClass);
      if (!$panel) return;

      if ($parent.classList.contains(activeClass)) {
        $target.setAttribute('aria-expanded', 'false');
        $parent.classList.remove(activeClass);
        slideUp($panel);
      } else {
        $target.setAttribute('aria-expanded', 'true');
        $parent.classList.add(activeClass);
        slideDown($panel);

        if ($isToggle) {
          const $liSiblings = getSiblings($parent);
          $liSiblings.forEach(siblings => {
            siblings.classList.remove(activeClass);
            const $siblingsBtn = siblings.querySelector('.' + btnClass);
            const $siblingsPanel = siblings.querySelector('.' + panelClass);
            $siblingsBtn.setAttribute('aria-expanded', 'false');
            slideUp($siblingsPanel);
          });
        }
      }
    }
  };

  // init
  if (elememet) {
    readyEvt(elememet);
    elememet.removeEventListener('click', clickEvt);
    elememet.addEventListener('click', clickEvt);
  }
};

export const htmlTab = (elememet, option) => {
  // default option
  let menuClass = 'tab-menu';
  let listClass = 'tab-list';
  let itemClass = 'tab-item';
  let btnClass = 'tab';
  let activeClass = 'tab-active';
  let contensClass = 'tab-contents';
  let panelClass = 'tab-panel';
  let activePanelClass = 'tab-panel-active';
  let enterPanelClass = 'tab-panel-enter';
  let leavePanelClass = 'tab-panel-leave';
  let panelInLeftClass = 'panel-in-left';
  let panelInRightClass = 'panel-in-right';
  let lineClass = 'tab-line';
  let lineMovingClass = 'line-moving';

  // change option
  if (option) {
    if (option.menuClass !== undefined) menuClass = option.menuClass;
    if (option.listClass !== undefined) listClass = option.listClass;
    if (option.itemClass !== undefined) itemClass = option.itemClass;
    if (option.btnClass !== undefined) btnClass = option.btnClass;
    if (option.activeClass !== undefined) activeClass = option.activeClass;
    if (option.contensClass !== undefined) contensClass = option.contensClass;
    if (option.panelClass !== undefined) panelClass = option.panelClass;
    if (option.activePanelClass !== undefined) activePanelClass = option.activePanelClass;
    if (option.enterPanelClass !== undefined) enterPanelClass = option.enterPanelClass;
    if (option.leavePanelClass !== undefined) leavePanelClass = option.leavePanelClass;
    if (option.panelInLeftClass !== undefined) panelInLeftClass = option.panelInLeftClass;
    if (option.panelInRightClass !== undefined) panelInRightClass = option.panelInRightClass;
    if (option.lineClass !== undefined) lineClass = option.lineClass;
    if (option.lineMovingClass !== undefined) lineMovingClass = option.lineMovingClass;
  }

  // tab line 애니메이션
  const lineMove = wrap => {
    const $line = wrap.querySelector('.' + lineClass);
    if (!$line) return;
    wrap.classList.add(lineMovingClass);
    const $active = wrap.querySelector('.' + itemClass + '.' + activeClass);
    const $activeW = $active.offsetWidth;
    const $activeL = $active.offsetLeft + parseInt(getComputedStyle(wrap).paddingLeft);
    $line.style.width = $activeW + 'px';
    $line.style.left = $activeL + 'px';

    const transitionendEvt = () => {
      wrap.classList.remove(lineMovingClass);
      $line.removeEventListener('trasitionend', transitionendEvt);
    };
    $line.addEventListener('transitionend', transitionendEvt);
  };

  // active 중앙 스크롤
  const activeCenter = wrap => {
    const $active = wrap.querySelector('.' + itemClass + '.' + activeClass);
    const $wrapW = wrap.offsetWidth;
    const $activeW = $active.offsetWidth;
    const $activeL = $active.offsetLeft + parseInt(getComputedStyle(wrap).paddingLeft);
    const $leftVal = $activeL + $activeW / 2 - $wrapW / 2;
    targetScroll(wrap, { left: $leftVal });
  };

  // ready 이벤트
  const readyEvt = el => {
    const $el = el;
    const $tabsIndex = document.querySelectorAll('[data-tab]').length;
    $el.dataset.tab = $tabsIndex;
    const $menu = $el.querySelector('.' + menuClass);
    const $list = $menu.querySelector('.' + listClass);
    const $items = $list.querySelectorAll('.' + itemClass);
    const $contens = $el.querySelector('.' + contensClass);
    const $panels = $contens.querySelectorAll('.' + panelClass);
    const $itemsActive = $list.querySelector('.' + itemClass + '.' + activeClass);

    $list.setAttribute('role', 'tablist');
    $panels.forEach(panel => {
      panel.setAttribute('role', 'tabpanel');
      panel.setAttribute('aria-expanded', 'false');
    });

    $items.forEach((item, itemIndex) => {
      item.setAttribute('role', 'presentation');
      const $isOpen = !!item.classList.contains(activeClass);
      const $btn = item.querySelector('.' + btnClass);

      if ($btn) {
        $btn.setAttribute('role', 'tab');
        const $btnID = $btn.getAttribute('id') ? $btn.getAttribute('id') : 'tab_btn_' + $tabsIndex + '_' + itemIndex;
        if (!$btn.getAttribute('id')) $btn.setAttribute('id', $btnID);
        const $panel = $panels[itemIndex];
        if ($panel === undefined) {
          item.style.display = 'none';
        } else {
          const $panelID = $panel.getAttribute('id')
            ? $panel.getAttribute('id')
            : 'tab_panel_' + $tabsIndex + '_' + itemIndex;
          if ($btn.tagName === 'A') $btn.setAttribute('href', '#' + $panelID);
          $btn.setAttribute('aria-selected', 'false');
          $btn.setAttribute('aria-controls', $panelID);
          $panel.setAttribute('aria-labelledby', $btnID);

          if ($isOpen || (!$itemsActive && itemIndex === 0)) {
            item.classList.add(activeClass);
            $btn.setAttribute('aria-selected', 'true');
            $panel.setAttribute('aria-expanded', 'true');
            $panel.classList.add(activePanelClass);
            $contens.style.height = $panel.offsetHeight + 'px';
          }
        }
      }
    });

    lineMove($menu);
    activeCenter($menu);
  };

  // 탭 활성화 이벤트
  const activeEvt = (wrap, index, isTouch = false) => {
    const $menu = wrap;
    let $idx = index;
    const $list = $menu.querySelector('.' + listClass);
    const $items = $list.querySelectorAll('.' + itemClass);
    if ($idx >= $items.length) {
      $idx = 0;
    } else if ($idx < 0) {
      $idx = $items.length - 1;
    }
    const $item = $items[$idx];
    const $btn = $item.querySelector('.' + btnClass);
    const $contens = $menu.parentNode.querySelector('.' + contensClass);
    const $panels = $contens.querySelectorAll('.' + panelClass);
    const $panel = $panels[$idx];
    const $itemsActive = $list.querySelector('.' + itemClass + '.' + activeClass);
    const $beforeIdx = Array.from($items).indexOf($itemsActive);
    const $beforePanel = $panels[$beforeIdx];
    if (!$panel || $idx === $beforeIdx) return;
    $items.forEach(item => {
      item.classList.remove(activeClass);
      item.querySelector('.' + btnClass).setAttribute('aria-selected', 'false');
    });
    $item.classList.add(activeClass);
    $btn.setAttribute('aria-selected', 'true');
    if (
      isTouch &&
      (($idx === 0 && $beforeIdx === $items.length - 1) || ($beforeIdx === 0 && $idx === $items.length - 1))
    ) {
      if ($idx === 0 && $beforeIdx === $items.length - 1) {
        $contens.classList.add(panelInRightClass);
      } else if ($beforeIdx === 0 && $idx === $items.length - 1) {
        $contens.classList.add(panelInLeftClass);
      }
    } else if ($idx < $beforeIdx) {
      $contens.classList.add(panelInLeftClass);
    } else {
      $contens.classList.add(panelInRightClass);
    }

    $panels.forEach(panel => {
      panel.setAttribute('aria-expanded', 'false');
      panel.classList.remove(activePanelClass, enterPanelClass, leavePanelClass);
    });
    $panel.setAttribute('aria-expanded', 'true');
    $panel.classList.add(activePanelClass, enterPanelClass);
    $contens.style.height = $panel.offsetHeight + 'px';
    $beforePanel.classList.add(leavePanelClass);

    const animationendEvt = () => {
      $contens.classList.remove(panelInRightClass, panelInLeftClass);
      $panel.classList.remove(enterPanelClass);
      $beforePanel.classList.remove(leavePanelClass);
      $panel.removeEventListener('animationend', animationendEvt);
    };
    $panel.addEventListener('animationend', animationendEvt);

    lineMove($menu);
    activeCenter($menu);
  };

  // click 이벤트
  const clickEvt = e => {
    const $target = e.target.closest('.' + btnClass) ? e.target.closest('.' + btnClass) : e.target;
    if ($target.classList.contains(btnClass)) {
      e.preventDefault();
      const $menu = $target.closest('.' + menuClass);
      const $items = $menu.querySelectorAll('.' + itemClass);
      const $item = $target.closest('.' + itemClass);
      const $idx = Array.from($items).indexOf($item);
      activeEvt($menu, $idx);
    }
  };

  // 터치 이벤트
  let $startTouchX = 0;
  let $startTouchY = 0;
  let $endTouchX = 0;
  let $endTouchY = 0;
  const gapX = 80;
  const gapY = 30;
  let $isTargetScl = false;
  const touchEvt = e => {
    if (e.type !== 'touchstart' && e.type !== 'touchmove' && e.type !== 'touchend') return;
    let $target = e.target;
    const $contens = $target.closest('.' + contensClass) ? $target.closest('.' + contensClass) : $target;
    const $menu = $contens.parentNode.querySelector('.' + menuClass);
    const $items = $menu.querySelectorAll('.' + itemClass);
    const $active = $menu.querySelector('.' + itemClass + '.' + activeClass);
    let $idx = Array.from($items).indexOf($active);

    if (e.type === 'touchstart') {
      $startTouchX = e.touches[0].clientX;
      $startTouchY = e.touches[0].clientY;
      while (!$target.classList.contains(contensClass) && !$isTargetScl) {
        if ($target.scrollWidth > $target.offsetWidth) $isTargetScl = true;
        $target = $target.parentNode;
      }
    }
    if (e.type === 'touchend') {
      $endTouchX = e.changedTouches[0].clientX;
      $endTouchY = e.changedTouches[0].clientY;
      // console.log(Math.abs($endTouchX - $startTouchX), gapX, Math.abs($endTouchY - $startTouchY), gapY);
      if (!$isTargetScl && Math.abs($endTouchX - $startTouchX) > gapX && Math.abs($endTouchY - $startTouchY) < gapY) {
        if ($endTouchX - $startTouchX > gapX) {
          // tab to left
          $idx -= 1;
        } else if ($startTouchX - $endTouchX > gapX) {
          // tab to right
          $idx += 1;
        }
        activeEvt($menu, $idx, true);
      }
      $isTargetScl = false;
    }
  };

  const resizeEvt = () => {
    const $menu = document.querySelectorAll('.' + menuClass);
    if ($menu.length) {
      $menu.forEach(el => {
        lineMove(el);
      });
    }
    const $contens = document.querySelectorAll('.' + contensClass);
    if ($contens.length) {
      $contens.forEach(el => {
        const $activePanel = el.querySelector('.' + activePanelClass);
        if ($activePanel) el.style.height = $activePanel.offsetHeight + 'px';
      });
    }
  };

  // init
  if (elememet) {
    readyEvt(elememet);
    elememet.removeEventListener('click', clickEvt);
    elememet.addEventListener('click', clickEvt);

    const $contents = elememet.querySelector('.' + contensClass);
    if ($contents) {
      $contents.removeEventListener('touchstart', touchEvt);
      $contents.removeEventListener('touchend', touchEvt);
      $contents.addEventListener('touchstart', touchEvt);
      $contents.addEventListener('touchend', touchEvt);
    }
  } else {
    return;
  }

  window.removeEventListener('resize', resizeEvt);
  window.addEventListener('resize', resizeEvt, { passive: true });
};

export const isDeviceCheck = () => {
  const obj = {
    pc: [],
    mobile: [],
  };
  const $agent = navigator.userAgent;
  let isPc = false;
  let isMobile = false;

  // pc
  if ($agent.match(/windows/i) != null) {
    obj.pc.push('window');
    isPc = true;
  }
  if ($agent.match(/macintosh/i) != null) {
    obj.pc.push('mac');
    isPc = true;
  }
  if (isPc) {
    obj.pc.push('is-pc');
    if ($agent.match(/rv:11.0|msie/i) != null) obj.pc.push('msie');
    if ($agent.match(/rv:11.0/i) != null) obj.pc.push('ie11');
    if ($agent.match(/msie 10.0/i) != null) obj.pc.push('ie10');
    if ($agent.match(/msie 9.0/i) != null) obj.pc.push('ie9');
    if ($agent.match(/msie 8.0/i) != null) obj.pc.push('ie8');
    if ($agent.match(/edge/i) != null) obj.pc.push('edge');
    else if ($agent.match(/opera|OPR/i) != null) obj.pc.push('opera');
    else if ($agent.match(/chrome/i) != null) obj.pc.push('chrome');
    else if ($agent.match(/safari/i) != null) obj.pc.push('safari');
    else if ($agent.match(/firefox/i) != null) obj.pc.push('firefox');
  }

  // mobile
  if ($agent.match(/Android/i) != null) {
    obj.mobile.push('android');
    isMobile = true;
  }
  if ($agent.match(/iPhone|iPad|iPod|iOS/i) != null) {
    obj.mobile.push('ios');
    isMobile = true;
  }
  if ($agent.match(/BlackBerry/i) != null) {
    obj.mobile.push('blackberry');
    isMobile = true;
  }
  if ($agent.match(/Opera Mini/i) != null) {
    obj.mobile.push('opera');
    isMobile = true;
  }
  if ($agent.match(/IEMobile/i) != null) {
    obj.mobile.push('ie-mobile');
    isMobile = true;
  }
  if (isMobile) {
    obj.mobile.push('is-mobile');
    if ($agent.match(/iPhone/i) != null) obj.mobile.push('iphone');
    if ($agent.match(/iPad/i) != null) obj.mobile.push('ipad');
    if ($agent.match(/chrome/i) != null) obj.mobile.push('chrome');
    else if ($agent.match(/safari/i) != null) obj.mobile.push('safari');
    if ($agent.includes('NAVER(inapp')) obj.mobile.push('naver-app');
    if ($agent.match(/DaumApps/i) != null) obj.mobile.push('daum-app');
    if ($agent.match(/KAKAOTALK/i) != null) obj.mobile.push('kakaotalk');
  }

  return obj;
};

export const isAppCheck = () => {
  const $agent = navigator.userAgent;
  return $agent.includes('Monimo');
};

export const iosVersionCheck = () => {
  const $agent = navigator.userAgent;
  let $version = false;
  let $start = null;
  let $end = null;
  if (isDeviceCheck().mobile.includes('ios')) {
    if ($agent.includes('OS ')) $start = $agent.indexOf('OS ') + 3;
    if ($agent.includes('_')) $end = $agent.indexOf('_');
    if ($start !== null && $end !== null) $version = parseInt($agent.slice($start, $end));
  }
  return $version;
};

export const debounce = (fn, delay) => {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};
export const throttle = (fn, delay) => {
  let timer;
  return function () {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, delay);
    }
  };
};

export const scrollAnimate = elememet => {
  const $wrap = elememet;
  let $winSclTop = 0;
  let $scrollItems = [];

  // animation type
  let isMobile = false;
  let $textRightVal;
  let $textLeftVal;
  let $textUpVal;
  let $textDownVal;
  const $minScale = 0.7;
  const $maxScale = 3;
  function setTypeOption() {
    const $winWidth = window.innerWidth;
    if ($winWidth <= 768) {
      isMobile = true;
    } else {
      isMobile = false;
    }
    $textRightVal = isMobile ? 50 : 100;
    $textLeftVal = isMobile ? -50 : -100;
    $textUpVal = isMobile ? 40 : 80;
    $textDownVal = isMobile ? -40 : -80;
  }

  function setSclItems() {
    $scrollItems = [];
    const $items = $wrap.querySelectorAll('[data-scl-animate]');
    $items.forEach(el => {
      const $top = getOffset(el).top;
      const $height = el.offsetHeight;
      const $animation = el.dataset.sclAnimate.split(',').map(item => {
        return item.trim();
      });
      const obj = {
        obj: el,
        active: false,
        trueActive: false,
        top: $top,
        height: $height,
        animation: $animation,
        loadShow: false,
      };
      $scrollItems.push(obj);
    });
  }

  function sclItemsChk() {
    setTypeOption();
    $winSclTop = window.pageYOffset;
    const $winHeight = window.innerHeight;
    const $gap = $winHeight * 0.1;

    for (let i = 0; i < $scrollItems.length; i++) {
      const $item = $scrollItems[i];
      const $start = $item.top;
      const $end = $start + $item.height;
      if ($start < $winSclTop + $winHeight + $gap && $end > $winSclTop - $gap) {
        $item.active = true;
      } else {
        $item.active = false;
      }
      if ($start < $winSclTop + $winHeight && $end > $winSclTop) {
        $item.trueActive = true;
      } else {
        $item.trueActive = false;
      }
    }
  }

  function loadAnimation() {
    let index = 1;
    for (let i = 0; i < $scrollItems.length; i++) {
      const $item = $scrollItems[i];
      const $obj = $item.obj;
      const $animation = $item.animation;
      if ($animation.includes('opacity')) {
        $obj.style.opacity = 0;
      }
      if ($animation.includes('zoomIn')) {
        $obj.style.transform = 'scale(' + $minScale + ')';
      }
      if ($animation.includes('zoomOut')) {
        $obj.style.transform = 'scale(' + $maxScale + ')';
      }
      if ($animation.includes('textRight')) {
        $obj.style.transform = 'translateX(' + $textRightVal + 'px)';
      }
      if ($animation.includes('textLeft')) {
        $obj.style.transform = 'translateX(' + $textLeftVal + 'px)';
      }
      if ($animation.includes('textUp')) {
        $obj.style.transform = 'translateY(' + $textUpVal + 'px)';
      }
      if ($animation.includes('textDown')) {
        $obj.style.transform = 'translateY(' + $textDownVal + 'px)';
      }

      if ($item.trueActive) {
        const opt = {
          duration: 0.3,
        };
        if ($animation.includes('opacity')) {
          opt.opacity = 1;
        }
        if ($animation.includes('zoomIn') || $animation.includes('zoomOut')) {
          opt.scale = 1;
        }
        if ($animation.includes('textRight') || $animation.includes('textLeft')) {
          opt.x = 0;
        }
        if ($animation.includes('textUp') || $animation.includes('textDown')) {
          opt.y = 0;
        }
        if (Object.keys(opt).length > 0) {
          setTimeout(() => {
            gsap.to($obj, opt);
            $item.loadShow = true;
          }, 300 * index);
          index += 1;
        }
      }
    }
  }

  function calcValues(item, from, to, outReverse = false) {
    let returnVal = null;
    // const $el = item.obj;
    const $elTop = item.top;
    const $elHeight = item.height;
    const $winHeight = window.innerHeight;
    const $winRatioIn = 0.4;
    const $winRatioOut = 0.15;
    let isTopOut = false;
    let $sclRatio = ($winSclTop + $winHeight - $elTop) / ($winHeight * $winRatioIn);
    if ($winHeight / 2 < $elTop && $elTop < $winHeight) {
      $sclRatio = ($winSclTop + $winHeight - $elTop) / ($winHeight - $elTop);
    }
    if ($elTop < $winSclTop + $winHeight * $winRatioOut) {
      item.loadShow = false;
      isTopOut = true;
      if ($elTop < $winHeight * $winRatioOut) {
        $sclRatio = ($elTop - $winSclTop) / $elTop;
      } else {
        $sclRatio = ($elTop + $elHeight / 2 - $winSclTop) / ($winHeight * $winRatioOut);
      }
    }
    if ($sclRatio < 0) $sclRatio = 0;
    if ($sclRatio > 1 || item.loadShow) $sclRatio = 1;
    const $from = from;
    const $to = to;
    returnVal = $sclRatio * ($to - $from) + $from;
    if (outReverse && isTopOut) {
      if ($from > $to && $to === 1) {
        // returnVal = 1 - returnVal / $from;
        returnVal = $sclRatio;
      } else {
        returnVal = returnVal * -1;
      }
    }
    return returnVal;
  }

  function sclAnimation() {
    for (let i = 0; i < $scrollItems.length; i++) {
      const $item = $scrollItems[i];
      if ($item.active) {
        const $obj = $item.obj;
        const $animation = $item.animation;
        if ($animation.includes('opacity')) {
          const $opacity = calcValues($item, 0, 1);
          $obj.style.opacity = $opacity;
        }
        if ($animation.includes('zoomIn')) {
          const $scale = calcValues($item, $minScale, 1);
          $obj.style.transform = 'scale(' + $scale + ')';
        }
        if ($animation.includes('zoomOut')) {
          const $scale = calcValues($item, $maxScale, 1, true);
          $obj.style.transform = 'scale(' + $scale + ')';
        }
        if ($animation.includes('textRight')) {
          const $move = calcValues($item, $textRightVal, 0, true);
          $obj.style.transform = 'translateX(' + $move + 'px)';
        }
        if ($animation.includes('textLeft')) {
          const $move = calcValues($item, $textLeftVal, 0, true);
          $obj.style.transform = 'translateX(' + $move + 'px)';
        }
        if ($animation.includes('textUp')) {
          const $move = calcValues($item, $textUpVal, 0, true);
          $obj.style.transform = 'translateY(' + $move + 'px)';
        }
        if ($animation.includes('textDown')) {
          const $move = calcValues($item, $textDownVal, 0, true);
          $obj.style.transform = 'translateY(' + $move + 'px)';
        }
      }
    }
  }

  function scrollEvt() {
    sclItemsChk();
    sclAnimation();
  }
  function resizeEvt() {
    setSclItems();
    sclItemsChk();
    sclAnimation();
  }

  if (elememet) {
    setSclItems();
    sclItemsChk();
    loadAnimation();
    window.removeEventListener('scroll', scrollEvt);
    window.removeEventListener('resize', resizeEvt);
    window.addEventListener('scroll', scrollEvt, { passive: true });
    window.addEventListener('resize', resizeEvt, { passive: true });
  } else {
    window.removeEventListener('scroll', scrollEvt);
    window.removeEventListener('resize', resizeEvt);
  }
};

export const scrollAddClass = (elememet, isRemove = false) => {
  const $wrap = elememet;
  let $winSclTop = 0;
  let $scrollItems = [];

  function setSclItems() {
    $scrollItems = [];
    const $items = $wrap.querySelectorAll('[data-scl-class]');
    $items.forEach(el => {
      const $top = getOffset(el).top;
      const $height = el.offsetHeight;
      const $class = el.dataset.sclClass;
      const obj = {
        obj: el,
        active: false,
        top: $top,
        height: $height,
        class: $class,
        loadShow: false,
      };
      $scrollItems.push(obj);
    });
  }

  function rollingNumberReady(wrap) {
    const $wrap = wrap;
    let number = $wrap.innerText !== '' ? $wrap.innerText : $wrap.getAttribute('aria-label');
    if (number === null || number === undefined) number = '-';
    const $space = '<div>&nbsp;</div>';
    let innerHtml = '';
    if (number === '0') {
      innerHtml += '<div class="rolling-in">';
      innerHtml += $space;
      innerHtml += '<div>' + number + '</div>';
      innerHtml += '</div>';
    } else {
      for (let i = 0; i < number.length; i++) {
        if (parseInt(number[i]) >= 0) {
          innerHtml += '<div class="rolling-in is__number" data-num="' + number[i] + '">';
          const total = Number('4' + number[i]);
          innerHtml += $space;
          for (let j = 0; j <= total; j++) {
            innerHtml += '<div>' + (j % 10) + '</div>';
          }
        } else {
          innerHtml += '<div class="rolling-in">';
          innerHtml += $space;
          innerHtml += '<div>' + number[i] + '</div>';
        }
        innerHtml += '</div>';
      }
    }
    $wrap.innerHTML = innerHtml;
  }

  function rollingNumberInit(wrap, reset = false) {
    const $wrap = wrap;
    const $rollings = $wrap.querySelectorAll('.rolling-in');
    if ($rollings.length) {
      if (reset) {
        $wrap.classList.remove('is-ing');
      } else {
        $wrap.classList.add('is-ing');
      }
      $rollings.forEach((item, i) => {
        const $num = Number(item.dataset.num);
        item.style.animationDelay = i * 50 + 'ms';
        if (reset) {
          item.style.top = '';
        } else if (!reset) {
          if (item.classList.contains('is__number')) {
            item.style.top = (41 + $num) * -100 + '%';
          } else {
            item.style.top = -100 + '%';
          }
        }
      });
    }
  }

  const countDuration = 1;
  const countDelay = 0;
  function countNumber(target, isInit = true) {
    const $target = target;
    const $start = 0;
    let $end;
    if ($target.dataset.number === undefined) {
      const setNumber = parseInt(removeComma($target.innerText));
      $target.dataset.number = setNumber;
      $end = setNumber;
    } else {
      $end = parseInt($target.dataset.number);
    }
    const $value = { val: $end };
    $target.innerText = $start;
    if (isInit) {
      gsap.from($value, {
        duration: countDuration,
        delay: countDelay,
        val: $start,
        roundProps: 'val',
        onUpdate() {
          $target.innerText = addComma($value.val);
        },
      });
    }
  }

  function sclItemsChk() {
    $winSclTop = window.pageYOffset;
    const $winHeight = window.innerHeight;
    const $topGap = 0.1;
    const $bottomGap = 0.3;
    const $headH = document.querySelector('.w-header') ? 54 : 0;

    for (let i = 0; i < $scrollItems.length; i++) {
      const $item = $scrollItems[i];
      const $obj = $item.obj;
      const $class = $item.class;
      const $start = $item.top;
      const $center = $start + $item.height / 2;
      const $end = $start + $item.height;
      if ($start < $winSclTop + $winHeight && $end > $winSclTop) {
        $item.active = true;
      } else {
        $item.active = false;
      }
      if (isLoad) {
        let isAddClass = null;
        let videoTimer;
        const $video = $obj.querySelector('video');
        const $rollings = $obj.querySelectorAll('.rolling.number');
        const $countNumbers = $obj.querySelectorAll('.count__number');

        if ($item.loadShow) {
          if ($end < $winSclTop || $start > $winSclTop + $winHeight) {
            $item.loadShow = false;
            isAddClass = false;
          }
        } else if ($item.height > $winHeight * (1 - ($topGap + $bottomGap)) || $rollings.length) {
          if ($center < $winSclTop + $winHeight && $center > $winSclTop + $headH) {
            isAddClass = true;
          } else if (isRemove) isAddClass = false;
        } else if ($end >= $winSclTop + $winHeight * $topGap && $start <= $winSclTop + $winHeight * (1 - $bottomGap)) {
          isAddClass = true;
        } else if (isRemove) isAddClass = false;

        if (isAddClass && !$obj.classList.contains($class)) {
          $obj.classList.add($class);
          if ($video) {
            clearTimeout(videoTimer);
            videoTimer = setTimeout(() => {
              $video.play();
            }, 500);
          }
          if ($rollings.length) {
            $rollings.forEach(item => {
              rollingNumberInit(item);
            });
          }
          if ($countNumbers.length) {
            $countNumbers.forEach(item => {
              countNumber(item);
            });
          }
          if (!isRemove) {
            $obj.removeAttribute('data-scl-class');
            // $scrollItems.splice(i, 1);
          }
        } else if (isAddClass === false && $obj.classList.contains($class) && isRemove) {
          $obj.classList.remove($class);
          if ($video) {
            clearTimeout(videoTimer);
            $video.pause();
            $video.currentTime = 0;
          }
          if ($rollings.length) {
            $rollings.forEach(item => {
              rollingNumberInit(item, true);
            });
          }
          if ($countNumbers.length) {
            $countNumbers.forEach(item => {
              countNumber(item, false);
            });
          }
        }
      }
    }
  }

  let isLoad = false;
  function loadEvt() {
    setSclItems();
    sclItemsChk();
    isLoad = true;
    let index = 0;
    for (let i = 0; i < $scrollItems.length; i++) {
      const $item = $scrollItems[i];
      const $obj = $item.obj;
      const $class = $item.class;
      const $rollings = $obj.querySelectorAll('.rolling.number');
      const $countNumbers = $obj.querySelectorAll('.count__number');

      if ($rollings.length) {
        $rollings.forEach(item => {
          rollingNumberReady(item);
        });
      }
      if ($countNumbers.length) {
        $countNumbers.forEach(item => {
          countNumber(item, false);
        });
      }
      if ($item.active) {
        $item.loadShow = true;
        setTimeout(() => {
          $obj.classList.add($class);
          if ($rollings.length) {
            $rollings.forEach(item => {
              rollingNumberInit(item);
            });
          }
          if ($countNumbers.length) {
            $countNumbers.forEach(item => {
              countNumber(item);
            });
          }
          if (!isRemove) {
            $obj.removeAttribute('data-scl-class');
            // $scrollItems.splice(i, 1);
          }
        }, 300 * index + 100);
        index += 1;
      }
    }
  }

  function scrollEvt() {
    sclItemsChk();
  }
  function resizeEvt() {
    setSclItems();
    sclItemsChk();
  }

  if (elememet) {
    setSclItems();
    sclItemsChk();
    setTimeout(() => {
      loadEvt();
    }, 500);
    window.removeEventListener('scroll', scrollEvt);
    window.removeEventListener('resize', resizeEvt);
    window.addEventListener('scroll', scrollEvt, { passive: true });
    window.addEventListener('resize', resizeEvt, { passive: true });
  } else {
    window.removeEventListener('scroll', scrollEvt);
    window.removeEventListener('resize', resizeEvt);
  }
};

/**
 * WCMS 처리관련 스크립트
 */
export const initWcmsHtml = el => {
  setAliasImage(el, null);
  setBgColor(el);
  setFontColor(el);
  setLottie(el);
};
const setTargetElement = el => {
  if (el != null) {
    return el;
  } else {
    return document;
  }
};
// 로띠 관련 처리 스크립트 (data-lotti 속성)
export const setLottie = el => {
  const $target = setTargetElement(el);
  const $el = $target.querySelectorAll('*[data-lotti-url]'); // UPFM-3377 미션 배너 미동작 조치
  for (let i = 0; i < $el.length; i++) {
    let lottiUrl = $el[i].getAttribute('data-lotti-url');
    const lottiLoop = $el[i].getAttribute('data-lotti-loop');
    lottiUrl = lottiUrl.replace(/ /gi, '');
    if (lottiUrl !== '' && lottiUrl != null) {
      if (!lottiUrl.includes('.json')) {
        // 로티json파일이 아닌 경우 이미지파일로 인식
        setAliasImage($target, 'data-lotti-url');
      } else if (lottiLoop) {
        $el[i].removeAttribute('data-lotti-url');
        (function (_$el, _lottiUrl) {
          lottie.loadAnimation({
            container: _$el,
            renderer: 'svg',
            loop: lottiLoop,
            autoplay: true,
            path: _lottiUrl,
          });
        })($el[i], lottiUrl);
      } else {
        $el[i].removeAttribute('data-lotti-url');
        (function (_$el, _lottiUrl) {
          lottie.loadAnimation({
            container: _$el,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: _lottiUrl,
          });
        })($el[i], lottiUrl);
      }
    }
  }
};
// 이미지 관련 처리 스크립트 (data-img 속성)
export const setAliasImage = (el, _imgUrl) => {
  let imgUrl = 'data-img-url';
  if (_imgUrl) {
    imgUrl = _imgUrl;
  }
  const $target = setTargetElement(el);
  const $el = $target.querySelectorAll('*[' + imgUrl + ']');
  for (let i = 0; i < $el.length; i++) {
    let bgImgUri = $el[i].getAttribute(imgUrl);
    if (bgImgUri === '' || bgImgUri === undefined || bgImgUri === null) continue;
    if (bgImgUri.includes('@images/')) {
      $el[i].removeAttribute(imgUrl);
      bgImgUri = bgImgUri.replace('~@images/', '').replace('@images/', '');
      bgImgUri = require('@images/' + bgImgUri);
    }
    $el[i].style.backgroundImage = 'url(' + bgImgUri + ')';
  }
  const $imgs = $target.querySelectorAll('img');
  for (let i = 0; i < $imgs.length; i++) {
    let bgImgUri = $imgs[i].getAttribute('src');
    if (bgImgUri === '' || bgImgUri === undefined || bgImgUri === null) continue;
    if (bgImgUri.includes('@images/')) {
      bgImgUri = bgImgUri.replace('~@images/', '').replace('@images/', '');
      bgImgUri = require('@images/' + bgImgUri);
    }
    $imgs[i].setAttribute('src', bgImgUri);
  }
};
// 백그라운드 컬러 처리 스크립트 (data-bg-color 속성)
export const setBgColor = el => {
  const $target = setTargetElement(el);
  const $el = $target.querySelectorAll('*[data-bg-color]');
  for (let i = 0; i < $el.length; i++) {
    const bgColor = $el[i].getAttribute('data-bg-color');
    $el[i].removeAttribute('data-bg-color');
    $el[i].style.backgroundColor = bgColor;
  }
};

// S: SnackBar
export const setVuetifyCallBack = () => {
  const $el = document.querySelectorAll('*[data-class-callback]');
  for (let i = 0; i < $el.length; i++) {
    const className = $el[i].getAttribute('data-class-callback');
    $el[i].removeAttribute('data-class-callback');
    (function (_className, _$el) {
      _$el.addEventListener('click', function (e) {
        document.querySelector('.' + _className).click();
      });
    })(className, $el[i]);
  }
};
// E: SnackBar
/// / wcms

// animate
export const animate = ({ duration, draw, timing }) => {
  const start = performance.now();
  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;
    const progress = timing(timeFraction);
    draw(progress);
    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });
};
// 치환 ex) 1억2천만원 -> <span>1</span>억<span>2</span>천만원
export const priceToTag = price => {
  const arr = price.split('');
  // eslint-disable-next-line no-useless-escape
  const reg = new RegExp(/[^0-9\,]/);
  let tag = '';
  for (let i = 0; i < arr.length; i++) {
    if (reg.exec(arr[i]) === null) {
      if (reg.exec(arr[i - 1]) === null) {
        tag += arr[i];
      } else {
        tag += '<span class="number">' + arr[i];
      }
    } else if (reg.exec(arr[i - 1]) === null) {
      tag += '</span>' + arr[i];
    } else {
      tag += arr[i];
    }
  }
  return tag;
};

/**
 * 체크박스 로티 초기화
 * @param
 * @returns
 */
export const checkboxLottieInit = () => {
  // vuetify v-checkbox를 사용하고 class로 master-checkbox를 추가했을 경우를 가정함
  const checkboxUrl = convertWcmsPath('/monimo/json/lotti/checkbox.json');
  document.querySelectorAll('.master-checkbox').forEach(e => {
    if (e.data === undefined) {
      e.data = {
        lottie: lottie.loadAnimation({
          container: e.querySelector('.v-icon'),
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: checkboxUrl,
        }),
      };
      e.querySelector('.v-icon').style.background = 'none';
    }
  });
  // vuetify v-checkbox를 사용하고 class로 child-checkbox를 추가했을 경우를 가정함
  document.querySelectorAll('.child-checkbox').forEach((e, index) => {
    if (e.data === undefined) {
      e.data = {
        lottie: lottie.loadAnimation({
          container: e.querySelector('.v-icon'),
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: '/lottie/check.json',
        }),
      };
      e.querySelector('.v-icon').style.background = 'none';
    }
  });

  document.querySelectorAll('ul[class*=subChild] .child').forEach((e, index) => {
    if (e.data === undefined) {
      e.data = {
        lottie: lottie.loadAnimation({
          container: e.querySelector('.v-icon'),
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: '/lottie/mydata/check-24.json',
        }),
      };
      e.querySelector('.v-icon').style.background = 'none';
    }
  });
};

// 빅배너 폰트 색상 WCMS로 적용
export const setFontColor = el => {
  const $target = setTargetElement(el);
  const $el = $target.querySelectorAll('*[data-color]');
  for (let i = 0; i < $el.length; i++) {
    const fontColor = $el[i].getAttribute('data-color');
    $el[i].removeAttribute('data-color');
    $el[i].style.color = fontColor;
  }
};

/**
 * wcms에 있는 로띠를 play/stop
 */
export const playLottieAnimate = el => {
  const $target = setTargetElement(el);
  $target.querySelectorAll('*[play-lotti-url]').forEach((e, index) => {
    const playAttribute = e.getAttribute('play-lotti-url');
    if (playAttribute != null) {
      e.data = {
        lottie: lottie.loadAnimation({
          container: e,
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: playAttribute,
        }),
      };
    }
  });
};

/**
 * 대상 element를 스크롤 top 위치로 이동 시키는 함수
 */
export const scrollMoveTop = (elememet, duration = 300, callback) => {
  const $el = elememet;
  if (!$el) return;
  const $headerH = document.querySelector('.sc-sub-header') ? document.querySelector('.sc-sub-header').offsetHeight : 0;
  const $elTop = getOffset($el).top;
  const $elStart = $elTop - $headerH;
  windowScrollTop($elStart, duration, callback);
};
