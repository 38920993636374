import { msg } from '@/api/pfm/cmn';
import SessionStorage from '@/utils/storage-util/session-storage.js';

export default {
  state: () => {
    return {
      fnitList: [], // 은행/증권/카드 리스트. wcms/monimo/common/openbanking/fnit_list.json
      fnitHppList: [], // 은행/증권/카드 리스트 (ver.personal). wcms/personal/openbanking/fnit_list.json
      fnitByKeywList: [], // 금융기관 키워드 리스트 wcms/monimo/app/more/credit/fin_key/fnitByKeyw.json
      fnitIconList: [], // 금융기관 아이콘 리스트 wcms/monimo/app/more/credit/fin_key/fnitIconList.json
      messageCodes: [],
      msgDialog: {
        contentInfo: {},
        visible: false,
      },
    };
  },
  getters: {
    /**
     * 은행/증권/카드 리스트를 가져온다.
     * @param {Object} state Vuex Store State
     * @returns 은행/증권/카드 리스트
     */
    getFnitList(state) {
      return state.fnitList;
    },
    /**
     * 은행/증권/카드 리스트를 가져온다. (ver.personal)
     * @param {Object} state Vuex Store State
     * @returns 은행/증권/카드 리스트
     */
    getFnitHppList(state) {
      return state.fnitHppList;
    },
    /**
     * 금융기관 키워드 리스트를 가져온다.
     * @param {Object} state Vuex Store State
     * @returns 금융기관 키워드 리스트
     */
    getFnitByKeywList(state) {
      return state.fnitByKeywList;
    },
    /**
     * 금융기관 아이콘 리스트를 가져온다. (ver.personal)
     * @param {Object} state Vuex Store State
     * @returns 금융기관 아이콘 리스트
     */
    getFnitIconList(state) {
      return state.fnitIconList;
    },
    /**
     * 공통 다이얼로그 노출여부를 가져온다. (ver.personal)
     * @param {Object} state Vuex Store State
     * @returns 공통 다이얼로그의 노출여부
     */
    getMsgDialog(state) {
      return state.msgDialog.visible;
    },
  },
  mutations: {
    /**
     * 은행/증권/카드 리스트 set
     * @param {Object} state Vuex Store State
     * @param {Object} list 은행/증권/카드 리스트
     */
    setFnitList(state, list) {
      state.fnitList = list;
    },
    /**
     * 은행/증권/카드 리스트 set (ver.personal)
     * @param {Object} state Vuex Store State
     * @param {Object} list 은행/증권/카드 리스트
     */
    setFnitHppList(state, list) {
      state.fnitHppList = list;
    },
    /**
     * 금융기관 키워드 리스트 set
     * @param {Object} state Vuex Store State
     * @param {Object} list 금융기관 키워드 리스트
     */
    setFnitByKeywList(state, list) {
      state.fnitByKeywList = list;
    },
    /**
     * 금융기관 아이콘 리스트 set
     * @param {Object} state Vuex Store State
     * @param {Object} list 금융기관 아이콘 리스트
     */
    setFnitIconList(state, list) {
      state.fnitIconList = list;
    },
    /**
     * 공통 메시지 단건 조회. sessionStorage item Get, Set
     * @param {Object} state Vuex Store State
     * @param {Object} resData messageCode api 조회 결과
     */
    setMessageCodeInfo(state, resData) {
      /* 메시지코드가 있고, 리스트가 있고, 메시지 코드가 신규일때!! ---- sesstion set
      메시지코드가 있고, 리스트가 있고, 메시지 코드가 기존에 있을때 --- 스토어만 update
      메시지 코드가 없을때, --- 스토어만 update */
      state.messageCodes = [];

      const getItem = SessionStorage.get('messageCodeData');
      if (resData.msgC) {
        if (getItem) {
          const rs = getItem.find(c => c.msgC === resData.msgC);
          if (!rs) {
            // getItem + new data
            getItem.push(resData);
            state.messageCodes = getItem;
            SessionStorage.set('messageCodeData', state.messageCodes);
          } else {
            state.messageCodes.push(rs);
          }
        } else {
          state.messageCodes.push(resData);
          SessionStorage.set('messageCodeData', state.messageCodes);
        }
      } else {
        state.messageCodes.push(resData);
      }
    },
    /**
     * 공통 메시지 show & hide, content 정보 set.
     * @param {Object} state Vuex Store State
     * @param {Object} msgDialogInfos visible, id등 정보
     */
    setMessageDialogShowHide(state, msgDialogInfos) {
      state.msgDialog = msgDialogInfos;
    },
  },
  actions: {
    /**
     * 은행/증권/카드 리스트 api call
     * @param {Object} state Vuex Store State
     * @param {Object} commit commit
     */
    async getFnitListApi({ state, commit }) {
      if (state.fnitList.length <= 0) {
        const result = await this.$wcms.$get('/monimo/common/openbanking/fnit_list.json');
        commit('setFnitList', result.list);
      }
    },
    /**
     * 은행/증권/카드 리스트 api call (ver.personal)
     * @param {Object} state Vuex Store State
     * @param {Object} commit commit
     */
    async getFnitHppListApi({ state, commit }) {
      if (state.fnitHppList.length <= 0) {
        let result = await this.$wcms.$get('/personal/openbanking/fnit_list.json');
        result = JSON.parse(result.toString().replace('fnitList(', '').replace(');', ''));
        commit('setFnitHppList', result.list);
      }
    },
    /**
     * 금융기관 키워드 리스트 api call
     * @param {Object} state Vuex Store State
     * @param {Object} commit commit
     */
    async getFnitByKeywListApi({ state, commit }) {
      if (state.fnitByKeywList.length <= 0) {
        const result = await this.$wcms.$get('/monimo/app/more/credit/fin_key/fnitByKeyw.json');
        commit('setFnitByKeywList', result.list);
      }
    },
    /**
     * 금융기관 아이콘 리스트 api call
     * @param {Object} state Vuex Store State
     * @param {Object} commit commit
     */
    async getFnitIconListApi({ state, commit }) {
      if (state.fnitIconList.length <= 0) {
        const result = await this.$wcms.$get('/monimo/app/more/credit/fin_key/fnitIconList.json');
        commit('setFnitIconList', result.list);
      }
    },
    /**
     * 공통 메시지 단건 조회.
     * @param {Object} context Vuex context
     * @param {Object} msgC 메시지 코드
     */
    async messageCodeInfo({ commit }, msgCode) {
      const responseData = (await msg(msgCode.msgC))?.result;
      const rspMsgC = {
        msgC: responseData?.msgC ? responseData.msgC : '',
        msgKrnCn: responseData?.msgKrnCn
          ? responseData.msgKrnCn.replace(/[\r\n]+/g, '<br/>')
          : '앗, 일시적인 문제가 생겼어요.<br/>잠시 후 다시 확인해 주시겠어요?',
      };

      commit('setMessageCodeInfo', rspMsgC);
    },
    /**
     * 공통 메시지 show & hide, content 정보.
     * @param {Object} context Vuex context
     * @param {Object} infos visible, id등 정보
     */
    async messageDialogShowHide(context, msgDialogInfos) {
      const infos = await msgDialogInfos;
      return await context.commit('setMessageDialogShowHide', infos);
    },
  },
};
