/**
 * Vue 디렉티브 등록
 *   - 전역(Global) 사용을 위해 본 파일에서 등록
 */
import Vue from 'vue';
import { gsap } from 'gsap';
import { getOffset, addComma, removeComma } from '@/utils';

Vue.directive('nl2br', {
  inserted(el) {
    el.innerHTML = el.textContent.replace(/\n/g, '<br />');
  },
});

let vueSclInEvtIdx = 0;
const vueSclInEvtAry = [];
Vue.directive('scroll-in', {
  inserted(el, binding) {
    vueSclInEvtAry.push(el);
    const rollingNumber = target => {
      const $wrap = target;
      let number = $wrap.innerText !== '' ? $wrap.innerText : $wrap.getAttribute('aria-label');
      if (number === null || number === undefined) number = '-';
      const $space = '<div>&nbsp;</div>';
      let innerHtml = '';

      if (number === '0') {
        innerHtml += '<div class="rolling-in" style="top:-100%;animation-delay:0ms;">';
        innerHtml += $space;
        innerHtml += '<div>' + number + '</div>';
        innerHtml += '</div>';
      } else {
        for (let i = 0; i < number.length; i++) {
          if (parseInt(number[i]) >= 0) {
            const total = Number('4' + number[i]);
            innerHtml +=
              '<div class="rolling-in is__number" style="transform: translateY(-' +
              (total + 1) * $wrap.clientHeight +
              'px);animation-delay:' +
              i * 50 +
              'ms;">';
            innerHtml += $space;
            for (let j = 0; j <= total; j++) {
              innerHtml += '<div>' + (j % 10) + '</div>';
            }
          } else {
            innerHtml += '<div class="rolling-in" style="top:-100%;animation-delay:' + i * 50 + 'ms;">';
            innerHtml += $space;
            innerHtml += '<div>' + number[i] + '</div>';
          }
          innerHtml += '</div>';
        }
      }
      $wrap.innerHTML = innerHtml;
    };

    const countNumber = (target, isInit = true) => {
      const $target = target;
      const $start = 0;
      const $end = removeComma($target.dataset.number);
      const $value = { val: $end };
      if (isInit) {
        gsap.from($value, {
          duration: 1,
          delay: 0,
          val: $start,
          roundProps: 'val',
          onUpdate() {
            $target.innerText = addComma($value.val);
          },
        });
      }
    };
    const inSclEvt = evt => {
      const $aryIdx = vueSclInEvtAry.indexOf(el);
      if ($aryIdx < 0) {
        window.removeEventListener('scroll', inSclEvt);
        window.removeEventListener('resize', inSclEvt);
        return;
      }
      const headHeight =
        document.querySelector('.v-app-bar.v-app-bar--fixed') !== null
          ? document.querySelector('.v-app-bar.v-app-bar--fixed').offsetHeight
          : 0;
      const footHeight =
        document.querySelector('.v-footer--fixed') !== null
          ? document.querySelector('.v-footer--fixed').offsetHeight
          : 0;
      const sclTop = window.pageYOffset + headHeight;
      const winHeight = window.innerHeight - headHeight - footHeight;
      const winBottom = sclTop + winHeight;
      const elTop = getOffset(el).top;
      const elHeight = el.offsetHeight;
      const elCenter = elTop + elHeight / 2;
      const elBottom = elTop + elHeight;

      let sclChkTop = elTop;
      let sclChkBottom = elBottom;
      if (elHeight > winHeight / 5) {
        sclChkTop = elCenter;
        sclChkBottom = elCenter;
      }
      if (sclTop < sclChkTop && sclChkBottom < winBottom) {
        vueSclInEvtIdx += 1;
        const timer = vueSclInEvtIdx * 100;
        vueSclInEvtAry.splice($aryIdx, 1);
        window.removeEventListener('scroll', inSclEvt);
        window.removeEventListener('resize', inSclEvt);
        setTimeout(() => {
          if (typeof binding.value === 'function') {
            binding.value(el, evt);
          } else if (typeof binding.value === 'string') {
            if (binding.value === 'counter-number') {
              countNumber(el);
            } else if (binding.value === 'rolling-number') {
              el.classList.add('is-active');
            } else {
              el.classList.add(binding.value);
            }
          }
          vueSclInEvtIdx -= 1;
        }, timer);
      }
    };
    window.addEventListener('scroll', inSclEvt);
    window.addEventListener('resize', inSclEvt);
    Vue.prototype.$nextTick(() => {
      if (typeof binding.value === 'string') {
        if (binding.value === 'rolling-number') {
          el.classList.add(binding.value);
          rollingNumber(el);
        }
        if (binding.value === 'counter-number') {
          el.classList.add(binding.value);
          const $text = el.innerText;
          el.dataset.number = $text;
          el.innerText = '0';
        }
      }
      window.dispatchEvent(new Event('scroll'));
    });
  },
  unbind(el) {
    const $aryIdx = vueSclInEvtAry.indexOf(el);
    if ($aryIdx >= 0) vueSclInEvtAry.splice($aryIdx, 1);
  },
});

Vue.directive('sticky', {
  unbind(el) {
    // todo remove
    // window.removeEventListener('resize');
    // window.removeEventListener('scroll');
  },
  inserted(el) {
    const win = window;
    const $sticky = el;
    const $stickyWrap = document.createElement('div');
    $sticky.insertAdjacentElement('beforebegin', $stickyWrap);
    $stickyWrap.classList.add('sticky-wrap');
    $stickyWrap.appendChild($sticky);

    const stickyPostion = $sticky.style.position;
    const stickyTop = $sticky.style.top;
    const stickyZindex = $sticky.style.zIndex;
    const stickyWidth = $sticky.style.width;
    const stickyHeight = $sticky.style.height;
    let stickyCWidth = $sticky.clientWidth;
    let stickyCHeight = $sticky.clientHeight;
    const stickyWrapWidth = $stickyWrap.style.width;
    let stickyWrapHeight = $stickyWrap.style.height;

    resetStyle();
    function resetStyle() {
      $stickyWrap.style.width = stickyWrapWidth;
      $stickyWrap.style.height = stickyWrapHeight;
      $sticky.style.position = stickyPostion;
      $sticky.style.top = stickyTop;
      $sticky.style.zIndex = stickyZindex;
      $sticky.style.width = stickyWidth;
      $sticky.style.height = stickyHeight;
      stickyCWidth = $sticky.clientWidth;
      stickyCHeight = $sticky.clientHeight;
      $stickyWrap.style.position = 'relative';
      $stickyWrap.style.height = stickyCHeight + 'px';
      $stickyWrap.style.width = stickyCWidth + 'px';
      stickyWrapHeight = $stickyWrap.getBoundingClientRect().top + win.pageYOffset;
    }
    win.addEventListener('scroll', () => {
      if (win.pageYOffset <= stickyWrapHeight) {
        $sticky.style.position = stickyPostion;
        $sticky.style.top = stickyTop;
        $sticky.style.zIndex = stickyZindex;
        $sticky.style.width = stickyWidth;
        $sticky.style.height = stickyHeight;
      } else {
        $sticky.style.position = 'fixed';
        $sticky.style.top = '0';
        $sticky.style.zIndex = '1';
        $sticky.style.width = stickyCWidth + 'px';
        $sticky.style.height = stickyCHeight + 'px';
      }
    });
    win.addEventListener('resize', () => {
      resetStyle();
      const triggerScrollEvent = document.createEvent('event');
      triggerScrollEvent.initEvent('scroll', true, false);
      win.dispatchEvent(triggerScrollEvent);
    });
  },
});
