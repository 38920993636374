/**
 * 모니모 브릿지 인터페이스 정의
 */
import _ from 'lodash';
import MonimoBridgeCore from '@/plugins/bridge/monimo-bridge-core';
import URLParser, { isHppUrl, isSlfHmpgUrl, isSlfDirctUrl, isSsfHmpgUrl, isSsfDirctUrl } from '@/utils/url-parser';
import CommonUtil, { setCacheData } from '@/utils/common-util';

// monimo.bridge 네임스페이스 생성
CommonUtil.createNamespace('monimo.bridge');
// AOS 디바이스 백 관련 함수 정의
window.monimo.bridge.back = MonimoBridgeCore.back;

export default {
  /**
   * 브릿지 호출 함수
   *
   * @param {String} apiCommand 하단 브릿지 커맨드
   * @param {Object} params 브릿지 요청 파라미터
   * @param {Function} callbackFunc 브릿지 콜백 함수
   * @param {Boolean} preventDoubleClick 브릿지 중복 호출 방지 Flag
   * @returns {Object} 브릿지 리턴 데이터
   */
  async callBridge(apiCommand, params, callbackFunc, preventDoubleClick = false) {
    // 전처리 로직이 있을때 해당 함수를 실행한다.
    if (this[apiCommand]) {
      const rtParams = this[apiCommand](params, callbackFunc, preventDoubleClick);
      return await MonimoBridgeCore.exec(
        apiCommand,
        rtParams.params,
        rtParams.callbackFunc,
        rtParams.preventDoubleClick,
      );
    }
    return await MonimoBridgeCore.exec(apiCommand, params, callbackFunc, preventDoubleClick);
  },

  /**
   *  브릿지 커맨드 정리 (브릿지 추가 시 채번 번호 순서대로 작성 해주세요)
   *  param에 '[⭐️]' 표시 : 필수값입니다. 파라미터에 반드시 추가 필요
   *  📌 params는 Input값 없어도 빈값({})은 필수로 보내야 함
   */
  /**
   * 로그인
   *  로그인 사용자 정보를 가져온다.
   * params: {}, // Input값 없어도 빈값 필수
   * return : {
   *   resultCode(string): '', // '00":성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   mnmCstMngtNo(string): 모니모고객관리번호
   *   cstNm(string): 고객명
   *   cstMngtNo(number): 고객관리번호
   * }
   */
  GET_LOGIN_USER_INFO: 1000, // 로그인 사용자 정보 조회
  /**
   * 삼성카드 세션데이터 조회
   *  삼성카드 세션 및 중복로그인방지 값을 조회한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   scssn(string): 삼성카드 세션ID값
   *   encst(string): 삼성카드 중복로그인방지 문자열
   * }
   */
  GET_SCSSN_DATA: 1001, // 삼성카드 세션데이터 조회
  /**
   * 세션 장애 발생 시 호출
   *  웹 세션 오류 발생 시 앗 팝업 대신 네이티브 호출
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  FAIL_SESSION: 1002, // 웹세션 오류시 (앗 팝업 대신 호출)
  /**
   * 본인인증
   *  KCB휴대폰인증 or 본인확인(휴대폰,카드) 인증 프로세스를 진행한다.
   *  - 인증프로세스가 새로운 Native 화면으로 진행
   *  - 호출횟수 제한 있음 (5회/일)
   *
   * params : {
   *   [⭐️]type(string): 인증타입 (sms: KCB휴대폰인증 / hsVd: 본인확인 인증(휴대폰, 카드)
   *   mnmAflcmpC(string): 모니모관계사코드(메타)
   *   mnmCstSvDvC(string): 모니모고객서비스구분코드(메타)
   *   mnmCstSvDtlPgmNm(string): 모니모고객서비스상세프로그램명
   *   srnId(string): 화면ID
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   mnmCtfSn(string):모니모인증일련번호
   *   // 실패 시 (Android: -1(int) 콜백, iOS: 콜백없음)
   * }
   */
  AUTH: 1100, // 본인인증
  /**
   * 계좌인증
   *  오픈뱅킹 가입 시 계좌1원인증을 하는 Native 화면으로 이동하여 인증을 진행합니다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  AUTH_ACCOUNT: 1101, // 계좌인증
  /**
   * FIDO 인증
   *  - FIDO 인증을 통한 본인확인을 진행한다. (FIDO 인증이 바텀시트로 올라옴)
   *
   * params : {
   *   mnmAflcmpC(string):모니모관계사코드(메타)
   *   mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *   mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *   srnId(string):화면ID
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   mnmCtfSn: 모니모인증일련번호
   * }
   */
  AUTH_FIDO: 1102, // FIDO인증
  /**
   * 전자서명
   *  FIDO인증 후 전자서명을 수행한다.
   *  - FIDO인증이 바텀시트로 올라옴.
   *  - 모니모인증서등급구분코드가 02(중요거래)로 들어왔는데사용자가 01(일반거래)로 인증서 보유시, 전자서명등륵상향필요안내 화면노출
   *
   * params : {
   *   [⭐️]mnmCertsGdDvC:모니모인증서등급구분코드(메타)
   *   [⭐️]elsgOtxtCn(string): 전자서명원문 (JSONString)
   *   mnmAflcmpC(string):모니모관계사코드(메타)
   *   mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *   mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *   srnId(string):화면ID
   * }
   * return : {
   *  resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *  mnmElsgSn(string):모니모전자서명일련번호(전자서명검증 후 리턴되는 값)
   * }
   */
  SIGN_DIGITAL: 1103, // 전자서명 (모니모)
  /**
   * 전자서명 (관계사용)
   *  FIDO인증 후 전자서명을 수행한다.
   *  - FIDO인증이 바텀시트로 올라옴.
   *  - 전자서명검증 제외
   *
   * params : {
   *   [⭐️]mnmCertsGdDvC: 모니모인증서등급구분코드(메타)
   *   [⭐️]elsgOtxtCn(string): 전자서명원문 (JSONString)
   *   mnmAflcmpC(string):모니모관계사코드(메타)
   *   mnmCstSvDvC(string):모니모고객서비스구분코드(메타)
   *   mnmCstSvDtlPgmNm(string):모니모고객서비스상세프로그램명
   *   srnId(string):화면ID
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   elsgOtxtCne(string):전자서명원문내용암호화
   * }
   */
  SIGN_DIGITAL_AFL: 1104, // 전자서명 (관계사)
  /**
   * 전자서명(관계사축약)
   *  전자서명을 수행한다.
   *   - FIDO인증 SKIP
   *   - 전자서명검증 SKIP
   *   - ONLY 전자서명만 생성 후 리턴
   *   - 중요거래전자서명인데 일반거래인증서보유시 등급상향으로 이동 프로세스는 유지
   *
   * params : {
   *   [⭐️]mnmCertsGdDvC:모니모인증서등급구분코드(메타)
   *   [⭐️]elsgOtxtCn(string):전자서명원문 (JSONString)
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   elsgOtxtCne(string):전자서명원문내용암호화
   *   pubKey: 모니모인증서 공개키(Base64 encoding)
   * }
   */
  SIGN_DIGITAL_AFL_COMPACT: 1105, // 전자서명 (관계사 축약)
  /**
   * 마이데이터 전자서명 요청
   *  인통합인증 002를 태우기 위해 필요한 전자서명 데이터
   *
   * 파라미터 및 상세스펙은 브릿지목록 컨플루언스 확인
   * URL: https://confluence.samsungcard.biz/display/IPFMDEVOPS/Bridge+List
   * 위치: 63행 mydataCertSign
   *
   * params : {
   *  [⭐️]userAgreement:개인정보제공 및 활용동의 약관
   *  [⭐️]realName(string):실명 공개여부 ('Y'|'N')
   *  [⭐️]gender(string): 성별 공개여부 ('Y'|'N')
   *  [⭐️]national(string): 국적 공개여부 ('Y'|'N')
   *  [⭐️]birth(string): 생년월일 공개여부 ('Y'|'N')
   *  [⭐️]ci(string): 주민번호 공개여부 ('Y'|'N')
   *  [⭐️]url(string): scheme정의부와 uri정의부를 제외한 ISP의 url
   *  [⭐️]signReqInfo(JSON Array) : [{
   *    orgCode: 기관코드
   *    ucpidNonce: 비표(Nonce)값으로 사용할 난수(UCPID)
   *    consentInfo: 전송요구내역 (url encoded json string)
   *  }]
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   signDataList(JSON Array): [{
   *     orgCode: 기관코드
   *     ucpidSignData: ucpid 전자서명 데이터
   *     consentSignData: Consent 전자서명 데이터
   *   }]
   *   caOrg: 인증서발급기관(yessign, SignKorea, etc)
   * }
   */
  SIGN_DIGITAL_MYDATA: 1106, // 전자서명 (마이데이터)
  /**
   * [오픈뱅킹]중요거래인증서발급
   *  중요거래 인증서 발급 진행한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *  resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  ISSUE_IMP_MONIMO_CERT: 1107, // 모니모 중요거래 인증서 발급 (오픈뱅킹)
  /**
   * 공동인증서 존재 여부 확인
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   isCert: 공동인증서유무 ('Y'|'N')
   * }
   */
  IS_PUBLIC_CERT: 1108, // 공동인증서 여부
  /**
   * 보안키패드 호출
   *  Nfilter 숫자보안키패드를 호출한다.
   *  각 업무 서비스에서 NFilter(NfilterService)를 활용하여 복호화처리할 것
   *  number
   *  - 입력시 마다 콜백
   *  - (모니모 입력 완료 값 전송, length가 숫자로 되어짐)
   * string
   * - 키패드 입력 후 확인 버튼 클릭 시 콜백
   *
   * params : {
   *   [⭐️]type(string):키패드구분Type('number'|'string')
   *   [⭐️]length(number):입력받을 길이
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   length(number):현재까지 입력된 길이
   *   pswde(string):암호화된 문자열
   * }
   */
  SHOW_SECU_KEYPAD: 1200, // 보안키패드 호출
  /**
   * 보안키패드 숨기기
   *  보안키패드를 숨긴다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  HIDE_SECU_KEYPAD: 1201, // 보안키패드 숨기기
  /**
   * FDS수집/전송
   *  FDS수집대상화면별 FDS수집정보를 전송한다.
   *
   *  1. FDS솔루션을 통한 기기정보수접
   *  2. 브릿지 파라미터로 넘어온 Data 와 FDS 로 수집한 데이터들
   *  3. /restapi/fds/insFds 호출
   *
   * params: {
   *   svId(string):서비스ID
   *   cdno(string):카드번호
   *   cdnoId(number):카드번호ID
   *   cstMngtNo: 고객관리번호
   *   dsbAm(number): 금액
   *   stlmMthC(string): 결제방법코드
   *   rrno(string): 주민등록번호
   *   fdsIdno: FDS데이터-natIp
   *   fdsDta: FDS고유번호-wData
   *   scrId(string): 화면ID(카드)
   *   clct
   *   afWdata
   * }
   *
   * 참고) 서비스ID 정보
   * .오픈뱅킹계좌/카드등록(오픈뱅킹등록완료화면진입) : 3600000502
   * .오픈뱅킹송금(인증호출전):3600000503 (금액 포함)
   * .카드번호조회(인증호출전):3600000504
   * .카드마이탭조회(화면진입시):3600000601
   * .모니모로그인(로그인 후):3700000601 (최종로그인후)
   * .모니머니충전(인증호출전):3700000303 (금액 포함)
   *
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   isComplete(string): '',  // 완료여부('Y'|'N')
   * }
   */
  SEND_FDS_DATA: 1202, // FDS수집/전송
  /**
   * 건강 등급 산출을 위한 간편 인증 요청
   *  - GHC 를 통해 간편인증을 요청한다.
   *  - 선택 된 인증기관 앱에서 알림 요청
   *  - 인증 완료 후 모니모 앱으로 복귀(수동)
   *
   * params: {
   *   authItem(Int):본인인증 기관 선택(KAKAO:1, NAVER:3)
   *   gender(Int):성별(남성:1, 여성:2)
   *   smokingType(Int):흡연 기록(비흡연:1, 과거흡연:2, 흡연:3)
   *   medicalTreatment(Int): 3개월내 의료행위 여부(예:1, 아니요:2)
   *   birthDay(String):고객 생년월일(YYYYMMDD)
   *   userName(String):고객이름
   *   phoneNumber(String):고객 휴대전화번호
   *   stepCountList(Array):걸음수리스트 : [{
   *     step_date(String): 날짜(YYYY-MM-DD)
   *     step_count(Number): 걸음 수
   *   }]
   *   scoreHistoryList(Array):월별점수리스트 : [{
   *     month(Number): 달
   *     score(Number): 점수
   *   }]
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   statusCode(String): 상태코드(에러코드 포함)
   * }
   */
  AUTH_SIMPLE_GHC: 1203, // 건강등급산출 간편인증요청 (마이데이터)
  /**
   * 간편 인증 확인 및 건강 등급 산출
   *  - 간편 인증 결과를 확인하고 건강 등급 산출 결과를 얻는다.
   *  - reqSimpleAuth 로 인증 요청, 인증 처리가 완료된 후 호출되어야 함
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   statusCode: 상태코드 (에러코드 포함)
   *   healthGrade: 건강등급 산출결과 (JSON)
   * }
   */
  GET_GHC_HEALTH_GRADE: 1204, // 간편인증확인 및 건강등급산출 (마이데이터)
  /**
   * 카카오톡 공유
   *  카카오톡을 실행시켜 특정경로/이미지등을 공유한다.
   *  파라미터 중 모든 URL 은 전체 경로를 전달하도록 한다.
   *  예) imageUrl: "http://domain.com/path?image=xxx"
   *
   * params : {
   *   1. feed
   *   [⭐️] type(string):"feed"
   *   [⭐️] content(content):메시지의 메인 콘텐츠 정보
   *   buttons(button array):버튼 목록(최대 2개), 버튼 타이틀, 링크 변경 or 버튼 두개 넣을 때
   *   2. text
   *   [⭐️] type(string):"text"
   *   [⭐️] text(string):텍스트 정보, 최대 200자
   *   link(link):콘텐츠 클릭 시 이동할 링크 정보
   * }
   *
   * 공통 사용 객체
   * content : {
   *   [⭐️] title(string):콘텐츠의 타이틀
   *   [⭐️] imageUrl(string):콘텐츠의 이미지 URL
   *   [⭐️] link(link):콘텐츠 클릭 시 이동할 링크 정보
   * }
   *
   * button : {
   *   title(string):버튼의 타이틀
   *   link(link):버튼클릭 시 이동할 링크 정보
   * }
   *
   * link(Object) : { // 파라미터 1개 이상 필수
   *   isOpenWeb: Web/App으로 열기 ('N':App으로 열기-default / 'Y':Web으로 열기)
   *   webUrl: PC버전 카카오톡에서 사용하는 웹 링크 URL
   *   mobileWebUrl: 모바일 카카오톡에서 사용하는 웹 링크 URL
   *   참고) URL의 도메인 부분은 [내 애플리케이션] > [플랫폼] > [Web]에서 등록한 사이트 도메인과 일치해야 함
   * }
   */
  SHARE_KAKAO: 1300, // 카카오톡 공유하기
  /**
   * OS 공유
   *  OS자체 공유기능을 활성화한다.
   *
   * data 형식
   * 아래 형식 중 하나의 배열 형태 (종류가 동일 해야 함)
   * 1.Comm' String
   *  - URL, Text
   * 2.Base64 String
   *  - "data:" 로 시작
   *  - 지원 : Image(jpg, png), PDF
   * ps. 첫 아이템의 종류에 따라 이후 다른 타입 아이템은 무시됨 (EX: Array 내 첫 번째 아이템 MIME 타입이 pdf인 경우 이후 아이템은 pdf 타입만 처리)
   *
   * params : {
   *   [⭐️]data(string Array):공유할 문자열
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   isShared(string): 공유 여부 ('Y'|'N')
   * }
   */
  SHARE_OS: 1301, // OS 공유하기
  /**
   * 클립보드 저장
   *  특정문자열을 클립보드에 저장한다.
   *
   * params : {
   *   [⭐️]type : 문자타입 ('text': 일반 텍스트 타입(Default), 'url': Url타입)
   *   [⭐️]contents: 복사할 내용
   *   msg(string) : 저장 완료시 보여줄 메세지
   * }
   */
  SET_CLIPBOARD: 1302, // 클립보드 저장하기
  /**
   * 클립보드 데이터 가져오기
   *  클립보드에 저장된 문자열을 꺼낸다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   data(string): 클립보드 내용
   * }
   */

  GET_CLIPBOARD: 1303, // 클립보드 가져오기
  /**
   * 전화앱 오픈
   *  단말 전화앱을 실행한다.
   *
   * params : {
   *   [⭐️]tel(string): 'tel:01012341234', // 앞에 tel 꼭 붙여야 함!
   * }
   */
  OPEN_CALL: 1304, // 전화앱 열기
  /**
   * 지도앱 오픈
   *  단말 지도앱을 실행한다.
   *
   * params : {
   *   latitude(string):위도
   *   longitude(string):경도
   *   address(string):주소
   * }
   * // 조건부 파라미터 옵션 : latitude, longitude값 or address값은 꼭 있어야 함
   */
  OPEN_MAP: 1305, // 지도앱 열기
  /**
   * 삼성카드통합앱오픈
   *  삼성카드통합앱(앱카드앱)을 실행한다.
   *
   * 브릿지가 호출되면 아래 Url 로 앱설치 유무 판단
   *  AOS: kr.co.samsungcard.mpocket
   *  IOS: com.samsungcard.mpocket
   *
   * 앱설치 X: 스토어 이동
   * 앱설치 O: monimo.appcard.samsungcard.com://{action}?{queryString} 실행
   *
   * params: {
   *   action(string):앱카드앱이 처리할 동작 정의(regCard:앱카드 등록)
   *   queryString(string):스키마 호출 시, 파라미터(ex. key=value1&key2=value2)
   * }
   */
  OPEN_APPCARD: 1306, // 삼성카드 앱 열기
  /**
   * 앱설치여부 확인
   *  인증전 앱설치 여부 확인
   *
   * params: {
   *   [⭐️]appId(string): 인증앱명 (kakao, naver)
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   isCheck(string): 앱설치 여부 ('Y'|'N')
   * }
   */
  CHECK_APP_INSTALL: 1307, // 앱설치 여부 확인 (마이데이터)
  /**
   * 디바이스 스토리지 사용
   *  디바이스 스토리지 저장 / 조회
   *  - 앱 재설치시,삭제됨
   *  - type 별로 param이 다름 (type: SAVE, LOAD, DEL)
   *
   * 1. SAVE (저장)
   * params : {
   *   [⭐️]type(string):'SAVE', // type
   *   [⭐️]key(string):저장데이터 KEY
   *   [⭐️]value(string):저장데이터 VALUE (JSON도 string으로)
   * }
   * 2. LOAD(조회)
   * params : {
   *   [⭐️]type(string):'LOAD', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * 3. DEL(삭제)
   * params : {
   *   [⭐️]type(string):'DEL', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   // type = LOAD 일때만
   *   key(string): 저장
   *   value(string): 저장값
   * }
   * 참고)) LOAD/SAVE/DEL →  성공 return 고정
   *       LOAD할 key가 없으면 value=""으로 성공 return
   */
  ACCESS_LOCAL_STORAGE: 1308, // 디바이스 스토리지 저장/조회
  /**
   * 암호화 디바이스 스토리지 사용
   * - '개인신용정보' 유사 데이터(ex. 마이데이터 초화면 계좌 금액 등)를 암호화(AES256) 하여 네이티브 로컬스토리지 영역에 저장 및 조회하여 페이지 로딩 속도 단축을 위해 사용합니다.
   * - dueDate(저장기간, 기본 30일)를 option으로 별도 지정 가능해야합니다.
   * - 사용자 고유식별값을 salt로 암호화키 활용하여 사용자 검증
   *   fail인경우 -> return dataset:{}
   * - 데이터 로딩시 저장된 데이터가 30일이 지난 데이터인경우 초기화
   *   return dataset:{}
   *  - type 별로 param이 다름 (type: SAVE, LOAD, DEL)
   *
   * 1. SAVE (저장)
   * params : {
   *   [⭐️]type(string):'SAVE', // type
   *   [⭐️]key(string):저장데이터 KEY
   *   [⭐️]value(string):저장데이터 VALUE (JSON도 string으로)
   *   dueDate(Number): 저장기간(Day) (Default: 30)
   * }
   * 2. LOAD(조회)
   * params : {
   *   [⭐️]type(string):'LOAD', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * 3. DEL(삭제)
   * params : {
   *   [⭐️]type(string):'DEL', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   // type = LOAD 일때만
   *   key(string): 저장
   *   value(string): 저장값
   * }
   * 참고)) LOAD/SAVE/DEL →  성공 return 고정
   *       LOAD할 key가 없으면 value=""으로 성공 return
   */
  ACCESS_ENC_LOCAL_STORAGE: 1309, // 암호화 디바이스 스토리지 저장/조회
  /**
   * Cache 사용
   *  데이터를 암호화 하여 Cache에 저장한다.
   *  - type별로 param 다름 주의
   *
   * 1. SAVE (저장)
   * params : {
   *   [⭐️]type(string):'SAVE', // type
   *   [⭐️]key(string):저장데이터 KEY
   *   [⭐️]value(string):저장데이터 VALUE (JSON도 string으로)
   * }
   * 2. LOAD(조회)
   * params : {
   *   [⭐️]type(string):'LOAD', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * 3. DEL(삭제)
   * params : {
   *   [⭐️]type(string):'DEL', // type
   *   [⭐️]key(string):저장데이터 KEY
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   // type = LOAD 일때만
   *   key(string): 저장
   *   value(string): 저장값
   * }
   * 참고)) LOAD/SAVE/DEL →  성공 return 고정
   *       LOAD할 key가 없으면 value=""으로 성공 return
   */
  ACCESS_CACHE_STORAGE: 1310, // cache storage 저장, 조회
  /**
   * 연락처 리스트 조회
   *  연락처 송금 시 연락처 동의여부, 연락처 리스트를 가져온다
   *  - 탭을 눌렀는지 버튼을 눌러서 연락처 리스트에 접근하는지에 따라 다르게 동작함
   *
   * params: {
   *   from(string): 탭 or 페이지 내부 버튼을 눌렀을때를 분기 처리하기 위한 파라미터 ('tab' | 'button', default: 'button');
   * }
   *
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   resultMsg(string): native 오류메시지가 있을때 리턴
   *   contactList(array): 연락처 리스트 Json Array [{ name: 이름, contact: 폰번호 }]
   * }
   */
  GET_CONTACT_LIST: 1311, // 연락처 리스트 조회
  /**
   * 파일다운로드
   *  파일다운로드를 처리한다.
   *
   *  파일명 필요 시
   *  브릿지 INPUT filename 참조
   *  브릿지 INPUT에 filename이 없으면 header참조
   *
   *  response header [Content-Disposition]의 filename을 parsing해서urldecoding
   *  ex) attachment;
   *      filename=%EC%9D%B4%EC%9B%90%EC%9A%B0_PC%EB%B3%B4%EC%95%88%EC%A4%80%EC%88%98%EC%84%9C%EC%95%BD%EC%84%9C.pdf"
   *
   * params: {
   *   [⭐️]url(string):파일다운로드 URL
   *   filename(string):파일명
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  DOWNLOAD_FILE: 1312, // 파일 다운로드
  /**
   * 이미지 다운로드
   * base64string으로 전달된 이미지를 기기 앨범에 저장한다.
   * params: {
   *   [⭐️]data(String):base64string
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  DOWNLOAD_IMAGE: 1313, // 이미지 다운로드
  /**
   * 날짜 선택(년,월,일)
   *  년, 월,일을 선택할 수 있는 Native picker 호출
   * - type: 'YYYYMMDD'(년월일), 'YYYYMM'(년월))
   *
   * params: {
   *   [⭐️]type: picker type ('YYYYMMDD' | 'YYYYMM)
   *   title(string):제목
   *   [⭐️]default(string):초기값(type 형태로 YYYYMMDD or YYYYMM)
   *   [⭐️]minDate(string):최소선택날짜(YYYYMMDD or YYYYMM)
   *   [⭐️]maxDate(string):최대선택날짜(YYYYMMDD or YYYYMM)
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   date(string):선택날짜,
   * }
   */
  OPEN_DATE_PICKER: 1400, // 날짜 선택
  /**
   * 주기 선택
   *  주기를 선택할 수 있는 Native 화면 호출
   *
   * params : {
   *   title(string):제목
   *
   *   // 초기노출유형
   *   [⭐️]type(string):주기선택유형(M(월), W(주), O(1회))
   *
   *   // 반복 탭 화면 기본값
   *   [⭐️]monthDefault(string):월 선택 초기값(1~31)
   *   [⭐️]weekDefault(string):주 선택 초기값(0(일)~6(토))
   *
   *   // 1회 탭 화면 기본값
   *   [⭐️]onceDefault(string):초기값(YYYYMMDD)
   *   [⭐️]minDate(string):최소선택날짜(YYYYMMDD)
   *   [⭐️]maxDate(string):최대선택날짜(YYYYMMDD)
   * }
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   periodType(string):주기선택유형(M(월), W(주), O(1회))
   *   value(string):선택주기(M:1~31,W:0(일)~6(토),O:YYYYMMDD)
   * }
   *
   * ex)
   * 1. 반복 탭의 화면
   *  매달15일 -> data:{"periodType":"M", "value":"15"}
   *  매주수요일 -> data:{"periodType":"W", "value":"3"}
   *
   * 2. 1회 탭의 화면
   *  2021년 9월 11일 -> data:{"periodType":"O", "value":"20210911"}
   */
  OPEN_PERIOD_PICKER: 1401, // 주기 선택 피커 호출
  /**
   * 시간선택(오전/오후, 시, 분)
   *  - 오전/오후, 시, 분을 선택할 수 있는 Native 화면 호출
   *  - Input 초기값과 output 선택시간 포맷은 웹에서 사용되는 일반적인 포맷을 따름
   *
   * params: {
   *   title(String):제목
   *   [⭐️]default (String):초기값(‘H:mm a’ or ‘H:mm p’)
   *   minutesInterval(Number):분 간격(1,5,10 - default: 10)
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   time(String):선택시간,(‘HH:mm a’ or ‘HH:mm p’)
   * }
   */
  OPEN_TIME_PICKER: 1402, // 시간선택 (마이데이터)
  /**
   * URL 오픈 (새 브라우저)
   *  특정 URL을 새로운 브라우저에서 Open
   *  - 모니모 관계사 코드에 따른 쿠키(세션) 제어 포함
   *  ✅ ※  headerYn이 'N'인데 optionYn이 'Y'일 수 는 없음
   *     ※ headerYn이 'Y' && 카/생/화 도메인이면 optionYn ='Y' (웹에서 처리)
   *
   * params: {
   *   [⭐️]url(string): '', // 이동할URL(프로토콜+도메인포함 FULL URL) (필수값)
   *   mnmAflcmpC(string): '', // 모니모관계사코드 (메타)
   *   headerYn(string): '', // 헤더포함여부 ('Y' | 'N', default: 'N')
   *   headerTitle(string): '', // 헤더타이틀명(headerYn: 'Y' && 관계사 화면이 아닐 때 해당 값을 웹뷰 헤더에 넣어줌)
   *   movingStepYn(string): '', // 무빙스탭 노출여부 ('Y' | 'N', default: 'N')
   *   backYn(string): '', // 헤더 뒤로가기 버튼(<) 여부 (default : 'Y')
   *   selfClose(string): '', // 이동 전 현재 웹페이지 닫힘여부 (default: 'N')
   *   optionYn(string): '', // (iOS Only) 하단툴바 옵션 활성화 여부 (default: 'N'), backYn='N'일 때만 하단 툴바 보이고, option값 확인
   *   requestFocus(string): '', // (AOS Only) 키패드 노출 여부 (default: 'N')
   *   aflShutdownYn(string): '', // 화면차단정책 체크 여부 (default: 'N')
   *   aflLoginYn(string): '', // 관계사 로그인 체크 여부 (default: 'N')
   * },
   */
  OPEN_WEBVIEW: 1403, // 새로운 웹뷰 열기
  1403: (params, callbackFunc, preventDoubleClick = true) => {
    // TODO: openNewBrowserForAI 처리
    const reqParams = {
      mnmAflcmpC: '',
      headerYn: 'Y',
      headerTitle: '',
      optionYn: 'N',
      movingStepYn: 'N',
      backYn: 'N',
      iosToolbarYn: 'Y',
      selfClose: 'N',
      ...params,
    };
    // 초기 파라미터 추가
    const urlParser = new URLParser(reqParams.url);
    urlParser.addParam('firstYn', 'Y').addParam('useAppTitle', 'Y');
    // footer 노출이 필요한 화면들
    const footerShowSrns = ['UHPPFS0101M0.jsp'];
    const footerShow = footerShowSrns?.some(srnId => {
      return reqParams.url.includes(srnId);
    });
    if (!footerShow) {
      urlParser.addParam('footerShowYn', 'N');
    }
    // 헤더표시 Y && 카드 or 생명 or 화재 도메인인 경우
    if (reqParams.headerYn === 'Y') {
      if (
        (isHppUrl(reqParams.url) ||
          isSlfHmpgUrl(reqParams.url) ||
          isSlfDirctUrl(reqParams.url) ||
          isSsfHmpgUrl(reqParams.url) ||
          isSsfDirctUrl(reqParams.url)) &&
        reqParams.backYn === 'N'
      ) {
        reqParams.optionYn = 'Y';
      }
    } else {
      // 헤더표시 N 인 경우, optionYn N 처리
      reqParams.optionYn = 'N';
    }

    // 22.07.08 디채 대응 - 삼성카드(HPP)인 경우, 관계사코드 값 제거
    // 관계사코드 설정 시, AOS 기준 비로그인처리되는 이슈가 있어서 임의로 제거
    if (CommonUtil.getAppInfo('mblOsDvC') === '01' && reqParams.mnmAflcmpC === 'HPP') {
      reqParams.mnmAflcmpC = '';
    }

    return {
      params: {
        ...reqParams,
        url: urlParser.toString(),
      },
      callbackFunc,
      preventDoubleClick,
    };
  },
  /**
   * 웹뷰 닫기
   *  현재브라우저를 닫는다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  CLOSE_WEBVIEW: 1404, // 현재 웹뷰 닫기
  /**
   * URL 오픈(아웃링크)
   *  특정 URL을 아웃링크로 OPEN
   *
   * params : {
   *   [⭐️]url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   * }
   */
  OPEN_OUTLINK: 1405, // 아웃링크 열기
  1405: (params, callbackFunc, preventDoubleClick = true) => {
    return {
      params: { ...params },
      callbackFunc,
      preventDoubleClick,
    };
  },
  /**
   * 앱 화면이동
   *  특정 앱화면으로 이동
   *
   * params : {
   *   [⭐️]target(String):이동할 화면 이름
   *   url(String):이동할URL(프로토콜+도메인포함 FULL URL)
   *   selfClose(Boolean): 이동 전 현재 웹페이지 닫힘여부(default: N)
   *
   * target 종류
   * - main    : 메인
   * - notice  : 알림함
   * - setting : 설정
   * - profile : 프로필
   * - asset   : 내자산
   * - tutorial: 튜토리얼
   * - more    : GNB > 더보기
   * - today   : GNB > 투데이
   * - benefit : GNB > 혜택
   * - product:  GNB > 상품
   * - card    : GNB > 마이 > 카드
   * - life    : GNB > 마이 > 생명
   * - fire    : GNB > 마이 > 화재
   * - stock   : GNB > 마이 > 증권
   * - nice    : 신용조회
   * - health  : 걷기
   * - juniorToday: 주니어 투데이
   * - juniorMoney: 주니어 머니
   * - juniorPlay : 주니어 플레이
   * - juniorAll  : 주니어 전체
   * - monomopay  : 모니모 페이
   */
  OPEN_NATIVE: 1406, // Native 이동
  1406: (params, callbackFunc) => {
    const reqParams = {
      selfClose: 'N',
      ...params,
    };

    if (!_.isEmpty(params.moveParam)) {
      const expireIntervalTime = 10000; // unit: ms
      const moveParam = {
        ...params.moveParam,
        target: params.target,
        expireTime: Date.now() + expireIntervalTime,
      };

      setCacheData('OPENNATIVE_MOVE_PARAM', moveParam, true, true);
    }
    return {
      params: {
        ...reqParams,
      },
      callbackFunc,
    };
  },
  /**
   * 화면이동
   *  모니모 화면 이동 또는 url 출력을 처리한다.
   *
   * params: {
   *   [⭐️]code(String): 이동코드
   *   isDirect(Boolean): 화면 직접 호출 여부 (default: N)
   * }
   */
  OPEN_SERVICE: 1407, // 화면이동
  /**
   * 미션팝업 오픈
   *  미션달성 화면으로 이동할 수 있는 팝업화면 OPEN
   *
   * params : {
   *   [⭐️]url(string):이동할URL(프로토콜 + 도메인포함 FULL URL)
   *   title(string): 제목
   *   curtainType(string): 커튼콜 타입 (default: 'jelly', 'mission)
   *   buttonTitle(string): 버튼 타이틀
   * }
   */
  OPEN_MISSION_POPUP: 1408, // 미션 팝업 열기
  1408: ({ url, title = '' }, callbackFunc) => {
    return {
      params: { url: new URLParser(url).addParam('firstYn', 'Y').toString(), title },
      callbackFunc,
    };
  },
  /**
   * 1Account로그인처리
   *  (카드한정)1Account로그인처리 후 ssotoken등 관련 쿠키를 관리한다.
   *   - 1Account로그인처리 암호화데이터 수신 후 처리
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  LOGIN_AFL: 1500, // 1Account 로그인처리
  /**
   *  관계사 계약/채널회원여부 재조회
   *   모니모 웹화면(혜택레벨 안내)에서 호출 시, 관계사* 코드값을 Native에 전달하여 해당 관계사의 계약/채널회원여부를 갱신 요청한다.
   *   웹화면 진입 시점에 계약/채널회원여부가 Y/Y가 아닌 관계사만 해당
   *   (Native는 요청받은 관계사에  대해 계약/채널회원여부조회 및 관계사회원여부수정(updAflCmpMbYn) 수행 후 성공/실패여부를 return)
   *
   * params: {
   *   [⭐️]mnmAflcmpCList(Json array): 모니모 관계사 코드 목록[카드: HPP, 생명: SLF, 화재: SSF, 증권: SSS] 카드, 생명, 화재, 증권 순서에 맞춰서 전달
   *                   예) mnmAflcmpCList: [HPP, SSF, SSS] 카드 → 화재 → 증권 순서로 전달
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  REFRESH_AFL_MEMBER_STATE: 1501, // 관계사 계약/채널회원여부 재조회
  /**
   * 관계사채널/계약회원여부 업데이트
   *  관계사채널/계약회원여부가 갱신됐을때 앱으로 알려서 모니모DB가 최신화되게 처리한다.
   *
   * params: {
   *   [⭐️]chnlMbYn(string):관계사채널고객여부, ('Y'|'N')
   *   [⭐️]cntrMbYn(string):관계사계약고객여부, ('Y'|'N')
   *   [⭐️]mnmAflcmpC(string):모니모관계사코드,
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  UPDATE_AFL_MEMBER_STATE: 1600, // 관계사 채널/계약회원여부 업데이트
  /**
   * 모니모 관계사 재로그인 요청
   *   모니모 및 관계사 세션이 끊어진 경우 재로그인을 요청한다.
   *   1. 안내메시지 표시
   *   2. 해당 관계사 재로그인 처리
   *   - 모니모 재로그인과는 별개
   *
   * params: {
   *   [⭐️]mnmAflcmpC(string):모니모 관계사코드(PFM 포함),
   *   msgCn(string):메시지 내용
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  LOGIN_AFL_WITH_MSG: 1601, // 모니모 관계사 재로그인 요청
  /**
   * 관계사별 고객식별번호 암호화 값을 조회
   *  관계사별 고객식별번호 암호화 값을 조회한다.
   *
   * params: {
   *   [⭐️]mnmAflcmpC: 모니모 관계사 (SSL, SLF, SSS)
   * }
   * return: {
   *  resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *  cstDrmNo(String): 고객식별번호 암호화 값
   * }
   */
  GET_CST_DRM_NO: 1602, // 관계사별 고객식별번호 암호화값 조회
  /**
   * 단말기정보수집
   * 주식거래 주문 시, 단말정보(핸드폰번호, IP, MAC) 수집을 위해 호출한다.
   * ※단말정보 수집
   * 1. 전화번호
   * - AOS : 1순위 전화번호, 2순위 I + IP주소
   * - iOS : I + IP주소
   * 2. IP 주소
   *      브릿지 함수 호출 시점에 단말기 IP 리턴
   * 3. MAC 주소
   * - AOS : "A"+타입(P or I) + CRC32알고리즘조합값(단말정보)
   *  * 단말정보 : ①전화번호, ②구글광고ID, ③안드로이드ID
   *              (① →②→③순위로 구해서 있는 값으로 적용)
   *  * 타입 : P(전화번호), I(구글광고ID 또는 안드로이드ID)
   * - iOS : 공백
   * 4. 단말 ID 추가
   * - Android ID 또는 iOS UDID
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   phoneNo(String):핸드폰번호,  // AOS
   *   mac(String):MAC주소, // AOS
   *   deviceId(String): 단말ID, // iOS
   * }
   */
  GET_DEVICE_INFO: 1603, // 단말기정보수집
  /**
   * [관계사] QR스캔
   *  유비케어 SDK 를 통해 QR 스캔 하여 이미지정보로 전환 및 전달
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   errCode(String) : "0"(성공) / "1"(오류) / "2"(취소)
   *   base64ImageData(BinaryData) : 변환된 이미지의 base 64 인코딩된 바이너리 데이터
   * }
   */
  OPEN_QRSCAN: 1604, // QR스캔
  /**
   * [관계사] SMS Hash 요청
   *  SMS 전송을 위한 모니모 Android의 Hash 값 요청
   *  - 삼성생명 보험계약대출 신청 화면에서 사용됨
   *  - 관련 이슈: UPFM-2842, PFM-3252
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   hashValue: Hash 값 // "dKf3cdFpE+o"
   * }
   */
  GET_SMS_HASH: 1605, // SMS Hash 요청
  /**
   * [관계사] SMS 인증번호 요청
   *  타이머 이내 SMS 인증번호 수신시 인증번호 반환
   *  - 삼성생명 보험계약대출 신청 화면에서 사용됨
   *  - 관련 이슈: UPFM-2842, PFM-3252
   *
   * params: {
   *    timerSec(string): SMS 인증요청 최대 대기시간 (default: 10)
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   authNum(String): 수신한 SMS 인증번호 // "123456"
   * }
   */
  GET_SMS_AUTH_NUMBER: 1606, // SMS 인증번호 요청
  /**
   * [관계사] 모니모ECID 전달 (생명 웹 UV측정을 위한 m_ecid값 요청 (어도비))
   *  삼성생명 모니모ECID 전달
   *   - 삼성생명 웹뷰 호출용
   *   - 어도비 ECID수신정보
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   mEcid(String) : 모니모 ECID
   * }
   */
  GET_MONIMO_ECID: 1607, // m_ecid값 요청
  /**
   * 증권 자체 인증 토근 저장
   *  모니모 접속 장애 시, 증권 자체 인증을 통한 주식 비상 주문 서비스
   *  제공을 위해, 증권 오픈API 서버에서 발급한 토큰 저장
   * ※ 앱 내 보안 영역에 저장 필요
   *    토큰별 사이즈 약 1,500 바이트
   *
   * params: {
   *   [⭐️]accessToken(String): 접근토큰,
   *   [⭐️]refreshToken(String): refresh 토큰
   * }
   * return: {
   *  resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  SET_STOCK_TRADE_TOKEN: 1608, // 증권 자체 인증 토큰 저장
  /**
   * 증권 자체 인증 토근 조회
   *  모니모 접속 장애 시, 증권 자체 인증을 통한 주식 비상 주문 서비스
   *  제공을 위해, 증권 오픈API 서버에서 발급한 토큰 조회
   * ※ 앱 내 보안 영역에서 조회
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   accessToken(String): 접근토큰,
   *   refreshToken(String): refresh 토큰
   * }
   */
  GET_STOCK_TRADE_TOKEN: 1609, // 증권 자체 인증 토큰 조회
  /**
   * [관계사] OCR 촬영
   *  Mobile Scan 솔루션을 통한 문서 OCR 촬영 및 이미지 전환 전달
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   data(json array): [{
   *     fileName(String) : 파일명,
   *     fileBase64String(String) : Base64 데이터 스트링,
   *     fileSize(String) : 파일 사이즈
   *   }]
   * }
   */
  OPEN_OCR_SCAN: 1610, // 문서스캔(OCR)
  /**
   * [관계사] FDS 정보 요청
   *  FDS 정보 및 약관동의 여부를 전달한다.
   *  - 약관동의 여부 체크 후 약관동의가 필요한 경우 약관화면을 띄우고 약관동의 저장 및 FDS 정보 전달
   *  - IpInside의 wData 전달
   *
   * params: {
   *   [⭐️]mnmAflcmpC(String):모니모관계사코드
   *   svId(String):FDS 서비스ID
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   prvAgYn(String):약관동의여부 (Y/N)
   *   fdsData(String): 암호화된 FDS wData
   * }
   */
  GET_FDS_INFO: 1611, // FDS 정보 요청
  /**
   * [관계사] 명의도용 (약관)정보 요청
   *  명의도용 약관동의 여부를 전달한다.
   *  - 약관동의 여부 체크 후 약관동의가 필요한 경우 약관화면을 띄우고 약관동의 저장 및 약관동의여부 전달
   *  - FakeFinder의 Fraud Detecton 정보 전달을 위햔 약관동의
   *
   * params: {
   *  [⭐️]mnmAflcmpC(String):모니모관계사코드
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   prvAgYn(String): 약관동의여부 (Y/N)
   * }
   */
  GET_FRAUD_DETECT_INFO: 1612, // 명의도용 (약관)정보 요청
  /**
   * [앱지원] 인앱리뷰호출
   *  인앱 리뷰 api를 호출한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  CALL_INAPP_REVIEW: 1700, // 인앱리뷰 호출
  /**
   * 모니모 웹화면에서 탈퇴 완료 시, Native에 탈퇴완료했음을 전달한다.
   * 애드브릭스 - 탈퇴화면에서 DELETE API 및 이벤트 태깅
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  WITHDRAW_MONIMO: 1701, // 모니모앱 탈퇴 완료 전달
  /**
   * 앱푸시 미수신건수 조회
   *  알림함에 앱푸시미수신건수를 조회한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   nrcvMsgCnt(Number): 미수신메시지건수
   *   userMsgId: 메시지UniqueID
   *   msgGrpNm: 메시지그룹명
   *   appLink: appLink
   *   iconName: iconName
   *   msgId: 메시지ID
   *   pushTitle: push타이틀
   *   pushMsg: push메시지
   *   pushImg: push이미지
   *   msgText: 메시지 텍스트
   *   map1: 추가매핑정보1
   *   map2: 추가매핑정보2
   *   map3: 추가매핑정보3
   *   msgType: 메시지유형
   *   readYn: 읽음여부
   *   delYn: 삭제여부
   *   expireDate: 메시지유효날짜
   *   regDate: 메시지등록날짜
   *   msgGrpCd: 메시지그룹코드
   *   targetType: 개인화 메세지유무 코드("M"|"T")
   * }
   */
  GET_NEW_PUSH_COUNT: 1702, // 알림 앱푸시 미수신건수조회
  /**
   * 알림설정상태조회
   *  디바이스알림/설정알림 상태를 조회한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * data : {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   dvcNotySe(string):디바이스알림설정여부(Y/N)
   *   sappNotySe(string):앱알림설정여부(Y/N)
   * }
   */
  GET_PUSH_SETTING: 1703, // 알림설정 상태조회
  /**
   * 주소검색결과리턴
   *  Native에서 웹으로 주소검색을 요청했을때, 그결과를 다시 Native로 전달한다.
   *  Native는 해당브릿지를 받으면 주소검색화면 브라우저를 닫는다.
   *
   * params : {
   *   zip(string):우편번호
   *   ltn(string):지번
   *   pnadr(string):우편번호주소
   *   adbzip(string):우편번호외주소
   *   bldMngtNo(string):건물관리번호
   * }
   */
  RETURN_ADDRESS: 1704, // 주소검색결과리턴
  /**
   * 약관동의여부결과리턴
   *  Native에서 웹으로 약관상세화면을 요청했을때, 그결과를 다시 Native로 전달한다.
   *  Native는 해당브릿지를 받으면 약관동의여부를 가져가고 해당화면을 닫는다.
   *
   * params : {
   *   mnmPrvGrpId(string):모니모약관그룹ID
   *   mnmPrvId(string):모니모약관ID
   *   prvAgYn(string):약관동의여부
   *   wconFomDvC(string):동의서형태구분코드
   * }
   *
   * 참고)
   * Native에서 약관상세화면 요청시 아래와 같이 파라미터를 설정해야함.
   *
   * listType: 약관 상세 화면 진입한 위치 [ group: 그룹레벨, individual: 그룹 하위레벨 ]
   * wcmsTitUrl: WCMS제목URL
   * wcmsCnUrl: WCMS내용URL
   *
   * // 그룹레벨에서 진입한 경우(그룹 하위 약관상세에서 진입한 경우 빈 문자열 전달)
   * mnmPrvGrpId: 모니모약관그룹ID
   * mnmPrvGrpNm: 모니모약관그룹명
   * mnmPrvGrpMndtYn: 모니모약관그룹필수여부
   *
   * // 그룹 하위레벨에서 진입한 경우(그룹에서 진입한 경우 빈 문자열 전달)
   * mnmPrvId: 모니모약관ID
   * mnmPrvNm: 모니모약관명
   * mnmPrvMndtYn: 모니모약관필수여부
   * mnmPrvCntsTpc: 모니모약관컨텐츠유형코드 [ 01:기본형(탭없음), 02: 전체/요약동의서 분리형(탭2), 03: 통신사(탭3) 형태 ]
   * prvAgYn: 약관동의여부(약관상세화면 진입 전 체크박스를 통해 동의했는지 여부)
   *
   * 참고2) 약관상세화면 요청 Url 예시(url 경로?쿼리스트링 형태)
   * 1. 그룹레벨에서 진입한경우(그룹 하위레벨 관련 데이터 빈 문자열 삽입)
   * - [도메인]/common/provision/UPFMCO0201M0
   * - ?listType=group&wcmsTitUrl=/wcms/monimo/common
   * - /clause/general/1350749_24600.html
   * - &wcmsCnUrl=/wcms/monimo/common
   * - /clause/general/1350749_24983.json
   * - &mnmPrvGrpId=PRG211005003
   * - &mnmPrvGrpNm=모니모 이용 약관 필수동의
   * - &mnmPrvGrpMndtYn=N&mnmPrvId=&mnmPrvNm=
   * - &mnmPrvMndtYn=&mnmPrvCntsTpc=01&prvAgYn=N
   *
   * 2. 그룹 하위레벨에서 진입한 경우(그룹 레벨 관련 데이터 빈 문자열 삽입)
   * - [도메인]/common/provision/UPFMCO0201M0?listType=individua
   * - l&wcmsTitUrl=/wcms/monimo/common
   * - /clause/general/1350749_24600.html
   * - &wcmsCnUrl=/wcms/monimo/common
   * - /clause/general/1350749_24983.json
   * - &mnmPrvGrpId=
   * - &mnmPrvGrpNm=&mnmPrvGrpMndtYn=&mnmPrvId=PRV211001017
   * - &mnmPrvNm=모니모 이용 약관 필수동의_모니모 회원가입 약관
   * - &mnmPrvMndtYn=N&mnmPrvCntsTpc=01&prvAgYn=Y
   */
  RETURN_AGREEMENT: 1705, // 약관동의여부결과리턴
  /**
   * 모니모페이 가입 여부
   *  모니모페이 가입 여부를 전달한다.
   *
   * params: {}, // Input값 없어도 빈값 필수s
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   regMonimoPayYn(string): 모니모페이 가입여부('Y'|'N')
   * }
   */
  IS_REG_MONIMOPAY: 1706, // 모니모페이 가입 여부
  /**
   * 웹뷰 헤더 타이틀 설정
   *  열려있는 웹뷰의 헤더 타이틀을 동적으로 변경한다.
   *
   * params: {
   *   [⭐️]title(String): 웹뷰 헤더 타이틀명
   * }
   */
  SET_HEADER_TITLE: 1800, // 헤더 타이틀 설정
  /**
   * 간편모드 설정값 요청
   *  간편(고령자) 모드 설정값을 전달한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   simpleModeYn(String): 간편모드 설정여부 ('Y'|'N')(default: 'N')
   * }
   */
  GET_SIMPLE_MODE: 1801, // 간편모드 설정값 요청
  /**
   * TTS 음성 파일 확인
   *  해당 음성이 서버에 있는지 확인하여 결과를 반환한다.
   *
   * params: {
   *   fcstBrchId(String): 예보지점ID
   *   [⭐️]mnmTtsSvDvC(String): 모니모TTS서비스구분코드 (ALL: 전체, 001: 증권브리핑, 002: 뉴스레터, 003: 산책지수)
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   *   exstYn(string): 파일존재여부,
   *   fileInfos: [{  // 음성파일정보응답DTO
   *     fileUrl(string): 파일경로
   *     imageFileUrl(string): 이미지파일경로
   *     title(string): 제목
   *     mnmTtsSvDvC(String): 모니모TTS서비스구분코드 (ALL: 전체, 001: 증권브리핑, 002: 뉴스레터, 003: 산책지수)
   *   }]
   * }
   */
  CHECK_MEDIA_FILE: 1802, // TTS 음성 파일 확인
  /**
   * TTS 음성 파일 재생
   *  해당 음성을 재생한다.
   *
   * params: {
   *   [⭐️]fileInfos: [{  // 음성파일정보응답DTO
   *     fileUrl(string): 파일경로
   *     imageFileUrl(string): 이미지파일경로
   *     title(string): 제목
   *   }]
   * }
   * return: {
   *   resultCode(string): '', // '00':성공, '10':미지원브릿지, '11':parsing Error, '12':InputError(필수값누락 or input type확인), '99':실패
   * }
   */
  PLAY_MEDIA_FILE: 1803, // TTS 음성 파일 재생
  /**
   * TTS 음성 파일 정지
   *  TTS 플레이어의 재생을 종료한다.
   *
   * params: {}, // Input값 없어도 빈값 필수
   */
  STOP_MEDIA_FILE: 1804, // TTS 음성 파일 정지
};
