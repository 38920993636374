/********************************
 * Store 유틸
 ********************************/
export default {
  get(key, context = window?.$nuxt?.context) {
    return context.$storage.getState(key);
  },
  set(key, value, context = window?.$nuxt?.context) {
    return context.$storage.setState(key, value);
  },
  remove(key, context = window?.$nuxt?.context) {
    context.$storage.removeState(key);
  },
  watch(key, fn, context = window?.$nuxt?.context) {
    return context.$storage.watchState(key, fn);
  },
};
