/********************************
 * Session Storage 유틸
 ********************************/

const isObjectOrArray = obj => {
  const type = Object.prototype.toString.call(obj);
  return type === '[object Object]' || type === '[object Array]';
};
const decodeValue = val => {
  const type = Object.prototype.toString.call(val);
  if (type === '[object Object]') {
    return val;
  }
  if (type === 'undefined') {
    return val;
  }
  try {
    return JSON.parse(val);
  } catch (error) {
    return val;
  }
};
export default {
  get(key) {
    if (!window?.sessionStorage) {
      return;
    }
    return decodeValue(window?.sessionStorage.getItem(key));
  },
  set(key, value) {
    if (!window?.sessionStorage) {
      return;
    }
    const sesStorage = window?.sessionStorage;
    if (isObjectOrArray(value)) {
      sesStorage.setItem(key, JSON.stringify(value));
    } else {
      sesStorage.setItem(key, value);
    }
    return value;
  },
  remove(key) {
    if (!window?.sessionStorage) {
      return;
    }
    window?.sessionStorage.removeItem(key);
  },
};
