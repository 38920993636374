/********************************
 * 이달의 미션 관련 유틸
 ********************************/

import { mainInquirySync } from '@/api/mdp/ma';
import ongobridge from '@/src/static/js/monimo/app/bridge/main';
import Log from '@/utils/log';
import CommonUtil from '@/utils/common-util';
import AppUtil from '@/src/static/js/monimo/app/bridge/modules/util.js';

export default {
  /**
   * 상세 미션이 마데인 경우 마데 자산 가입유무에 따른 페이지 이동 분기 처리
   *
   * params: {
   *  target(String):이동할 화면 이름
   *  moveParam(Object) : {
   *    type(String) : target 화면 이동 후 동작 유형,
   *     // '01' : 포커스(스크롤이동), '02' : 탭이동, '03' : URL이동, '04' : 탭이동+포커스(스크롤이동), '05' : 탭이동+URL이동, '06' : 탭이동+포커스(스크롤이동)+URL이동, '07' : 클릭
   *    id(String)   : target 화면 이동 후 동작할 id,
   *    account_open      // 계좌
   *    consume_open      // 소비
   *    invest_open       // 투자
   *    insurance_open    // 보험
   *    load_open         // 대출
   *    annuity_open      // 연금
   *    point_open        // 포인트머니
   *    health_open       // 건강
   *    addproperty_open  // 자산추가
   *    screenedit_open   // 자산편집
   *    connmanage_open   // 자산연결관리
   *  }
   * }
   */
  async myDataMissionCheck(param) {
    if (!this.versionCheck()) {
      return false;
    }
    try {
      const res = await mainInquirySync();
      Log.log('[공통-missionUtil] 자산홈 가입유무 및 전송철회 res ', res);
      if (res && res.success) {
        const mdJYn = res?.payload?.mdJYn; // 자산 가입 유무 N : 자산가입, Y : 자산미가입
        // 한번도 마이자산 연결 안한 고객에 대해서 mdJYn이 null로 넘어오는 경우가 있음 null 조건 체크 제외
        if (mdJYn === 'N') {
          ongobridge.openNative({ target: param.target, moveParam: param.moveParam });
        } else {
          ongobridge.openNative({ target: param.target });
        }
      }
    } catch (error) {
      Log.log('[공통-missionUtil] 자산홈 가입유무 및 전송철회 res error', error);
    }
  },
  versionCheck() {
    if (CommonUtil.isMobileApp()) {
      const isMydataApp = AppUtil.activateService({ aos: '10.3.0', ios: '10.3.0' }); // 마이데이터 동작여부 Flag 설정(231006 - 10.3.0 앱분기)
      return isMydataApp;
    }
    return false;
  },
};
