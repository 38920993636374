/**
 * mutation
 * state에 정보 저장시에 mut에 선언하여 사용 * * * * * * * * * * * * * * * *
 * setXxx / getXxx
 *
 * dispatch -> actions(commit) -> mutations(state) -> state
 * rootState는 action과 getters로만 접근이 가능하며, 직접 접근이 안됩니다.
 *
 */

export const state = () => ({
  chkAge: {}, // 성인 & 주니어 구분
  reqPayment: '', // 요청 총 금액
  reqNickName: '', // 더치페이 별명
  memberList: [], // 더치페이 맴버 리스트
  reqAmountList: [], // 계산된 각자에게 요청할 금액 리스트
  onGoingMemberLists: {}, // 진행중인 더치페이 리스트
  hsCrtYn: '', // 현재 로그인한 사용자의 더치페이 건을 클릭해서 들어갔는지의 여부
  selectedInfo: {}, // 더치페이 결제 정보
  tab: 0, // 선택한 탭 정보
  pageOffsetY: 0, // 보고 있던 화면 위치
  isFirstRun: true, // 푸시를 받아 들어온 경우
  isEndPopupNeed: false, // 직전에 해당 더치페이를 종료했는지의 여부
});

/**
 * MUTATION SETTER * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * state값 변경은 MUT를 통해서만
 * sync function
 */
export const mutations = {
  /**
   * Store 초기화 메서드 * * * * * * *
   * 데이터 추가될 시, 이곳에 동일하게 추가
   * 하여 데이터 초기화 진행
   */
  // 나이체크
  setChkAge(state, payload) {
    state.chkAge = payload;
  },
  // 총요청금액
  setReqPayment(state, payload) {
    state.reqPayment = payload;
  },
  // 기입력된 더치페이 별명
  setReqNickName(state, payload) {
    state.reqNickName = payload;
  },
  // 계산된분배금액
  setMemberList(state, payload) {
    state.memberList = payload;
  },
  // 계산된분배금액
  setReqAmountList(state, payload) {
    state.reqAmountList = payload;
  },
  // 진행중인리스트
  setonGoingMemberLists(state, payload) {
    state.onGoingMemberLists = payload;
  },
  // 선택된더치페이의호스트
  setHsCrtYn(state, payload) {
    state.hsCrtYn = payload;
  },
  // 선택된정보
  setSelectedInfo(state, payload) {
    state.selectedInfo = payload;
  },
  // 선택된탭정보
  setTab(state, payload) {
    state.tab = payload;
  },
  // 선택된Y좌표정보
  setPageOffsetY(state, payload) {
    state.pageOffsetY = payload;
  },
  // 초기실행여부 : deepLink에사용
  setIsFirstRun(state, payload) {
    state.isFirstRun = payload;
  },
  // 더치페이 종료 시점 기억
  setIsEndPopupNeed(state, payload) {
    state.isEndPopupNeed = payload;
  },
};

/**
 * ACTIONS * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * execute mutations function with state update
 * async function
 */
export const actions = {};

/**
 * GETTER * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
export const getters = {
  // 나이체크
  getChkAge(state) {
    return state.chkAge;
  },
  // 총요청금액
  getReqPayment(state) {
    return state.reqPayment;
  },
  // 기입력된 더치페이 별명
  getReqNickName(state) {
    return state.reqNickName;
  },
  // 추가된 맴버리스트
  getMemberList(state) {
    return state.memberList;
  },
  // 계산된분배금액
  getReqAmountList(state) {
    return state.reqAmountList;
  },
  // 진행중인리스트
  getOnGoingMemberLists(state) {
    return state.onGoingMemberLists;
  },
  // 선택된더치페이의호스트
  getHsCrtYn(state) {
    return state.hsCrtYn;
  },
  // 선택된정보
  getSelectedInfo(state) {
    return state.selectedInfo;
  },
  // 선택된탭정보
  getTab(state) {
    return state.tab;
  },
  // 선택된Y좌표정보
  getPageOffsetY(state) {
    return state.pageOffsetY;
  },
  // 초기실행여부 : deepLink에사용
  getIsFirstRun(state) {
    return state.isFirstRun;
  },
  // 종료 시점 기억
  getIsEndPopupNeed(state) {
    return state.isEndPopupNeed;
  },
};
