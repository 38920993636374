/**
 * MonimoAppInterface 정의
 */
import MonimoBridgeInterface from '@/plugins/bridge/monimo-bridge-interface';
import bus from '@/plugins/eventBus';
import Log from '@/utils/log';
import TypeUtil from '@/utils/type-util';
import CommonUtil from '@/utils/common-util';

export const CONSOLE_STYLE1 = [
  'background-color: blue',
  'border: 1px solid',
  'color: white',
  'display: block',
  'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)',
  'box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset',
  'line-height: 20px',
  'text-align: center',
  'font-size: 16px',
  'font-weight: bold',
  'padding-left: 120px',
  'padding-top: 10px',
  'padding-right: 120px',
  'padding-bottom: 10px',
  'border-radius: 0.5em',
].join('; ');
// 신규 브릿지 로그
Log.log(`%c New Monimo Bridge initializing...`, CONSOLE_STYLE1);

export const mnmBridge = {
  HANDLER_NAME: 'MonimoAppInterface',

  // OS 구분 여부
  MOBILE_CODE: { AOS: '01', IOS: '02', OTHER: '03' },

  // 페이지 내 브릿지 호출 시퀀스
  seq: 0,

  /** 브릿지 호출 타임스탬프 */
  _call_timeStamps: {},

  /**
   * 모니모 앱내 모니모 페이지에서 AOS 디바이스 백처리 관련 함수
   */
  back: () => {
    Log.log('[공통-monimoBridgeCore] AOS 디바이스 백버튼 처리');
    if (bus) {
      // 마이-자산 탭일 경우 처리
      if (window.location.pathname === '/mydata/asset/UPFMMA0101M0') {
        bus.$emit('click:deviceBackButton');
      } else if (document.querySelector('.sc-sub-header-wrapper')) {
        // SubHeader가 있는 화면인 경우 Event를 Emit하여 SubHeader가 받아서 처리토록 한다.
        bus.$emit('click:deviceBackButton');
      } else if (window.$nuxt.$router?.query?.firstYn === 'Y') {
        // SubHeader가 없는 화면인 경우 여기서 처리.
        // 첫번쨰 페이지인 경우 ( firstYn=Y )
        MonimoBridgeInterface.callBridge(MonimoBridgeInterface.CLOSE_WEBVIEW);
      } else {
        // 그외의 모니모 페이지인 경우
        window.$nuxt.$router?.go(-1);
      }
    } else if (location.search.includes('firstYn=Y')) {
      // 모니모 외부 도메인의 첫번쨰 페이지인 경우 ( firstYn=Y )
      MonimoBridgeInterface.callBridge(MonimoBridgeInterface.CLOSE_WEBVIEW);
    } else {
      // 그 외에는 history.back을 시도한다.
      (history || window?.history)?.back();
    }
  },

  /**
   * 네이티브 핸들러 함수를 호출한다.
   * @param {String} apiCommand 핸들러 Command ID
   * @param {Object} params 파라미터
   * @param {Function} callbackFunc 콜백 함수
   * @param {Boolean} preventDoubleClick 연속 호출 방지 플래그 (기본=false 로 연속 호출을 허용 함)
   */
  exec(apiCommand, params, callbackFunc = '', preventDoubleClick = false) {
    // 연속클릭 방지 설정 시
    if (preventDoubleClick) {
      if (!this._call_timeStamps[apiCommand]) {
        this._call_timeStamps[apiCommand] = new Date().getTime();
      } else {
        const lastCallTime = this._call_timeStamps[apiCommand];
        const currentTime = new Date().getTime();

        // 0.5초 이내 동일한 브릿지 재 호출시 브릿지 호출을 막는다.
        if (currentTime - lastCallTime < 500) {
          return;
        } else {
          // 현재 시각을 최종호출시각으로 udpate
          this._call_timeStamps[apiCommand] = currentTime;
        }
      }
    }

    if (!params) {
      params = {};
    }

    // Native callback 처리를 위한 promise
    let promise;
    const mmBridgeData = this.makeBridgeData(apiCommand, params, callbackFunc);
    Log.log('[공통-monimoBridgeCore] 브릿지 요청 데이터: ', mmBridgeData);
    // 네이티브 앱인경우 네이티브 호출
    if (CommonUtil.isMobileApp()) {
      promise = mmBridgeData.promise;
      // Native 브릿지 호출
      this.callNative(mmBridgeData.jsonData);
    } else {
      // 앱이 아닌 경우
      // 콜백함수 존재 시 호출
      if (TypeUtil.isFunction(callbackFunc)) {
        callbackFunc.apply(window, [{}]);
      }
      // promise resolve 처리
      promise = Promise.resolve({});
    }
    return promise;
  },

  /**
   * Native로 보낼 브릿지 데이터를 생성한다.
   *
   * @param {String} apiCommand 핸들러 Command ID
   * @param {Object} params 파라미터
   * @param {Function} callbackFunc 콜백 함수
   * @returns {Object} {
   *   jsonData {String} 네이티브 브릿지 호출 시 넘겨줄 JSON String Data
   *   promise {Promise} 네이티브 콜백함수가 호출되면 resolve 될 Promise 객체
   * }
   */
  makeBridgeData(apiCommand, params, callbackFunc = {}) {
    // 콜백 생성
    const { onCallback, promise } = this.createCallback(apiCommand, callbackFunc);
    Log.log('[공통-monimoBridgeCore] makeBridgeData onCallback: ', onCallback);
    return {
      jsonData: JSON.stringify({
        command: apiCommand,
        params,
        onCallback,
      }),
      promise,
    };
  },

  /**
   * 네이티브 콜백을 생성한다
   *
   * @param {String} apiCommand 핸들러 함수
   * @param {Function} callbackFunc 콜백 함수
   * @returns {Object} {
   *   callback {String} 네이티브에서 호출할 글로벌 스코프의 콜백함수 이름
   *   promise {Promise} 네이티브 콜백함수가 호출되면 resolve 될 Promise 객체
   * }
   */
  createCallback(apiCommand, callbackFunc) {
    const callbackName = apiCommand + '_' + new Date().getTime() + '_' + ++this.seq;
    Log.log('[공통-monimoBridgeCore] createCallback apiCommand: ', apiCommand, ', callbackName: ', callbackName);

    const promise = new Promise((resolve, reject) => {
      if (!window.monimo.bridge.callback) {
        window.monimo.bridge.callback = {};
      }
      window.monimo.bridge.callback[callbackName] = function () {
        try {
          const mappedArguments = Array.from(arguments).map(argument => {
            let result = {};
            Log.log('[공통-monimoBridgeCore] createCallback argument: ', argument);
            try {
              if (TypeUtil.isString(argument)) {
                argument = argument.replace(/\+/g, '%20');
                argument = argument.replace(/[\r\n]+/g, '<br/>');
              }
              result = JSON.parse(decodeURIComponent(argument));
            } catch (error) {
              Log.error('[공통-monimoBridgeCore] createCallback json parsing error: ', error);
            }
            Log.log('[공통-monimoBridgeCore] createCallback result', result);
            return result;
          });

          // resolve 처리 (브릿지 await 처리 시 페이지에서 response 받음)
          if (TypeUtil.isArray(mappedArguments) && mappedArguments.length === 1) {
            // arguments가 1개인 경우, 해당 argument를 resolve 데이터로 셋팅
            resolve(mappedArguments[0]);
          } else {
            // arguments가 1개가 아닌 경우, Array 타입으로 전체 arguments를 resolve 데이터로 셋팅
            resolve(mappedArguments);
          }

          // 3번째 파라미터 설정 시 콜백함수 호출
          if (TypeUtil.isFunction(callbackFunc)) {
            callbackFunc.apply(window, Array.prototype.slice.call(mappedArguments));
          }
        } catch (err) {
          Log.error('[공통-monimoBridgeCore] createCallback error: ', err);
          // reject 처리
          reject(err);
        }
      };
    });

    return {
      onCallback: "monimo.bridge.callback['" + callbackName + "']",
      promise,
    };
  },

  /**
   * 네이티브를 브릿지를 직접 호출한다.
   *
   * @param {Object} data 브릿지 호출 요청 데이터
   */
  callNative(data) {
    Log.log('[공통-monimoBridgeCore] callNative data', data);

    const deviceCode = CommonUtil.getAppInfo('mblOsDvC');
    if (deviceCode === this.MOBILE_CODE.IOS) {
      window.webkit.messageHandlers[this.HANDLER_NAME].postMessage(data);
    } else if (deviceCode === this.MOBILE_CODE.AOS) {
      window[this.HANDLER_NAME].callMessage(data);
    } else {
      Log.error('[공통-monimoBridgeCore] callNative Not supported this platform');
    }
  },
};
export default mnmBridge;
