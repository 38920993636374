// String 관련 필터 정의
import Vue from 'vue';

// StringUtil 함수들 중에서 필터로 사용할 것이 있다면 가져와서 등록
import StringUtil from '~/utils/string-util';

/**
 * string 을 reverse하여 리턴한다.
 */
Vue.filter('reverse', StringUtil.reverse);

/**
 *  총 이용금액 천 단위 이하 자르기 (만원 단위 출력)
 */
Vue.filter('cutUnit', StringUtil.cutUnit);
