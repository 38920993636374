/**
 * URLParser made by ongosgod@ongosoft.com
 */

import TypeUtil from './type-util';
import {
  IS_DEV,
  IS_TEST,
  IS_STAGE,
  IS_PROD,
  HPP_BASE_URL,
  SLF_BASE_URL,
  SSF_BASE_URL,
  SSS_BASE_URL,
} from './constants';

// 접속 네트워크 환경 구분
const NET_KIND = {
  LTE: 'lte', // Public 네트워크 망
  WIFI: 'wifi', // 내부 AP 망
  WIFI_SSL: 'wifiSsl', // 내부 AP SSL 망
};

/**
 * URLParser 객체 정의
 */
const URLParser = function (url) {
  if (url) {
    this.url = url;
    this.tokenized = false;
  } else {
    throw new Error('url is necessary!');
  }
};

/**
 * URL을 분석하여 각 구성요소로 분리해낸다.
 */
URLParser.prototype.tokenize = function () {
  const urlTokens = {};
  if (this.url) {
    this.url = this.url.replace(
      /^(?:(?:((?:https{0,1}:){0,1})\/\/){0,1}((?:[^:\\/?#]*)(?::\d+){0,1})){0,1}([^?#]*)((?:\?[^#]*){0,1})((?:#.*){0,1})$/gi,
      function (match, protocol, host, pathname, search, hash, index, orgString) {
        urlTokens.protocol = protocol || '';
        if (protocol && protocol.includes(':')) {
          urlTokens.protocolValue = protocol.substring(0, protocol.indexOf(':'));
        }
        urlTokens.host = host || '';
        if (urlTokens.protocol && urlTokens.host) {
          urlTokens.origin = urlTokens.protocol + '//' + urlTokens.host;
        }
        const hostTokens = urlTokens.host.split(':');
        urlTokens.hostname = hostTokens[0];
        if (hostTokens[1]) {
          urlTokens.port = hostTokens[1];
        }
        urlTokens.pathname = pathname || '';
        urlTokens.search = search || '';
        if (search && search.includes('?')) {
          urlTokens.searchValue = search.substring(search.indexOf('?') + 1);
        }
        urlTokens.hash = hash || '';
        if (hash && hash.includes('#')) {
          urlTokens.hashValue = hash.substring(hash.indexOf('#') + 1);
        }
        return orgString;
      },
    );
  }
  this.protocol = urlTokens.protocol ? urlTokens.protocol : '';
  this.protocolValue = urlTokens.protocolValue ? urlTokens.protocolValue : '';
  this.host = urlTokens.host ? urlTokens.host : '';
  this.origin = urlTokens.origin ? urlTokens.origin : '';
  this.hostname = urlTokens.hostname ? urlTokens.hostname : '';
  this.port = urlTokens.port ? urlTokens.port : '';
  this.pathname = urlTokens.pathname ? urlTokens.pathname : '';
  this.search = urlTokens.search ? urlTokens.search : '';
  this.searchValue = urlTokens.searchValue ? urlTokens.searchValue : '';
  this.hash = urlTokens.hash ? urlTokens.hash : '';
  this.hashValue = urlTokens.hashValue ? urlTokens.hashValue : '';
  this.tokenized = true;

  return this;
};

/**
 * 현재 객체내에 설정된 URL 구성정보들을 가지고 URL을 문자열로 생성한다.
 */
URLParser.prototype.toString = function () {
  if (this.tokenized) {
    return (
      (this.host ? (this.protocol ? this.protocol : '') + '//' + this.host : '') +
      this.pathname +
      this.search +
      this.hash
    );
  } else {
    return this.url;
  }
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (단건처리)
 * @param key {string} 파라미터 key
 * @param value {string} 파라미터 value
 */
URLParser.prototype.addParam = function (key, value) {
  const _paramObject = {};
  _paramObject[key] = value;
  return this.addParamObject(_paramObject);
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (다건처리)
 * @param paramObject {object} 파라미터 object - object 내의 key-value 쌍으로 파라미터를 붙인다.
 * 			{
 * 				key1: value1,
 * 				key2: value2,
 * 				...
 * 			}
 */
URLParser.prototype.addParamObject = function (paramObject, encoding = true) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  if (!this.tokenized) {
    this.tokenize();
  }

  if (TypeUtil.isPlainObject(paramObject)) {
    for (const key in paramObject) {
      const value = paramObject[key];
      if (key && value) {
        this.search += (this.search ? '&' : '?') + (key + '=' + (encoding ? encodeURIComponent(value) : value));
      }
    }
    //			scard.log.debug("		>>>>>>>>>> Parameter appended url: " + this.toString());
  }
  return this;
};

/**
 * URL에 get방식 query 파라미터를 붙인다. (다건처리)
 * @param paramArray {array} 파라미터 key,value object array - object 내에 key값, value값을 각각 정의하여 사용.
 * 			[
 * 				{
 * 					key: {string} 파라미터 key1
 * 					value: {string} 파라미터 value1
 * 				},
 * 				{
 * 					key: {string} 파라미터 key2
 * 					value: {string} 파라미터 value2
 * 				}, ...
 * 			]
 */
URLParser.prototype.addParamArray = function (paramArray, encoding = true) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  if (!this.tokenized) {
    this.tokenize();
  }

  if (paramArray && paramArray.length > 0) {
    for (let i = 0; i < paramArray.length; i++) {
      const key = paramArray[i].key;
      const value = paramArray[i].value;
      if (key && value) {
        this.search += (this.search ? '&' : '?') + (key + '=' + (encoding ? encodeURIComponent(value) : value));
      }
    }
    //			scard.log.debug("		>>>>>>>>>> Parameter appended url: " + this.toString());
  }
  return this;
};

/**
 * 특정 URL이 대상 (BASE) URL 목록의 도메인에 해당하는 URL인지를 체크한다.
 *
 * @param {Array} urls 대상 (BASE) URL 목록
 * @returns
 */
URLParser.prototype.isIncluded = function (urls) {
  // tokenize 호출이 되어 있지 않으면 선호출 시켜서 URL 구성요소 분리해야 한다.
  !this.tokenized && this.tokenize();
  return !this.host || urls?.some(url => url.includes(this.host));
};

export default URLParser;

/**
 * HPP 카드 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isHppUrl = url => {
  return new URLParser(url).tokenize().isIncluded([getHppBaseUrl()]);
};

/**
 * SLF 생명_홈페이지 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSlfHmpgUrl = url => {
  const domain = SLF_BASE_URL[getEnvKey()].HMPG;
  return new URLParser(url).tokenize().isIncluded([domain]);
};

/**
 * SLF 생명_다이렉트 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSlfDirctUrl = url => {
  const domain = SLF_BASE_URL[getEnvKey()].DIRCT;
  return new URLParser(url).tokenize().isIncluded([domain]);
};

/**
 * SSF 화재_홈페이지 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSsfHmpgUrl = url => {
  const domain = SSF_BASE_URL[getEnvKey()].HMPG;
  return new URLParser(url).tokenize().isIncluded([domain]);
};

/**
 * SSF 화재_다이렉트 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSsfDirctUrl = url => {
  const domain = SSF_BASE_URL[getEnvKey()].DIRCT;
  return new URLParser(url).tokenize().isIncluded([domain]);
};

/**
 * SSS 증권 URL 여부를 체크한다.
 * @param {String} url URL
 */
export const isSssUrl = url => {
  const domain = SSS_BASE_URL[getEnvKey()];
  return new URLParser(url).tokenize().isIncluded([domain]);
};

/**
 * 현재 환경에서의 hpp baseUrl을 가져온다.
 */
export const getHppBaseUrl = () => {
  let hppBaseUrl = '';
  const envKey = getEnvKey();

  // 로컬or개발계일 때
  if (IS_DEV || IS_TEST) {
    if (getUserAgentValue('netKind') === NET_KIND.WIFI) {
      hppBaseUrl = HPP_BASE_URL[envKey].INNER;
    } else if (getUserAgentValue('netKind') === NET_KIND.WIFI_SSL) {
      hppBaseUrl = HPP_BASE_URL[envKey].SSL;
    } else if (getUserAgentValue('netKind') === NET_KIND.LTE) {
      hppBaseUrl = HPP_BASE_URL[envKey].EXTERNAL;
    }
    // agent에 값이 없다면, 로컬로 간주
    // 현재 https로 접속중인 경우
    else if (location.protocol.includes('https')) {
      hppBaseUrl = HPP_BASE_URL[envKey].SSL;
    } else {
      hppBaseUrl = HPP_BASE_URL[envKey].INNER;
    }
  }
  // 그 외
  else {
    hppBaseUrl = HPP_BASE_URL[envKey];
  }

  return hppBaseUrl;
};

/**
 * UserAgent String에서 Key=Value 형태의 문자열 중에서 원하는 key에 대한 value를 꺼내온다.
 * @param {String} searchKey 찾고자 하는 값.
 * @returns UserAgent에 특정 key 값
 */
const getUserAgentValue = searchKey => {
  const userAgent = navigator.userAgent;
  return userAgent
    ?.split(';')
    ?.map(token => {
      return {
        key: token?.split('=')[0],
        value: token?.split('=')[1],
      };
    })
    ?.filter(({ key }) => key?.trim() === searchKey)[0]?.value;
};

// 서버 환경에 맞는 상수 문자열 가져오기
const getEnvKey = () => {
  let key = '';
  (IS_DEV || IS_TEST) && (key = 'TEST');
  IS_STAGE && (key = 'STAGE');
  IS_PROD && (key = 'PROD');

  return key;
};
