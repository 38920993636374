// URL 관련 필터 정의
import Vue from 'vue';

// URL 관련 함수들 중에서 필터로 사용할 것이 있다면 가져와서 등록
import {
  toPfmUrl,
  toHppUrl,
  toSlfHmpgUrl,
  toSlfDirctUrl,
  toSsfHmpgUrl,
  toSsfDirctUrl,
  toSssUrl,
  toMnmAflcmpC,
} from '~/utils/url-parser';

// PFM (모니모) URL 로 도메인을 변환하여 가져온다.
Vue.filter('toPfmUrl', toPfmUrl);

// HPP 카드 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toHppUrl', toHppUrl);

// SLF 생명_홈페이지 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toSlfHmpgUrl', toSlfHmpgUrl);

// SLF 생명_다이렉트 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toSlfDirctUrl', toSlfDirctUrl);

// SSF 화재_홈페이지 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toSsfHmpgUrl', toSsfHmpgUrl);

// SSF 화재_다이렉트 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toSsfDirctUrl', toSsfDirctUrl);

// SSS 증권 URL 로 도메인을 변환하여 가져온다.
Vue.filter('toSssUrl', toSssUrl);

// URL이 어떤 관계사 URL인지를 체크하여 해당 관계사의 모니모 관계사 코드를 가져온다.
Vue.filter('toMnmAflcmpC', toMnmAflcmpC);
