import _ from 'lodash';
import bus from '@/plugins/eventBus';

// 중복되는 다이얼로그 닫기 함수 선언
const closeDialog = async (context, dialogId, isShow) => {
  const msgDialog = context.store.state.msgDialog;
  if (msgDialog != null && dialogId === msgDialog.contentInfo.id) {
    await context.store.dispatch('messageDialogShowHide', { ...msgDialog, visible: isShow });
    bus.$emit('messageDialogCallbackAfterClosing', dialogId);
  }
};

export default {
  /**
   * 모달 메시지 클릭한 버튼의 btnId
   */
  messageDialogCurrentEventId: btnId => {
    bus.$emit('messageDialogCurrentEventId', btnId);
  },
  /**
   * 모달 메시지 닫은 후 call back
   */
  messageDialogCallbackAfterClosing: btnId => {
    bus.$emit('messageDialogCallbackAfterClosing', btnId);
  },
  /**
   * 모달 메시지 messageData 전달. 0917
   */
  messageDialogData: (msgInfo, context = window?.$nuxt?.context) => {
    const msgData = {
      id: '',
      wrapStyleClass: 'message-dialog',
      iconName: 'icon-error-caution',
      contentStyleClass: '',
      msgCode: '',
      contentText: '',
      buttons: [
        {
          btnId: 'defaultConfirm',
          btnStyleClass: 'primary',
          btnText: '확인',
          eventName: 'close',
        },
      ],
      persistent: true,
      ...msgInfo,
    };

    const isShow = !!(msgInfo.msgCode || msgInfo.contentText);

    // const infos = {
    //   visible: true,
    //   contentInfo: msgData,
    // };

    if (isShow) {
      const infos = {
        visible: true,
        contentInfo: msgData,
      };

      context.store.dispatch('messageDialogShowHide', infos);
    }
  },
  // commonDialogState: (isShow = false, context = window?.$nuxt?.context) => {
  closeMessageDialog: async (evtid, isShow = false, context = window?.$nuxt?.context) => {
    // TODO
    // Dialog가 닫힐때 transition delay 때문에 contentInfo를 바로 날려버리면 어색하게 닫히기 때문에, visible만 일단 false로 닫게 처리
    // 나중에 버튼같은게 어색해 지면, delay를 주면서 먼저 visible을 끄고, 적당한 delay 이후 contentInfo를 날려야 함
    // await context.store.dispatch('messageDialogShowHide', { contentInfo: {}, visible: isShow });
    await context.store.dispatch('messageDialogShowHide', { ...context.store.state.msgDialog, visible: isShow });

    bus.$emit('messageDialogCallbackAfterClosing', evtid);
  },
  commonDialogState: state => {
    bus.$emit('commonDialogState', state);
  },
  // 기존 closeMessageDialog 함수 수정 시 영향도가 너무커서 새로 만듬
  // MessageDialog ID로 해당 아이디를 가진 다이얼로그만 닫는다
  closeMessageDialogById: async (dialogId, isShow = false, context = window?.$nuxt?.context) => {
    if (_.isArray(dialogId)) {
      // dialogId를 Array로 넘겼을때
      for (let i = 0; i < dialogId.length; i++) {
        const id = dialogId[i];
        await closeDialog(context, id, isShow);
      }
    } else if (typeof dialogId === 'string') {
      await closeDialog(context, dialogId, isShow);
    }
  },
};
