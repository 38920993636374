// 전역 필터 정의 함수
import Vue from 'vue';

// Util 함수들 중에서 필터로 사용할 것이 있다면 가져와서 등록
import {} from './date-filter';
import {} from './string-filter';
import {} from './url-filter';
import {
  decodeXss,
  convertWcmsPath,
  resolveWcmsPath,
  getEmailLocalPart,
  addComma,
  setDefaultMydataField,
} from '~/utils';

// 전역 필터 등록

/**
 * XSS 디코딩 필터
 */
Vue.filter('decodeXss', decodeXss);

/**
 * WCMS URL 필터: WCMS PATH -> WCMS 절대경로 Full URL로 변환
 */
Vue.filter('convertWcmsPath', convertWcmsPath);

/**
 * XSS Decoding + WCMS URL 변환
 */
Vue.filter('resolveWcmsPath', resolveWcmsPath);

/**
 * '@'를 통해 이메일 타입 구분
 */
Vue.filter('getEmailLocalPart', getEmailLocalPart);

/**
 * 3자리 콤마 찍기
 */
Vue.filter('addComma', addComma);

/**
 * 마이데이터용 default field 처리 (-)
 */
Vue.filter('setDefaultMydataField', setDefaultMydataField);

// TODO 마스킹 처리 필터 등
