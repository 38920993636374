/********************************
 * Nuxt.js axios plugin 설정 파일
 ********************************/
import TypeUtil from '~/utils/type-util';

/**
 * 프로젝트에 필요한 각 axios 인스턴스들을 초기화하여, context에 주입한다.
 * @param {Object} context Nuxt.js Context 객체
 * @param {Function} inject context에 주입(inject)해주는 함수
 */
export default function (context, inject) {
  // 로컬환경에선 Webpack Dev Server Proxy로 설정
  // pfm/hpp/rcm에 대한 설정 분기
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'devlocal') {
    // pfm proxy axios 생성
    inject(
      'pfm',
      createAxiosInstance(context, `${window?.location?.origin || process.env.PFM_BASE_URL}/wds-proxy/pfm`),
    );

    // hpp proxy axios 생성
    inject(
      'hpp',
      createAxiosInstance(context, `${window?.location?.origin || process.env.BROWSER_BASE_URL}/wds-proxy/hpp`),
    );

    // hpp_ssl proxy axios 생성
    inject(
      'hpp_ssl',
      createAxiosInstance(context, `${window?.location?.origin || process.env.BROWSER_BASE_URL}/wds-proxy/hpp_ssl`),
    );

    // hpp_api axios 생성
    inject('hpp_api', createAxiosInstance(context, process.env.HPP_BASE_API_URL));

    // rcm_ssl axios 생성
    inject('rcm_ssl', createAxiosInstance(context, process.env.RCM_BASE_URL_SSL));

    // mdp proxy axios 생성
    inject(
      'mdp',
      createAxiosInstance(context, `${window?.location?.origin || process.env.MDP_ISP_URL}/wds-proxy/mdp`, false),
    );

    // wcms local axios 생성
    inject(
      'wcms_local',
      createAxiosInstance(context, `${window?.location?.origin || process.env.WCMS_LOCAL_URL}`, false, false),
    );
  }

  // 개발계
  // pfm/hpp/rcm에 대한 설정 분기
  else if (process.env.NODE_ENV === 'test') {
    // pfm axios 생성
    inject('pfm', createAxiosInstance(context, `${window?.location?.origin || process.env.PFM_BASE_URL}`));

    // hpp axios 생성(withCredentials=true)
    inject('hpp', createAxiosInstance(context, `${process.env.HPP_BASE_URL}/frontservice`, true));

    // hpp_ssl axios 생성(withCredentials=true)
    inject('hpp_ssl', createAxiosInstance(context, `${process.env.HPP_BASE_SSL_URL}/frontservice`, true));

    // hpp_external axios 생성(withCredentials=true)
    inject('hpp_external', createAxiosInstance(context, `${process.env.HPP_BASE_EXTERNAL_URL}/frontservice`, true));

    // hpp_api axios 생성
    inject('hpp_api', createAxiosInstance(context, process.env.HPP_BASE_API_URL, true));

    // rcm_ssl axios 생성
    inject('rcm_ssl', createAxiosInstance(context, process.env.RCM_BASE_URL_SSL));

    // rcm_external axios 생성
    inject('rcm_external', createAxiosInstance(context, process.env.RCM_BASE_URL_EXTERNAL));

    // mdp axios 생성
    inject('mdp', createAxiosInstance(context, `${window?.location?.origin || process.env.MDP_ISP_URL}`, false));

    // wcms local axios 생성
    inject('wcms_local', createAxiosInstance(context, process.env.WCMS_LOCAL_URL, false, false));
  }

  // 그외 (검증/운영)
  // pfm/hpp/rcm에 대한 보정 및 설정
  else {
    // pfm axios 생성
    inject('pfm', createAxiosInstance(context, `${process.env.PFM_BASE_URL}`));

    // hom axios 생성(withCredentials=true)
    inject('hpp', createAxiosInstance(context, `${process.env.HPP_BASE_URL}/frontservice`, true));

    // hpp_api axios 생성
    inject('hpp_api', createAxiosInstance(context, process.env.HPP_BASE_API_URL, true));

    // mdp axios 생성
    inject('mdp', createAxiosInstance(context, `${window?.location?.origin || process.env.MDP_ISP_URL}`, false));
  }

  // rcm axios 생성
  inject('rcm', createAxiosInstance(context, process.env.RCM_BASE_URL));

  // wcms axios 생성
  inject('wcms', createAxiosInstance(context, process.env.WCMS_BASE_URL, false, false));
}

/**
 * nuxt.config.js 에서 설정된 기본 $axios 객체의 설정을 기반으로 새로운 axios 객체를 생성하여 리턴한다.
 * @param {Object} $axios base axios 인스턴스
 * @param {String} baseURL 새로 생성할 axios의 baseURL
 * @param {Boolean} withCredentials 크로스도메인 요청시 쿠키 사용여부 (디폴트: false)
 * @param {Boolean} addNoCacheHeader no-cache 헤더 사용 여부 (디폴트: true)
 * @param {Object} config 추가적으로 override할 axios instance 생성 옵션 config
 * @returns
 */
const createAxiosInstance = (context, baseURL = '/', withCredentials = false, addNoCacheHeader = true, config) => {
  const instance = context?.$axios.create({
    baseURL,
    withCredentials,
    progress: true,
    // nocache 헤더 추가
    headers: addNoCacheHeader
      ? {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: 0,
        }
      : {},
    ...config,
  });

  // setting interceptors
  // 원래 nuxt axios 디폴트로 progress가 떠야 하는데 안뜨네..
  // 인터셉터로 한번 시도해 봊지
  instance.onRequest(config => {
    config.progress && TypeUtil.isFunction(window?.$nuxt?.$loading?.start) && window?.$nuxt?.$loading?.start();
  });
  instance.onResponse(response => {
    response?.config?.progress &&
      TypeUtil.isFunction(window?.$nuxt?.$loading?.finish) &&
      window?.$nuxt?.$loading?.finish();
  });
  // eslint-disable-next-line node/handle-callback-err
  instance.onError(err => {
    err?.config?.progress && TypeUtil.isFunction(window?.$nuxt?.$loading?.finish) && window?.$nuxt?.$loading?.finish();
  });
  // eslint-disable-next-line node/handle-callback-err
  instance.onRequestError(err => {
    err?.config?.progress && TypeUtil.isFunction(window?.$nuxt?.$loading?.finish) && window?.$nuxt?.$loading?.finish();
  });
  // eslint-disable-next-line node/handle-callback-err
  instance.onResponseError(err => {
    err?.config?.progress && TypeUtil.isFunction(window?.$nuxt?.$loading?.finish) && window?.$nuxt?.$loading?.finish();
  });
  return instance;
};
