/**
 * mutation
 * state에 정보 저장시에 mut에 선언하여 사용 * * * * * * * * * * * * * * * *
 * setXxx / getXxx
 *
 * dispatch -> actions(commit) -> mutations(state) -> state
 * rootState는 action과 getters로만 접근이 가능하며, 직접 접근이 안됩니다.
 *
 */

export const state = () => ({
  mnmLvWtcfApyYn: '',
});

/**
 * MUTATION SETTER * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * state값 변경은 MUT를 통해서만
 * sync function
 */
export const mutations = {
  /**
   * Store 초기화 메서드 * * * * * * *
   * 데이터 추가될 시, 이곳에 동일하게 추가
   * 하여 데이터 초기화 진행
   */
  // 모니모레벨가중치추가적용여부 set
  setMnmLvWtcfApyYn(state, payload) {
    state.mnmLvWtcfApyYn = payload;
  },
};

/**
 * ACTIONS * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * execute mutations function with state update
 * async function
 */
export const actions = {};

/**
 * GETTER * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
export const getters = {
  // 모니모레벨가중치추가적용여부 get
  getMnmLvWtcfApyYn(state) {
    return state.mnmLvWtcfApyYn;
  },
};
