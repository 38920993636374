/********************************
 * String 관련 유틸
 ********************************/

import DateUtil from '~/utils/date-util';

/**
 * 연락처명에서 사용하는 문자열의 우선순위 입니다.
 * 한글 > 영문 > 숫자 > 특수문자 > 그 외
 *
 * @param char
 * @returns num
 */
const charPriorityForName = char => {
  if (/[ㄱ-힣]/.test(char)) return 1;
  if (/[a-zA-Z]/.test(char)) return 2;
  if (/[0-9]/.test(char)) return 3;
  // ASCII 특수문자
  if (/[\u0020-\u007F]/.test(char)) return 4;
  return 5;
};

export default {
  // AS-IS 디채 scard.common.js에 있는 함수들 START
  /**
   * str이 length보다 작은 경우, str 을 padStr 으로 length 만큼 padding 처리 후 리턴한다.
   * str이 length보다 큰 경우, str을 앞에서 length 만큼 잘라서 리턴한다.
   * @param {string} str 입력문자열
   * @param {number} length 출력문자열 길이
   * @param {string} padStr 채워넣을 문자열
   * @param {boolean} isTail 채워넣을 문자열을 뒤에 붙일 것인지에 대한 여부
   * @return {object} padded string
   * @example
   * var i = scard.padString(5, 3, '0');
   * // i = '005';
   *
   * var j = scard.padString('15640', 3);
   * // j = '156';
   *
   * var k = scard.padString(5, 3, '0', true);
   * // k = '500';
   **/
  padString(str, length, padStr, isTail) {
    let i;
    let paddings = '';
    if (padStr == null || typeof padStr === 'undefined' || typeof padStr !== 'string') {
      padStr = ' ';
    } else if (padStr.length > 1) {
      padStr = padStr.substr(0, 1);
    }

    if (typeof str === 'number') {
      str = '' + str;
    }
    const strLength = str.length;
    if (str.length === length) {
      return str;
    } else if (strLength < length) {
      for (i = 0; i < length - strLength; i++) {
        paddings = paddings + padStr;
      }
      return isTail === true ? str + paddings : paddings + str;
    } else {
      // 문자열이 길면 자릿수만큼 substring 처리
      return str.substr(0, length);
    }
  },

  /**
   * string 을 reverse하여 리턴한다.
   * @param {string}
   * @return {string}
   * @example
   * var reverse = scard.reverse('ABC');
   **/
  reverse(s) {
    let o = '';
    const len = s.length;
    for (let i = len - 1; i >= 0; i--) {
      o += s[i];
    }
    return o;
  },

  /**
   * hash 값을 리턴한다.
   * @param {string}
   * @return {number}
   * @example
   * var hash = scard.hash('ABC');
   **/
  hash(str) {
    let hash = 0;
    let i;
    let chr;
    const len = str.length;
    if (len === 0) return hash;
    for (i = 0; i < len; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash = hash & hash;
    }
    return hash;
  },

  /**
   * Universally Unique IDentifier (32) 를 생성하여 리턴한다.
   * @return {string} UUID
   * @example
   * var uuid = scard.createUUID();
   **/
  createUUID() {
    const s = [];
    const hexDigits = '0123456789ABCDEF';
    for (let i = 0; i < 32; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[12] = '4';
    s[16] = hexDigits.substr((s[16] & 0x3) | 0x8, 1);

    return s.join('');
  },

  /**
   * 문자열의 byte 길이를 리턴한다. (UTF-8기준)
   * @param {string} 문자열
   * @return {number} byte 길이
   * @example
   * var len = scard.getByteLength('abc가나다123');
   * // len = 15
   *
   **/
  getByteLength(s) {
    let b, i, c;
    if (typeof s !== 'string') {
      return 0;
    }
    for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
    return b;
  },
  // AS-IS 디채 scard.common.js에 있는 함수들 E N D

  // String 관련 함수 정의 시작!!

  /**
   * 총 이용금액 천 단위 이하 자르기 (만원 단위 출력)
   * ex0) cutStart=4, 5,299,873원 -> 529 만원
   * ex1) cutStart=4, 512,000 원 -> 51 만원 (천 단위 버림)
   * ex2) cutStart=6, 512,000 원 -> 0 만원 (만 단위 버림)
   * ex3) cutStart=9, 512,000 원 -> 0 만원 (substring 예외 처리)
   *
   * @param {*} am
   * @param {*} cutStart
   * @returns
   */
  cutUnit(am, cutStart) {
    am = String(am);
    // 4
    const length = am.length; // 6
    const printIndex = length - cutStart; // 2 = 6-4
    if (printIndex <= 0) {
      // 2 <= 0
      return '0';
    } else {
      return am.substring(0, printIndex); // substring(0, 2);
    }
  },

  /**
   * timestampDiff
   * @param {datetime}
   * @returns
   */
  timestampDiff: dtm => {
    const ms = DateUtil.diff(DateUtil.now(), DateUtil.formatDateTime(dtm), 'ms');
    const s = ms / 1000;
    if (s < 60) return `방금 전`;
    const mi = s / 60;
    if (mi < 60) return `${Math.floor(mi)}분 전`;
    const h = mi / 60;
    if (h < 24) return `${Math.floor(h)}시간 전`;
    const d = h / 24;
    if (d < 7) return `${Math.floor(d)}일 전`;
    const w = d / 7;
    if (w < 5) return `${Math.floor(w)}주 전`;
    const m = d / 30;
    if (m < 12) return `${Math.floor(m)}개월 전`;
    const y = d / 365;
    return `${Math.floor(y)}년 전`;
  },

  /**
   * ---------------------------------
   * 문자열 정렬을 위한 기준을 위한 함수들입니다.
   * ---------------------------------
   */
  charPriorityForName,
  contactNameSort(str1, str2) {
    if (typeof str1 !== 'string' || typeof str2 !== 'string') return 0;

    for (let i = 0; i < str1.length && i < str2.length; i++) {
      const char1 = str1[i] || '';
      const char2 = str2[i] || '';
      if (char1 === char2) continue;
      const char1Info = {
        char: char1,
        priority: charPriorityForName(char1),
      };
      const char2Info = {
        char: char2,
        priority: charPriorityForName(char2),
      };
      if (char1Info.priority > char2Info.priority) return 1;
      if (char1Info.priority < char2Info.priority) return -1;
      const compare = char1Info.char.localeCompare(char2Info.char);
      if (compare) return compare;
    }
    if (str1.length > str2.length) return 1;
    if (str1.length < str2.length) return -1;
    return 0;
  },
};
