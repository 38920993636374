// 날짜 관련 필터 정의
import Vue from 'vue';

// DateUtil 함수들 중에서 필터로 사용할 것이 있다면 가져와서 등록
import DateUtil from '~/utils/date-util';

// 일시 포맷팅
Vue.filter('formatDateTime', DateUtil.formatDateTime);
// 일자 포맷팅
Vue.filter('formatDate', DateUtil.formatDate);
// 시각 포맷팅
Vue.filter('formatTime', DateUtil.formatTime);

// 일시 파싱
Vue.filter('parseDateTime', DateUtil.parseDateTime);
// 일자 파싱
Vue.filter('parseDate', DateUtil.parseDate);
// 시각 파싱
Vue.filter('parseTime', DateUtil.parseTime);
