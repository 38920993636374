/**********************************************************
 * Nuxt.js Context에 유용한 유틸성 모듈을 inject하는 스크립트
 **********************************************************/

import EventBus from './eventBus.js';
import MonimoBridge from './bridge/monimo-bridge-interface.js';
import Modal from '@/utils/modals';
import Log from '@/utils/log';
import StorageUtil from '@/utils/storage-util';
import DateUtil from '@/utils/date-util';
import StringUtil from '@/utils/string-util';
import AdobeUtil from '@/utils/adobe-util';
import NumberUtil from '@/utils/number-util';
import TypeUtil from '@/utils/type-util';
import OngoBridge from '@/src/static/js/monimo/app/bridge/main.js';
import AppUtil from '@/src/static/js/monimo/app/bridge/modules/util.js';
import MydataUtil from '@/utils/mydata-util';
import MissionUtil from '@/utils/mission-util';
import SentryUtil from '@/utils/sentry-util';

export default function (context, inject) {
  // 모니모 공통
  inject('modal', Modal);
  inject('storageUtil', StorageUtil);
  inject('dateUtil', DateUtil);
  inject('stringUtil', StringUtil);
  inject('numberUtil', NumberUtil);
  inject('typeUtil', TypeUtil);
  inject('log', Log);
  inject('appUtil', AppUtil);
  inject('mydataUtil', MydataUtil);
  inject('adobeUtil', AdobeUtil);
  inject('sentryUtil', SentryUtil);
  // 이달의 미션 유틸(기존 방식으로 처리 불가능한 상세미션 처리 등록)
  inject('missionUtil', MissionUtil);

  // 브릿지 관련
  inject('ongobridge', OngoBridge); // 온고 브릿지
  inject('mnmBridge', MonimoBridge); // 신규 통합 브릿지
  // nuxt 기반이 아닌 관계사 페이지에 제공되는 ongobridge.min.js 스크립트 내에서 백버튼 감지 및 핸들러 호출을 위해,
  // EventBus 를 $bus로 $nuxt 컨텐스트에 Inject하여 체크한다.
  inject('bus', EventBus);
}
