/**
 * 브릿지 코어
 */
import { MBL_OS_DV_C, DEFAULT_HANDLER_NAME } from './constants';
import Console from './console';
import Util from './util';
import TypeUtil from './type-util';

const bridgeCore = {
  // 페이지 내 브릿지 호출 시퀀스
  seq: 0,

  /** 핸들러 */
  _handlers: {},

  /** 네이티브 콜백 */
  _callbacks: {},

  /** 브릿지 호출 시점 기록 */
  _call_timestamps: {},

  /**
   * 네이티브 핸들러를 등록한다.
   * @param {String} handlerName 핸들러 이름
   * @param {Object} handlerObject 핸들러 객체
   * @returns
   */
  registerHandler(handlerName, handlerObject) {
    bridgeCore._handlers[handlerName] = handlerObject || {};
    bridgeCore._callbacks[handlerName] = {};
    return bridgeCore._handlers[handlerName];
  },

  /**
   * 등록된 핸들러를 가져온다.
   * @param {String} handlerName
   * @returns
   */
  getHandler(handlerName) {
    return bridgeCore._handlers[handlerName];
  },

  /**
   * 네이티브 핸들러 함수를 호출한다.
   * @param {String} handlerName 핸들러 이름
   * @param {String} apiName 핸들러 함수
   * @param {Object} params 파라미터
   * @param {Function} callbackFunc 콜백 함수
   * @param {Boolean} preventDoubleClick 연속 호출 방지 플래그 (기본=false 로 연속 호출을 허용 함)
   */
  exec(handlerName, apiName, params, callbackFunc, preventDoubleClick = false) {
    Console.log('[브릿지-brideCore] apiName', apiName);
    Console.log('[브릿지-brideCore] params', params);

    // 연속클릭 방지 설정 시
    if (preventDoubleClick) {
      if (!bridgeCore._call_timestamps[handlerName]) {
        bridgeCore._call_timestamps[handlerName] = {};
      }

      if (!bridgeCore._call_timestamps[handlerName][apiName]) {
        bridgeCore._call_timestamps[handlerName][apiName] = new Date().getTime();
      } else {
        const lastCallTimestamp = bridgeCore._call_timestamps[handlerName][apiName];
        const currentTimestamp = new Date().getTime();

        // 0.5초 이내 동일한 브릿지 재 호출시
        if (currentTimestamp - lastCallTimestamp < 500) {
          return;
        } else {
          // 현재 시각을 최종호출시각으로 udpate
          bridgeCore._call_timestamps[handlerName][apiName] = currentTimestamp;
        }
      }
    }

    if (!params) {
      params = {};
    }

    let promise;

    try {
      const bridgeData = bridgeCore.makeBridgeData(handlerName, apiName, params, callbackFunc);

      // 네이티브 앱인경우 네이티브 호출
      if (Util.isMobileApp()) {
        promise = bridgeData.promise;
        bridgeCore.callNative(handlerName, apiName, bridgeData.jsonData);
      }
      // 앱이 아닌 경우
      else {
        // 콜백함수 존재 시 호출
        if (TypeUtil.isFunction(callbackFunc)) {
          callbackFunc.apply(window, [{}]);
        }
        // promise resolve 처리
        promise = Promise.resolve({});
      }
    } catch (err) {
      Console.error('[브릿지-brideCore] ', err);
    }
    return promise;
  },

  /**
   * 브릿지 호출 데이터를 생성한다.
   * @param {String} handlerName 핸들러 이름
   * @param {String} apiName 핸들러 함수
   * @param {Object} params 파라미터
   * @param {Function} callbackFunc 콜백 함수
   * @returns {Object} {
   *   jsonData {String} 네이티브 브릿지 호출시 넘겨줄 JSON String Data(URI Encoded)
   *   promise {Promise} 네이티브 콜백함수가 호출되면 resolve 될 Promise 객체
   * }
   */
  makeBridgeData(handlerName, apiName, params, callbackFunc) {
    // 콜백 생성
    const { callback, promise } = bridgeCore.createCallback(handlerName, apiName, callbackFunc);
    return {
      jsonData: encodeURIComponent(
        JSON.stringify({
          func: apiName,
          params,
          callback,
        }),
      ),
      promise,
    };
  },

  /**
   * 네이티브 콜백을 생성한다.
   * @param {String} handlerName 핸들러 이름
   * @param {String} apiName 핸들러 함수
   * @param {Function} callbackFunc 콜백 함수
   * @returns {Object} {
   *   callback {String} 네이티브에서 호출할 글로벌 스코프의 콜백함수 이름
   *   promise {Promise} 네이티브 콜백함수가 호출되면 resolve 될 Promise 객체
   * }
   */
  createCallback(handlerName, apiName, callbackFunc) {
    const callbackName = apiName + '_' + new Date().getTime() + '_' + ++bridgeCore.seq;
    const promise = new Promise((resolve, reject) => {
      window.scard.pfm.bridgeCallback[handlerName][callbackName] = function () {
        try {
          Console.log('[공통-brideCore] arguments', arguments);

          const mappedArguments = Array.from(arguments).map(argument => {
            let result = {};
            try {
              if (TypeUtil.isString(argument)) {
                argument = argument.replace(/[\r\n]+/g, '<br/>');
              }
              result = JSON.parse(argument);
            } catch (error1) {
              try {
                argument = argument.replace(/\+/g, '%20');
                argument = argument.replace(/[\r\n]+/g, '<br/>');
                result = JSON.parse(decodeURIComponent(argument));
              } catch (error2) {
                Console.error('[공통-brideCore] json parsing error!', error2);
              }
            }
            Console.log('[공통-brideCore] result', result);
            return result;
          });

          Console.log('[공통-brideCore] mappedArguments', mappedArguments);

          // resolve 처리
          // arguments가 1개인 경우, 해당 argument를 resolve 데이터로 셋팅
          if (TypeUtil.isArray(mappedArguments) && mappedArguments.length === 1) {
            resolve(mappedArguments[0]);
          }

          // arguments가 1개가 아닌 경우, Array 타입으로 전체 arguments를 resolve 데이터로 셋팅
          else {
            resolve(mappedArguments);
          }

          // 콜백함수 존재 시 호출
          if (TypeUtil.isFunction(callbackFunc)) {
            callbackFunc.apply(window, Array.prototype.slice.call(mappedArguments));
          }
        } catch (err) {
          Console.error('[공통-brideCore] ', err);

          // reject 처리
          reject(err);
        }
      };
    });

    return {
      callback: "scard.pfm.bridgeCallback['" + handlerName + "']['" + callbackName + "']",
      promise,
    };
  },

  /**
   * 네이티브를 호출한다.
   * @param {String} handlerName
   * @param {String} apiName
   * @param {Object} data
   */
  callNative(handlerName, apiName, data) {
    Console.log('[공통-brideCore] userAgent', navigator.userAgent.toLowerCase());

    if (Util.getMblOsDvC() === MBL_OS_DV_C.IOS) {
      Console.log('[공통-brideCore] OS', 'iOS');
      window.webkit.messageHandlers[handlerName].postMessage(data);
    } else if (Util.getMblOsDvC() === MBL_OS_DV_C.ANDROID) {
      Console.log('[공통-brideCore] OS', 'Android');
      if (DEFAULT_HANDLER_NAME === handlerName) {
        window[handlerName].callApi(data);
      } else {
        window[handlerName][apiName](data);
      }
    } else {
      Console.log('[공통-brideCore] OS', 'Not supported platform');
    }
  },
};

export default bridgeCore;
