import { getLoginUser } from '@/utils/common-util';
import Log from '@/utils/log';

/**
 * Adobe Tagging Data를 만든다.
 */
const makeTaggingData = async (data = {}, context = window?.$nuxt?.context) => {
  // 화면의 메타 정보를 store에서 가져온다.
  const { srnId: pageId, srnNm: pageName } = context?.$storageUtil?.store?.get('srnInfo');

  Log.log(`[공통-adobeUtil] pageId: ${pageId}`);
  Log.log(`[공통-adobeUtil] pageName: ${pageName}`);

  // 로그인사용자 정보 조회
  const { success: isLogin, loginUser } = await getLoginUser();
  Log.log('[공통-adobeUtil] getLoginUser: ', loginUser);

  const baseData = {
    event: 'pageLoad', // 이벤트: pageLoad, searchResultLoad, ...
    page: {
      pageName, // 페이지 이름
      pageId, // 페이지 ID
    },
    user: {
      loginStatus: isLogin ? 'logged in' : 'logged out', // 로그인/로그아웃 상태
      csnum_individual: loginUser?.cstMngtNo ?? '', // 고객번호 - 개인
      csnum_corporate: '', // 고객번호 - 법인
      csnum_partner: '', // 고객번호 - 가맹점 주
      lkpm_alncmp_mngt_no: '', // 제휴사관리번호
      csnum_monimo: loginUser?.mnmCstMngtNo ?? '', // 모니모고객관리번호
      csdemo_level: '', // 고객 정보 - 회원 등급
      csdemo_gender: '', // 고객 정보 - 회원 성별
      csdemo_age: '', // 고객 정보 - 회원 연령
    },
    // 이건 검색엔진 연계 할때만인가, 그냥 내부적으로 검색과 관련된 UI에는 다 적용하는건가????
    search: {
      internal_search: '', // 사이트 내부 검색어
      internal_search_noresult: '', // 검색 결과가 없는 검색어
    },
    shop: {
      shop_cat1: '', // 쇼핑몰 대분류
      shop_cat2: '', // 쇼핑몰 중분류
      shop_cat3: '', // 쇼핑몰 소분류
      shop_product: '', // 쇼핑몰 상품코드
    },
  };
  return Object.assign({}, baseData, data);
};

/**
 * Adobe Tagging 처리
 */
const _doTagging = async (data = {}, context = window?.$nuxt?.context) => {
  const taggingData = await makeTaggingData(data, context);
  Log.log('[공통-adobeUtil] _doTagging tagging data: ', taggingData);

  try {
    // adobe script 체크
    if (typeof window?.adobeDataLayer?.getState === 'function') {
      // tagging 처리
      window?.adobeDataLayer?.push?.(taggingData);
    }
  } catch (error) {
    Log.error('[공통-adobeUtil] _doTagging error: ', error.message);
  }
};

/**
 * pageLoad 이벤트 태깅
 */
const pageTagging = async (data = {}, context = window?.$nuxt?.context) => {
  await _doTagging(Object.assign({}, data, { event: 'pageLoad' }), context);
  const pushYn = context.query?.pushYn; // Push 여부
  if (pushYn === 'Y') {
    const pushClickTaggingCode = context.query?.pushClickTaggingCode;
    if (pushClickTaggingCode) {
      clickTagging(pushClickTaggingCode);
    }
  }
};

/**
 * 검색 결과 태깅
 */
const searchTagging = async (keyword = '', hasResult = false, context = window?.$nuxt?.context) => {
  await _doTagging(
    Object.assign(
      {},
      { internal_search: keyword, internal_search_noresult: !hasResult ? keyword : '' },
      { event: 'searchResultLoad' },
    ),
    context,
  );
};

/**
 * 클릭코드 태깅
 */
const clickTagging = clickCode => {
  try {
    if (clickCode) {
      Log.log('[공통-adobeUtil] clickTagging clickCode: ', clickCode);

      // adobe script 체크
      if (typeof window?.adobeDataLayer?.getState === 'function') {
        // tagging 처리
        window?._satellite?.track?.('call_click_code', { click_code: clickCode });
      }
    }
  } catch (error) {
    Log.error('[공통-adobeUtil] clickTaggingError: ', error.message);
  }
};

/**
 * 개인화배너 노출 태깅
 */
const psnzBnnrTagging = (mnmPsnzCntsIdList = []) => {
  try {
    if (mnmPsnzCntsIdList.length > 0) {
      Log.log('[공통-adobeUtil] psnzBnnrTagging mnmPsnzCntsIdList: ', mnmPsnzCntsIdList);
      const percontentsid = mnmPsnzCntsIdList.join('|');
      const taggingData = {
        event: 'personaliztion_banner_impression',
        page: { percontentsid },
      };
      Log.log('[공통-adobeUtil] psnzBnnrTagging taggingData: ', taggingData);
      // adobe script 체크
      if (typeof window?.adobeDataLayer?.getState === 'function') {
        // tagging 처리
        window?.adobeDataLayer?.push?.(taggingData);
      }
    }
  } catch (error) {
    Log.error('[공통-adobeUtil] psnzBnnrTaggingError: ', error.message);
  }
};

export default {
  pageTagging,
  searchTagging,
  clickTagging,
  psnzBnnrTagging,
};
