import PFMAppInterface from '../api/pfm-app-interface';
import Console from './console';

export default {
  /**
   * 디바이스 백버튼 클릭 시, scard.pfm.from.back() 으로 네이티브가 스크립트 호출(안드로이드)
   */
  back: () => {
    Console.log(' [ fromNative ] scard.pfm.from.back');

    // In Nuxt Context (모니모 페이지 내에서 디바이스 백버튼 누른 경우)
    // 관계사에 제공 되는 스크립트에 vue 디펜던시까지 섞으면 용량이 커지므로,
    // bus를 import하지 않고, $nuxt 컨텐스트에 미리 주입해 놓고,
    // $nuxt 컨텍스트 존재여부를 체크하여 주입해 놓은 bus를 context에서 가져와서 event를 emit한다.
    if (window?.$nuxt?.context?.$bus) {
      // 마이-자산 탭일 경우 처리
      if (window.location.pathname === '/mydata/asset/UPFMMA0101M0') {
        window?.$nuxt?.context?.$bus?.$emit('click:deviceBackButton');
      }
      // SubHeader가 있는 화면인 경우 Event를 Emit하여 SubHeader가 받아서 처리토록 한다.
      else if (document.querySelector('.sc-sub-header-wrapper')) {
        window?.$nuxt?.context?.$bus?.$emit('click:deviceBackButton');
      }
      // SubHeader가 없는 화면인 경우 여기서 처리.
      // 첫번쨰 페이지인 경우 ( firstYn=Y )
      else if (window?.$nuxt?.$router?.query?.firstYn === 'Y') {
        PFMAppInterface.closeCurBrowser();
      }

      // 그외의 모니모 페이지인 경우
      else {
        window?.$nuxt?.$router?.go(-1);
      }
    }
    // 모니모 외부 도메인의 첫번쨰 페이지인 경우 ( firstYn=Y )
    else if (location.search.includes('firstYn=Y')) {
      PFMAppInterface.closeCurBrowser();
    }
    // 그 외에는 history.back을 시도한다.
    else {
      (history || window?.history)?.back();
    }
  },

  /**
   * 스크롤 Top으로 이동
   */
  scrollTop: () => {
    window.document.scrollingElement.scrollTop = 0;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const ongoScrollingElement = document.querySelector('.ongo-scrolling-element');
    if (ongoScrollingElement) {
      ongoScrollingElement.scrollTop = 0;
    }
  },

  /**
   * TODO: keyboard가 숨겨지기 직전에 호출
   * 이렇게 스크롤만 보정하면 될지, body가 html 아랫부분을 짤라내야 하는지는 테스트 필요
   */
  keyboardWillHide: height => {
    const isIOS = window?.$nuxt?.$browserDetect.isIOS;
    const OSVersion = window?.$nuxt?.$browserDetect.meta.version;

    try {
      Console.log(`isIOS: ${isIOS}`);
      Console.log(`OSVersion: ${OSVersion}`);

      if (isIOS && Number(OSVersion) <= 13) {
        const delta = height ?? 0;
        window.document.scrollingElement.scrollTop -= delta;
        window.scrollTo(0, window.scrollY - delta);
        document.body.scrollTop -= delta;
        const ongoScrollingElement = document.querySelector('.ongo-scrolling-element');
        if (ongoScrollingElement) {
          ongoScrollingElement.scrollTop -= delta;
        }
      }
    } catch (error) {
      Console.dir(error);
    }
  },
};
