/**
 * Util 모듈
 */
import {
  MBL_OS_DV_C,
  NATIVE_USERAGENT_STRING,
  DEEPLINK_BASE_URL,
  DEEPLINK_LINK_URL,
  DEEPLINK_LINK_APN,
  DEEPLINK_LINK_ISI,
  DEEPLINK_LINK_IBI,
} from './constants';
import URLParser from './url-parser';
import Console from './console';

// Mobile에서 넘겨주는 userAgent값을 Map으로 저장
export const userAgentMap = {};
// eslint-disable-next-line array-callback-return
navigator.userAgent?.split(';')?.map(text => {
  userAgentMap[text?.split('=')[0]] = text?.split('=')[1];
});

// 앱/타겟 버전을 비교하는 함수
const versionCompare = (appVersion, targetVersion) => {
  if (Number(appVersion[0]) > Number(targetVersion[0])) {
    return true;
  } else if (Number(appVersion[0]) === Number(targetVersion[0]) && Number(appVersion[1]) > Number(targetVersion[1])) {
    return true;
  } else if (
    Number(appVersion[0]) === Number(targetVersion[0]) &&
    Number(appVersion[1]) === Number(targetVersion[1]) &&
    Number(appVersion[2]) >= Number(targetVersion[2])
  ) {
    return true;
  }
  return false;
};

export default {
  /**
   * 네임스페이스를 생성한다.
   * @param {String} 네임스페이스
   * @returns 생성된 네임스페이스의 parent 네임스페이스 객체
   */
  createNamespace(ns) {
    const names = ns.split('.');
    let i;
    let parent = window;
    const length = names.length;

    for (i = 0; i < length; i++) {
      if (typeof parent[names[i]] === 'undefined') {
        parent[names[i]] = {};
      }
      if (i < length - 1) {
        parent = parent[names[i]];
      }
    }
    return parent;
  },

  /**
   * 모바일OS구분코드를 가져온다.
   *  - 유효값: [01] 안드로이드 / [02] iOS / [03] 기타
   * @returns
   */
  getMblOsDvC() {
    if (/Android/i.test(navigator.appVersion)) {
      return MBL_OS_DV_C.ANDROID;
    } else if (/iphone|ipod|ipad/i.test(navigator.appVersion)) {
      return MBL_OS_DV_C.IOS;
    } else {
      return MBL_OS_DV_C.OTHER;
    }
  },

  /**
   * userAgent의 앱 정보를 가져온다
   * @param {Object} param {
   *    key: String 앱 정보 키
   *    mblSappYn // 모바일앱 여부 (Y 고정)
   *    mblOsDvC  // 모바일OS 구분 (01:Android,  02:iOS)
   *    mblOsVerNm // 모바일OS 버전
   *    mblSappNm  // 모바일앱 이름 (Monimo 고정)
   *    mblSappVerNm // 모바일앱 버전명
   *    mblDvcDrmNo // 모바일디바이스식별번호
   *    mblSappKndDvC // 앱종류 구분코드 (PFM 고정)
   *    apstDvc // 앱스토어 구분코드 (2:기본, 3:T스토어)
   *    netKind // 연결네트워크 구분 (wifi:개발서버 Wifi http 접속, wifiSsl:개발서버 Wifi https 접속, lte:기타)
   *    isDebug // 디버그앱 여부 ('Y':디버그앱, null:일반앱)
   * }
   * @returns
   */
  getAppInfo(key) {
    return userAgentMap[key];
  },

  /**
   * 특정 서비스가 배포된 앱에서만 동작해야할때 이 유틸을 사용한다.
   * @param {Object} param {
   *    key: OS종류 (aos or ios)
   *    value: 버전정보 (ex. "10.0.2")
   * }
   * @returns {Boolean} 앱 버전에 따른 서비스 가능여부 리턴
   */
  activateService(deviceObj) {
    let activateService = false;
    const deviceCode = this.getAppInfo('mblOsDvC');
    const appVersion = this.getAppInfo('mblSappVerNm');
    // appVersion 을 가져오지 못하면 false 처리
    if (appVersion == null) return activateService;

    if (deviceCode === '01') {
      // AOS
      // 버전 정보가 없으면 false 리턴
      if (deviceObj.aos == null) return activateService;

      let targetProdVerArr, targetTestVerArr;
      if (typeof deviceObj.aos === 'object') {
        // 타겟 버전을 운영 버전과 테스트 버전으로 구성된 Array로 입력했을때
        for (let i = 0; i < deviceObj.aos.length; i++) {
          const targetVersion = deviceObj.aos[i];
          if (targetVersion.split('.').length === 3) {
            targetProdVerArr = targetVersion.split('.');
          } else {
            targetTestVerArr = targetVersion.split('.');
          }
        }
      } else {
        // 타겟 버전을 한개만 입력 했을때
        const targetVersion = deviceObj.aos.split('.');
        if (targetVersion.length === 3) {
          targetProdVerArr = targetVersion;
        } else {
          targetTestVerArr = targetVersion;
        }
      }
      Console.log('[브릿지-AppUtil] ProdArr: ', targetProdVerArr);
      Console.log('[브릿지-AppUtil] TestArr: ', targetTestVerArr);

      const versionArr = appVersion.split('.');
      Console.log('[브릿지-AppUtil] AppVersionArr: ', versionArr);
      if (versionArr.length === 3) {
        // AOS 운영 버전일때
        // target 버전 이상에서만 해당 기능이 동작함
        activateService = versionCompare(versionArr, targetProdVerArr);
      } else if (targetTestVerArr != null) {
        // AOS 테스트 버전일때
        // target 버전 이상에서만 해당 기능이 동작함
        activateService = Number(versionArr[0]) >= Number(targetTestVerArr[0]);
      }
    } else if (deviceCode === '02') {
      // IOS
      if (deviceObj.ios == null) return activateService;

      const versionArr = appVersion.split('.');
      const targetVerArr = deviceObj.ios.split('.');
      Console.log('[브릿지-AppUtil] AppVersionArr: ', versionArr);
      Console.log('[브릿지-AppUtil] TargetVerArr: ', targetVerArr);
      activateService = versionCompare(versionArr, targetVerArr);
    }
    return activateService;
  },

  /**
   * 네이티브 앱 여부를 가져온다.
   *  - UserAgent의 mblSappYn=Y 값으로 구분
   * @returns {Boolean} 네이티브 앱 여부
   */
  isMobileApp() {
    const ua = navigator.userAgent.toUpperCase();
    return ua.includes(NATIVE_USERAGENT_STRING.toUpperCase()) || location.search.includes(NATIVE_USERAGENT_STRING);
  },

  /**
   * URL을 생성한다.
   * @param {String} baseURL 기본 URL
   * @param {Object} params key: value 가 queryString으로 파싱된다.
   * @returns 생성된 URL
   */
  createURL(baseURL = '', params = {}, encoding = true) {
    return new URLParser(baseURL).addParamObject(params, encoding).toString();
  },

  /**
   * DeepLink URL을 생성한다.
   * @param {Object} param {
   *    url: fullUrl String
   *    query: {Object} key,value query Object가 queryString으로 변환된다.
   *    gnb: gnb 탭 구분 값 (디폴트:today)
   * }
   * @param {*} baseURL
   * @param {*} linkURL
   * @returns
   */
  createDeepLinkUrl({ url = '', query = {}, gnb = 'today' }, baseURL = DEEPLINK_BASE_URL, linkURL = DEEPLINK_LINK_URL) {
    const linkParams = {};
    query = { ...query, firstYn: 'Y', gnb };
    const parsedDataURL = new URLParser(url)?.addParamObject(query, false); // data 내부는 URL encoding 하지 않음.
    linkParams.data = JSON.stringify({
      url: parsedDataURL?.toString()?.replace(parsedDataURL.search, ''),
      queryString: parsedDataURL?.search?.replace('?', ''),
    });

    linkParams.apn = DEEPLINK_LINK_APN;
    linkParams.isi = DEEPLINK_LINK_ISI;
    linkParams.ibi = DEEPLINK_LINK_IBI;

    return this.createURL(
      baseURL,
      {
        // 여기서 data를 포함한 파라미터를 한번에 encoding 한다.
        link: this.createURL(linkURL, linkParams),
      },
      false, // 제일 바깥쪽은 URL encoding 하지 않음.
    );
  },
};
