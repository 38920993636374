/********************************
 * 날짜 관련 유틸
 ********************************/
import TypeUtil from '~/utils/type-util';

// 날짜 부분 포맷 정의
const DATE_FORMAT = {
  YYYYMMDD: 'YYYYMMDD',
  DASH_YYYYMMDD: 'YYYY-MM-DD',
  DOT_YYYYMMDD: 'YYYY.MM.DD',
  SLASH_YYYYMMDD: 'YYYY/MM/DD',
  DASH_YYYYMD: 'YYYY-M-D',
  DOT_YYYYMD: 'YYYY.M.D',
  SLASH_YYYYMD: 'YYYY/M/D',
  YYMMDD: 'YYMMDD',
  DASH_YYMMDD: 'YY-MM-DD',
  DOT_YYMMDD: 'YY.MM.DD',
  SLASH_YYMMDD: 'YY/MM/DD',
  DASH_YYMD: 'YY-M-D',
  DOT_YYMD: 'YY.M.D',
  SLASH_YYMD: 'YY/M/D',
};

// 시간 부분 포맷 정의
const TIME_FORMAT = {
  HHMMSSSSS: 'HHmmssSSS',
  COLON_HHMM: 'HH:mm',
  COLON_HHMMSS: 'HH:mm:ss',
  COLON_HHMMSSSSS: 'HH:mm:ss.SSS',
};

// 전체 포맷 집어넣을 변수
const FORMAT = { ...DATE_FORMAT, ...TIME_FORMAT };

// 날짜와 시간을 조합하여 FORMAT에 추가
Object.entries(DATE_FORMAT).forEach(([dateFormatKey, dateFormatValue]) => {
  Object.entries(TIME_FORMAT).forEach(([timeFormatKey, timeFormatValue]) => {
    FORMAT[`${dateFormatKey}${timeFormatKey}`] = `${dateFormatValue}${timeFormatValue}`;
    FORMAT[`${dateFormatKey}_SPACE_${timeFormatKey}`] = `${dateFormatValue} ${timeFormatValue}`;
  });
});

// 서버데이터 기준으로 디폴트 잡는게 맞을거 같아서 YYYYMMDDHHMMSSSSS로 디폴트 정의
const DEFAULT_DATETIME_FORMAT = FORMAT.YYYYMMDDHHMMSSSSS;
const DEFAULT_DATE_FORMAT = FORMAT.YYYYMMDD;
const DEFAULT_TIME_FORMAT = FORMAT.HHMMSSSSS;

const WEEKDAY_NM = {
  EN: {
    ABBR: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    FULL: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },
  KR: {
    ABBR: ['일', '월', '화', '수', '목', '금', '토'],
    FULL: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  },
};

const MONTH_NM = {
  EN: {
    ABBR: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    FULL: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
};

/**
 * 일시 포맷팅
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const formatDateTime = (date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) =>
  context.$dayjs(!date ? new Date() : date).format(template);

/**
 * 일자 포맷팅
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const formatDate = (date = new Date(), template = DEFAULT_DATE_FORMAT, context = window?.$nuxt?.context) =>
  context.$dayjs(!date ? new Date() : date).format(template);

/**
 * 시각 포맷팅
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const formatTime = (date = new Date(), template = DEFAULT_TIME_FORMAT, context = window?.$nuxt?.context) =>
  context.$dayjs(!date ? new Date() : date).format(template);

/**
 * 일시 파싱
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const parseDateTime = (date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) => {
  let templateArr = [];

  if (TypeUtil.isArray(template)) {
    templateArr = [...template];
  } else {
    templateArr = [template];
  }

  // 입력 날짜 문자열의 길이만큼 템플릿을 보정
  if (TypeUtil.isString(date)) {
    templateArr = templateArr.map(tmpl => {
      return tmpl?.slice(0, date?.length);
    });
    return context.$dayjs(date, Array.from(new Set([...templateArr, DEFAULT_DATETIME_FORMAT])));
  } else {
    return context.$dayjs(!date ? new Date() : date);
  }
};

/**
 * 일자 파싱
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const parseDate = (date = new Date(), template = DEFAULT_DATE_FORMAT, context = window?.$nuxt?.context) => {
  let templateArr = [];

  if (TypeUtil.isArray(template)) {
    templateArr = [...template];
  } else {
    templateArr = [template];
  }

  // 입력 날짜 문자열의 길이만큼 템플릿을 보정
  if (typeof date === 'string') {
    templateArr = templateArr.map(tmpl => {
      return tmpl?.slice(0, date?.length);
    });
    return context.$dayjs(date, Array.from(new Set([...templateArr, DEFAULT_DATE_FORMAT])));
  } else {
    return context.$dayjs(!date ? new Date() : date);
  }
};

/**
 * 시각 파싱
 * @param {*} date
 * @param {*} template
 * @param {*} context
 * @returns
 */
const parseTime = (date = new Date(), template = DEFAULT_TIME_FORMAT, context = window?.$nuxt?.context) => {
  let templateArr = [];

  if (TypeUtil.isArray(template)) {
    templateArr = [...template];
  } else {
    templateArr = [template];
  }

  // 입력 날짜 문자열의 길이만큼 템플릿을 보정
  if (typeof date === 'string') {
    templateArr = templateArr.map(tmpl => {
      return tmpl?.slice(0, date?.length);
    });
    return context.$dayjs(date, Array.from(new Set([...templateArr, DEFAULT_TIME_FORMAT])));
  } else {
    return context.$dayjs(!date ? new Date() : date);
  }
};

/**
 * 날짜가 요일에서 몇번째 날인지를 가져온다.
 * (일요일(0) ~ 토요일(6))
 */
const weekday = (date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) => {
  return parseDateTime(date, template, context).weekday();
};

const month = (date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) => {
  return parseDateTime(date, template, context).month();
};

/**
 * 현재 일시를 가져온다.
 * @param {*} template
 * @param {*} context
 * @returns
 */
const nowDateTime = (template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) => {
  return formatDateTime(context.$dayjs(), template, context);
};

export default {
  FORMAT,
  formatDateTime,
  formatDate,
  formatTime,
  parseDateTime,
  parseDate,
  parseTime,
  nowDateTime,
  now: nowDateTime, // alias for nowDateTime
  /**
   * 현재 일자를 가져온다.
   * @param {*} template
   * @param {*} context
   * @returns
   */
  nowDate(template = DEFAULT_DATE_FORMAT, context = window?.$nuxt?.context) {
    return formatDate(context.$dayjs(), template, context);
  },
  /**
   * 현재 시각을 가져온다.
   * @param {*} template
   * @param {*} context
   * @returns
   */
  nowTime(template = DEFAULT_TIME_FORMAT, context = window?.$nuxt?.context) {
    return formatTime(context.$dayjs(), template, context);
  },

  /**
   * 날짜 formatter
   * @param String
   * @returns [String] yyyyMMdd -> M.d (20240116 -> 1.16)
   */
  shortDateFormat(date) {
    const month = Number(date.substring(4, 6));
    const day = Number(date.substring(6, 8));
    return month + '.' + day;
  },
  /**
   * 시간, 분 formatter
   * @param String
   * @returns HHmm -> HH:mm (0901 -> 09:01)
   */
  shortTimeFormat(time) {
    const hours = time.substring(0, 2);
    const mins = time.substring(2, 4);
    return hours + ':' + mins;
  },

  /**
   * 날짜가 윤년인지 체크한다.
   * @param {*} date
   * @param {*} context
   * @returns
   */
  isLeapYear(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).isLeapYear();
  },

  /**
   * 날짜가 주에서 몇번째 날인지를 가져온다.
   * (일요일(0) ~ 토요일(6))
   */
  weekday,

  /**
   * 이번주 일요일(시작일)을 기준점(0)으로하여, 특정일수만큼 전후 날짜를 offset만큼 가감한 차일을 가져온다.
   * @param {*} offset 이번 주 일요일(시작일)로부터의 날짜 offset
   * @param {*} template
   * @param {*} context
   */
  weekdayFromThisWeek(offset = 0, template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return context.$dayjs().weekday(offset).format(template);
  },

  /**
   * 날짜가 년에서 몇번째 날인지를 가져온다.
   * 1월1일(1) ~ 12월31일(365or366)
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  dayOfYear(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).dayOfYear();
  },

  /**
   * 날짜가 년에서 몇번째 주인지를 가져온다.
   * 1월1일(1) ~ 12월31일(52or53)
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  weekOfYear(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).week();
  },

  /**
   * 날짜가 년에서 몇번째 분기인지를 가져온다.
   * 1월1일(1) ~ 12월31일(4)
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  quarterOfYear(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).quarter();
  },

  /**
   * 해당 년도에 몇주가 존재하는지 리턴한다.(52or53)
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  isoWeeksInYear(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).isoWeeksInYear();
  },

  /**
   * 날짜의 요일을 영문명 약어로 가져온다.
   * (일요일(Sun) ~ 토요일(Sat))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  weekdayNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return WEEKDAY_NM.EN.ABBR[weekday(date, template, context)];
  },

  /**
   * 날짜의 요일을 영문명 풀네임으로 가져온다.
   * (일요일(Sunday) ~ 토요일(Saturday))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  weekdayFullNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return WEEKDAY_NM.EN.FULL[weekday(date, template, context)];
  },

  /**
   * 날짜의 요일을 한글명 약어로 가져온다.
   * (일요일(일) ~ 토요일(토))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  weekdayKrNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return WEEKDAY_NM.KR.ABBR[weekday(date, template, context)];
  },

  /**
   * 날짜의 요일을 한글명 풀네임으로 가져온다.
   * (일요일(일요일) ~ 토요일(토요일))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  weekdayKrFullNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return WEEKDAY_NM.KR.FULL[weekday(date, template, context)];
  },

  /**
   * 날짜의 월을 영문명 약어로 가져온다.
   * (1월(JAN) ~ 12월(DEC)))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  monthErNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return MONTH_NM.EN.ABBR[month(date, template, context)];
  },

  /**
   * 날짜의 월을 영문명 풀네임으로 가져온다.
   * (1월(January) ~ 12월(December)))
   * @param {*} date
   * @param {*} template
   * @param {*} context
   * @returns
   */
  monthErFullNm(date = new Date(), template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return MONTH_NM.EN.FULL[month(date, template, context)];
  },

  /**
   * 주어진 날짜들 중에서 최소 날짜(가장 과거)를 가져온다.
   * @returns
   */
  min() {
    if (arguments.length) {
      let dayArr = [];
      if (TypeUtil.isArray(arguments[0])) {
        dayArr = [...arguments[0]];
      } else {
        dayArr = [...arguments];
      }
      return formatDateTime(window.$nuxt?.$dayjs.min(dayArr.map(day => parseDateTime(day))));
    }
  },

  /**
   * 주어진 날짜들 중에서 최대 날짜(가장 미래)를 가져온다.
   * @returns
   */
  max() {
    if (arguments.length) {
      let dayArr = [];
      if (TypeUtil.isArray(arguments[0])) {
        dayArr = [...arguments[0]];
      } else {
        dayArr = [...arguments];
      }
      return formatDateTime(window.$nuxt?.$dayjs.max(dayArr.map(day => parseDateTime(day))));
    }
  },

  /**
   * 두 날짜의 차이를 특정 날짜 단위별로 계산하여 가져온다.
   * [단위]
   * [Unit(대소문자상관없음) / Shorhand(대소문자가림)]
   *   day / d
   *   week / w
   *   quarter / Q
   *   month / M
   *   year / y
   *   hour / h
   *   minute / m
   *   second / s
   *   millisecond / ms
   */
  diff(
    date1 = new Date(),
    date2 = new Date(),
    unit = 'd',
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date1, template, context).diff(parseDateTime(date2, template, context), unit);
  },

  /**
   * 날짜에 특정 단위를 지정한 양만큼 더해서 가져온다.
   * [단위]
   * [Unit(대소문자상관없음) / Shorhand(대소문자가림)]
   *   day / d
   *   week / w
   *   month / M
   *   quarter / Q
   *   year / y
   *   hour / h
   *   minute / m
   *   second / s
   *   millisecond / ms
   * @param {Date | String | Number | Dayjs} date
   * @param {Number} amount
   * @param {String} unit
   * @param {String} template
   * @param {Object} context
   * @returns
   */
  add(date = new Date(), amount, unit = 'd', template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).add(amount, unit).format(template);
  },

  /**
   * 날짜에 특정 단위를 지정한 양만큼 뺴서 가져온다.
   * [단위]
   * [Unit(대소문자상관없음) / Shorhand(대소문자가림)]
   *   day / d
   *   week / w
   *   month / M
   *   quarter / Q
   *   year / y
   *   hour / h
   *   minute / m
   *   second / s
   *   millisecond / ms
   *
   * @param {*} date
   * @param {*} amount
   * @param {*} unit
   * @param {*} template
   * @param {*} context
   * @returns
   */
  subtract(
    date = new Date(),
    amount,
    unit = 'd',
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date, template, context).subtract(amount, unit).format(template);
  },

  /**
   * 날짜에서 특정 단위의 시작 일자를 계산하여 가져온다.
   * [단위]
   * [Unit(대소문자상관없음) / Shorhand(대소문자가림)]
   *   year / y
   *   quarter / Q
   *   month / M
   *   week / w
   *   isoWeek
   *   date / D
   *   day / d
   *   hour / h
   *   minute / m
   *   second / s
   * @param {*} date
   * @param {*} unit
   * @param {*} template
   * @param {*} context
   */
  startOf(date = new Date(), unit = 'd', template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).startOf(unit).format(template);
  },

  /**
   * 날짜에서 특정 단위의 끝 일자를 계산하여 가져온다.
   * [단위]
   * [Unit(대소문자상관없음) / Shorhand(대소문자가림)]
   *   year / y
   *   quarter / Q
   *   month / M
   *   week / w
   *   isoWeek
   *   date / D
   *   day / d
   *   hour / h
   *   minute / m
   *   second / s
   * @param {*} date
   * @param {*} unit
   * @param {*} template
   * @param {*} context
   */
  endOf(date = new Date(), unit = 'd', template = DEFAULT_DATETIME_FORMAT, context = window?.$nuxt?.context) {
    return parseDateTime(date, template, context).endOf(unit).format(template);
  },

  /**
   * 특정 날짜가 현재로 부터 얼마나 전인지 또는 후인지를 계산하여 가져온다.
   */
  fromNow(
    date = new Date(),
    eliminateSuffix = false,
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date, template, context).fromNow(eliminateSuffix);
  },

  /**
   * 특정 날짜가 기준날짜로 부터 얼마나 전인지 또는 후인지를 계산하여 가져온다.
   */
  from(
    date = new Date(),
    fromDate = new Date(),
    eliminateSuffix = false,
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date, template, context).from(parseDateTime(fromDate, template, context), eliminateSuffix);
  },

  /**
   * 특정 날짜로부터 현재까지는 얼마나 되는지를 계산하여 가져온다.
   */
  toNow(
    date = new Date(),
    eliminateSuffix = false,
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date, template, context).toNow(eliminateSuffix);
  },

  /**
   * 특정 날짜로부터 기준날짜까지는 얼마나 되는지를 계산하여 가져온다.
   */
  to(
    date = new Date(),
    toDate = new Date(),
    eliminateSuffix = false,
    template = DEFAULT_DATETIME_FORMAT,
    context = window?.$nuxt?.context,
  ) {
    return parseDateTime(date, template, context).from(parseDateTime(toDate, template, context), eliminateSuffix);
  },
};
