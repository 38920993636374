/*
 * Hybrid Webapp 웹뷰 Javascript 브릿지 메인 Entry Point
 *  - 관계사 제공을 위해 Nuxt 의 webpack 빌드에 포함시키지 않고 별도로 webpack 빌드
 */

import BridgeCore from './modules/bridge-core';
import FromNative from './modules/from-native';
import Util from './modules/util';
import { CONSOLE_STYLE1 } from './modules/constants';
import Console from './modules/console';
import PFMAppInterface from './api/pfm-app-interface';

// ongobridge 시작 로그!!!
Console.log(`%c OLD OngoBridge initializing...`, CONSOLE_STYLE1);

// scard.pfm 네임스페이스 생성
Util.createNamespace('scard.pfm');
window.scard.pfm = {
  exec: BridgeCore.exec,
  registerHandler: BridgeCore.registerHandler,
  getHandler: BridgeCore.getHandler,
  bridgeCallback: BridgeCore._callbacks,
  from: FromNative,
  util: Util,
  ongobridge: BridgeCore.registerHandler(PFMAppInterface.HANDLER_NAME, PFMAppInterface),
};

export default window.scard.pfm.ongobridge;
