/********************************
 * Storage 관련 유틸
 *   - @nuxtjs/universal-storage 랩핑
 *   - sessionStorage 추가
 ********************************/

import cookie from './cookie';
import sessionStorage from './session-storage';
import localStorage from './local-storage';
import store from './store';

const isUnset = o => {
  return typeof o === 'undefined' || o === null;
};
function isSet(o) {
  return !isUnset(o);
}

export default {
  localStorage,
  sessionStorage,
  cookie,
  store,
  universal: {
    get(key, context = window?.$nuxt?.context) {
      let value = context.$storage.getUniversal(key);
      if (isUnset(value)) {
        value = sessionStorage.get(key);
      }
      return value;
    },
    set(key, value, context = window?.$nuxt?.context) {
      context.$storage.setUniversal(key, value);
      sessionStorage.set(key, value);
      return value;
    },
    remove(key, context = window?.$nuxt?.context) {
      context.$storage.removeUniversal(key);
      sessionStorage.remove(key);
    },
    sync(key, defaultValue, context = window?.$nuxt?.context) {
      let value = this.get(key, defaultValue, context);
      if (isUnset(value) && isSet(defaultValue)) {
        value = defaultValue;
      }
      if (isSet(value)) {
        this.set(key, value, context);
      }
      return value;
    },
  },
};
