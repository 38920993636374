/********************************
 * Local Storage 유틸
 ********************************/
export default {
  get(key, context = window?.$nuxt?.context) {
    return context.$storage.getLocalStorage(key);
  },
  set(key, value, context = window?.$nuxt?.context) {
    return context.$storage.setLocalStorage(key, value);
  },
  remove(key, context = window?.$nuxt?.context) {
    context.$storage.removeLocalStorage(key);
  },
};
