/********************************
 * Number 관련 유틸
 ********************************/

export default {
  /**
   * 전화번호 포맷으로 변경한다.
   * @param {string} num       전화번호
   * @param {boolean} masking  가운데 글자 마스킹 처리여부
   * @return {string}          포맷팅된 전화번호
   * @example
   * const phoneNumber = scard.phoneNumberFomatter('01012341234');
   **/
  phoneNumberFomatter: (num, masking) => {
    let formatedNum = '';
    if (masking == null) masking = false;
    if (num.length === 10) {
      if (masking) {
        formatedNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatedNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    } else if (num.length === 11) {
      if (masking) {
        formatedNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatedNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    }
    return formatedNum;
  },
};
